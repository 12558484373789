import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sortBy, orderBy, isEqual, omit, findKey } from 'lodash';
import { getParseData, processMultiLineData } from '../sagas/sessionResult';

type Filters = {
  results: any[],
  fields: any,
  isLoading: boolean;
  defaultValues: any;
  currentFilteration: any;
}

type CurveAndDesignObjectType = {
  label: string, 
  id: string,
  results?: Array<any>
}

type MultiLineView = {
  curves: Array<CurveAndDesignObjectType>,
  designOptions: Array<CurveAndDesignObjectType>,
  endPoint: string,
  metaInfo?: any,
  views: Array<any>
}
type GraphPreviewData = {
  graphIdentifier:string,
  graphPreviewImgSrc: string,
  graphTableData: any,
  graphTitle: string,
  tooltip: string,
  error: string,
}

type UpdateResultViewData = {
  viewId: string ,
  compareByValue: string
}
interface SessionResulsType {
  data: any[];
  loading: boolean;
  error: string;
  isTestRun: string;
  totalRecords: string | number;
  pageCount: number;
  isCompare: boolean;
  isSameEndpointsCompare: boolean;
  filter: Filters;
  sort: any;
  isLayoutLoading: boolean;
  multiLineView: MultiLineView;
  graphPreviewData: any;
  posteriorDistributionPreviewData: any;
  resultsViewRadioOption: any
  isRatingLoading: boolean;
  mtdTabsSpecialValues: any;
  posteriorDistributionTabsValues: any;
  cancelSimulationStatus: string;
}

export const initialState: SessionResulsType = {
  data: [],
  isTestRun: '',
  totalRecords: '',
  loading: false,
  pageCount: 0,
  error: '',
  isCompare: false,
  isSameEndpointsCompare: false,
  isLayoutLoading: false,
  filter: {
    results: [],
    fields: {
      projSessionId: [],
      designOption: [], 
      curveName: [], 
      avgRating: [],
    },
    defaultValues:{},
    isLoading: false,
    currentFilteration: {},
  },
  sort: {
    sortedField: '',
    sortBy: '',
  },
  multiLineView: {
    curves: [],
    designOptions: [],
    endPoint: '',
    views: [],
  },
  graphPreviewData: {},
  posteriorDistributionPreviewData: {},
  resultsViewRadioOption: {
    'Reported MTD Distribution': 'compareByToxCurve',
    'Subject Distribution': 'compareByToxCurve',
    'Sample Size Distribution': 'compareByToxCurve',
    'Expected # DLTs by Dose': 'compareByToxCurve',
    'Reported RP2D(Recommended Phase 2 Dose) Distribution': 'compareByToxCurve',
    'Expected # DLTs': 'compareByToxCurve',
    'Posterior Distribution': 'compareByToxCurve',
    'Total # Cohorts Distribution': 'compareByToxCurve',
    'Trial Duration Distribution': 'compareByToxCurve',
  },
  isRatingLoading: false,
  mtdTabsSpecialValues: {},
  posteriorDistributionTabsValues: {},
  cancelSimulationStatus:"",
};

const sessionResulSlice = createSlice({
  name: 'sessionResult',
  initialState: initialState as SessionResulsType,
  reducers: {
    fetchRequest: (state: SessionResulsType, action) => {
      const payload = action.payload;
      state.loading = payload.hideLoader ? false : true;
    },
    fetchSuccess: (state: SessionResulsType, action: PayloadAction<any>) => {
      const { data, totalRecords, isTestRun } = action.payload;
      state.data = data;
      state.totalRecords = totalRecords;
      state.pageCount = Math.ceil(+totalRecords / 4);
      state.loading = false;
      state.isTestRun = isTestRun;
      state.error = '';
      state.isLayoutLoading = false;
    },
    fetchError: (state, action: PayloadAction<any>) => {
      state.data = [];
      state.loading = false;
      state.error = action.payload;
    },
    resetSessionResults: (state) => {
      state.data = initialState.data;
      state.totalRecords = initialState.totalRecords;
      state.error = '';
      state.loading = false;
      state.pageCount = 0;
      state.isTestRun = '';
      state.resultsViewRadioOption = initialState.resultsViewRadioOption;
      state.mtdTabsSpecialValues = {};
      state.posteriorDistributionTabsValues = {};
    },
    addChartPreviewImgSrc: (state: SessionResulsType, action: PayloadAction<any>) => {
      const { chartPreviewImgSrc, designId, curveName } = action.payload;
      state.filter.results = state.filter.results
        .map(sessionData => {
          if(sessionData.sessionDesignId === designId) {
              let graphsData = sessionData?.graphData?.map((graphData: any) => (
                (graphData.name === curveName) ? {...graphData, chartPreviewImgSrc: chartPreviewImgSrc} : graphData
              ));
              sessionData.graphData = graphsData;
            } 
            return sessionData
          })
    },
    addCurvePreviewImgSrc: (state: SessionResulsType, action: PayloadAction<any>) => {
      const { curvePreviewImgSrc, designId } = action.payload;
      state.filter.results = state.filter.results
        .map(sessionData => {
          if(sessionData.sessionDesignId === designId) {
              sessionData.curvePreviewImgSrc = curvePreviewImgSrc;
            } 
            return sessionData
          })
    },
    switchColumns: (
      state,
      action: PayloadAction<{ index: number; arrowPos: string }>
    ) => {
      const { index, arrowPos } = action.payload;

      if (arrowPos === 'right') {
        [state.filter.results[index], state.filter.results[index + 1]] = [
          state.filter.results[index + 1],
          state.filter.results[index],
        ];
      }
      if (arrowPos === 'left') {
        [state.filter.results[index], state.filter.results[index - 1]] = [
          state.filter.results[index - 1],
          state.filter.results[index],
        ];
      }
    },
    userRatingRequest: (state: SessionResulsType, action: any) => {
      state.isRatingLoading = true;
    },
    userRatingSuccess: (state: SessionResulsType, action: any) => {
      state.isRatingLoading = false;
      const {
        resultid,
        data: { avgRating },
        ratingvalue,
      } = action.payload;
      const index = state.data.findIndex((el) => el.resultId === resultid);
      if (index > -1) {
        state.data[index].avgRating = avgRating
          ? avgRating
          : state.data[index].avgRating;
        state.data[index].userRating = ratingvalue
          ? ratingvalue
          : state.data[index].userRating;
      }
    },
    userRatingError: (state: SessionResulsType, action: any) => {
      state.isRatingLoading = false;
      state.error = JSON.stringify(action?.payload);
    },
    setIsCompare: (state: SessionResulsType, action) => {
      state.isCompare = action.payload;
    },
    setIsSameEndpointsCompare: (state: SessionResulsType, action) => {
      state.isSameEndpointsCompare = action.payload;
    },
    toggleSelectionResultsLoading: (state: SessionResulsType, action) => {
      state.loading = action.payload;
    },
    setFilters: (state: SessionResulsType) => {
      state.filter.isLoading = true;
      const fields = renderFields(state.data);
      const sortedResults = sort(state.data, 'projSessionId');
      state.sort.sortedField = 'projSessionId';
      state.sort.sortBy = 'asc';
      state.filter.results = sortedResults;
      state.filter.fields = fields;
      state.filter.defaultValues = fields;
      state.filter.isLoading = false;
    },
    updateFields: (state: SessionResulsType, action: PayloadAction<{ fieldName: string; value: any }>) => {
      const currentSelection = state.filter.currentFilteration;
      const { fieldName, value } = action.payload;
      let fieldsToRender = {};
      let defaultValueToRender = {};
      let data = state.filter.results;
      if(!Object.keys(currentSelection).length){
          const fields = { [fieldName]: { defaultValue: value, renderValue: state.filter.fields[fieldName], isPrimaryField: true  }};
          data = filterDataByField(state.data, value, fieldName);
          const updateFields = renderFields(data, fieldName);
          fieldsToRender = {...updateFields, [fieldName]: state.filter.fields[fieldName]};
          defaultValueToRender = {...updateFields, [fieldName]: value};
          state.filter.currentFilteration = {...fields};
      } else {
        const isAlreadyIncludedInCurrentSelection = currentSelection.hasOwnProperty(fieldName);
        if(isAlreadyIncludedInCurrentSelection){
          const { isPrimaryField = false, ...rest } = currentSelection[fieldName];
          if(isPrimaryField) {
            if(isEqual(sortBy(rest.renderValue), sortBy(value))){
              data = filterDataByField(state.data, value, fieldName);
              const updateFields = renderFields(data, fieldName);
              fieldsToRender = {...updateFields, [fieldName]: state.filter.fields[fieldName]};
              defaultValueToRender = {...updateFields, [fieldName]: value};
              state.filter.currentFilteration = {};
            } else{
              const fields = { [fieldName]: { defaultValue: value, renderValue: state.filter.fields[fieldName], isPrimaryField: true  }};
              data = filterDataByField(state.data, value, fieldName);
              const updateFields: any = renderFields(data, fieldName);
              const updateFilteration = Object.keys(updateFields).reduce((a: any, v: any) => ({ ...a, [v]: { defaultValue: updateFields[v], renderValue: updateFields[v],  } }), {})
              fieldsToRender = {...updateFields, [fieldName]: state.filter.fields[fieldName]};
              defaultValueToRender = {...updateFields, [fieldName]: value};
              state.filter.currentFilteration = {...updateFilteration, ...fields};
            }
          } else{
            if(!isEqual(sortBy(state.filter.defaultValues[fieldName]), sortBy(value))){
              const getPrimaryField = findKey(currentSelection, 'isPrimaryField') || '';
              const primaryField = getPrimaryField ? currentSelection[getPrimaryField] : {};
              const primarydata = filterDataByField(state.data, primaryField.defaultValue, getPrimaryField);
              const otherFieldsInSelection = omit(currentSelection, [fieldName, getPrimaryField]) || {};
              const fields = { [fieldName] : { defaultValue: value, renderValue: state.filter.fields[fieldName] } };
              if(!Object.keys(otherFieldsInSelection).length){
                const newData = filterDataByField(primarydata, value, fieldName);
                const updateFields = renderFields(newData, fieldName);
                const onlyUpdateFiledsNotIncluded = omit(updateFields, Object.keys(currentSelection));
                data = newData;
                fieldsToRender = {...state.filter.fields, ...onlyUpdateFiledsNotIncluded };
                defaultValueToRender = {...state.filter.defaultValues,...onlyUpdateFiledsNotIncluded, [fieldName]: value};
                state.filter.currentFilteration = {...state.filter.currentFilteration, ...fields};
              } else{
                const currentSelectionupdated: any = {...otherFieldsInSelection,...fields};
                let intialData = primarydata;
                for(let v in currentSelectionupdated){
                  const selection = currentSelectionupdated[v];
                  const tempData = filterDataByField(intialData, selection.defaultValue, v);
                  intialData = [...tempData];
                }
                const updateFields = renderFields(intialData, fieldName);
                const onlyUpdateFiledsNotIncluded = omit(updateFields, [fieldName, getPrimaryField]);
                data = intialData;
                fieldsToRender = {...state.filter.fields, ...onlyUpdateFiledsNotIncluded };
                defaultValueToRender = {...state.filter.defaultValues,...onlyUpdateFiledsNotIncluded, [fieldName]: value};
                state.filter.currentFilteration = {...state.filter.currentFilteration, ...fields};
              }
            }
          }
        } else {
          const fields = { [fieldName] : { defaultValue: value, renderValue: state.filter.fields[fieldName] } };
          data = filterDataByField(state.filter.results, value, fieldName);
          const updateFields = renderFields(data, fieldName);
          const getPrimaryField = findKey(currentSelection, 'isPrimaryField') || '';
          const onlyUpdateFiledsNotIncluded = omit(updateFields, [getPrimaryField, fieldName]);
          fieldsToRender = { ...state.filter.fields, ...onlyUpdateFiledsNotIncluded, [fieldName]: state.filter.fields[fieldName]};
          defaultValueToRender = {...state.filter.defaultValues,...onlyUpdateFiledsNotIncluded, [fieldName]: value};
          state.filter.currentFilteration = {...state.filter.currentFilteration, ...fields};
        }
      }

      state.filter.fields = fieldsToRender;
      state.filter.defaultValues = defaultValueToRender;
      state.filter.results = data;
    },
    resetFilters: (state: SessionResulsType) => {
      state.filter.currentFilteration = {};
      const updateFields = renderFields(state.data);
      state.filter.fields = {...updateFields};
      state.filter.defaultValues = {...updateFields};
      state.filter.results = state.data;
    },
    sortFields: (state: SessionResulsType, action: any) => {
      const fieldName = action.payload;
      const sortBy = state.sort.sortedField === fieldName ? state.sort.sortBy === 'asc' ? 'desc' : 'asc' : 'asc';
      const sortedResults = sort(state.filter.results, fieldName, sortBy);
      state.filter.results = sortedResults;
      state.sort.sortBy = sortBy;
      state.sort.sortedField = action.payload;
      
    },
    fetchCompareRequest: (state: SessionResulsType, action) => {
      state.loading = true;
    },
    setSessionResultLoading: (state: SessionResulsType, action) => {
      state.loading = action.payload.isLoading;
    },
    toogleLayout: (state: SessionResulsType, action) => {
      state.isLayoutLoading = action.payload;
    },
    multiLineView: (state: SessionResulsType, action: PayloadAction<MultiLineView>) => {
      const { curves = [], designOptions = [], endPoint = '', metaInfo = {}, views = [] } = action.payload;
      state.multiLineView.curves = curves;
      state.multiLineView.designOptions = designOptions;
      state.multiLineView.endPoint = endPoint;
      state.multiLineView.views = views;
      if(metaInfo && Object.keys(metaInfo).length){
        state.multiLineView.metaInfo = metaInfo;
      }
      
    },
    addGraphPreviewImgSrc: (state: SessionResulsType, action: PayloadAction<GraphPreviewData>) => {
      const { graphIdentifier, graphPreviewImgSrc, graphTableData, graphTitle, tooltip, error } = action.payload;
      state.graphPreviewData[graphIdentifier] = {
        graphImg: graphPreviewImgSrc, 
        tableData:graphTableData,
        title: graphTitle,
        tooltip,
        error
      }
    },
    addPosteriorDistributionImgSrc: (state: SessionResulsType, action: PayloadAction<any>) => {
      const { graphId, graphLabel, graphsArray } = action.payload;
      state.posteriorDistributionPreviewData[graphId] = {
        graphData: graphsArray, 
        title: graphLabel
      }
    },
    updateResultsViewRadio: (state: SessionResulsType, action: PayloadAction<UpdateResultViewData>) => {
      const { viewId, compareByValue } = action.payload;
      state.resultsViewRadioOption[viewId] = compareByValue;
    },
    setInitialMtdSpecialValues :(state: SessionResulsType, action) => {
      state.mtdTabsSpecialValues = action.payload;
    },
    updateMtdTabSpecialValues: (state: SessionResulsType, action) => {
      const { tabId, specialValues } = action.payload;
      state.mtdTabsSpecialValues[tabId] = specialValues;
    },
    setInitialPosteriorDistributionTabsValues :(state: SessionResulsType, action) => {
      state.posteriorDistributionTabsValues = action.payload;
    },
    updatePosteriorDistributionValues: (state: SessionResulsType, action) => {
      const { tabId, posteriorDistributionValues } = action.payload;
      state.posteriorDistributionTabsValues[tabId] = posteriorDistributionValues;
    },
    updateResults:(state: SessionResulsType, action) => {
      const newPartResults = action.payload?.data || [];
      const parseresultsData = newPartResults.length ? getParseData(newPartResults, false) : [];

      const newResults = state.data.map((el: any) => {
        const newRes = parseresultsData.find((result: any) => result.resultId === el.resultId);
        return newRes ? newRes : el;
      });

      const processedDatForMultiLineView = processMultiLineData(newResults);
      const {curves = [], designOptions = []} = processedDatForMultiLineView;

      state.multiLineView.curves = curves;
      state.multiLineView.designOptions = designOptions;
      state.data = newResults;
    },
    cancelSimulationRequest:(state, action) => {
      state.cancelSimulationStatus = ""
    },
    cancelSimulationStatus:(state, action) =>{
      state.cancelSimulationStatus = action.payload
    },
    cancelSimulationFailure: (state, action) =>{
      state.cancelSimulationStatus = action.payload
    },
    resetSimulationStatus : (state) => {
      state.cancelSimulationStatus = ""
    }
  },
});

export const {
  fetchError,
  fetchSuccess,
  fetchRequest,
  resetSessionResults,
  switchColumns,
  addChartPreviewImgSrc,
  addCurvePreviewImgSrc,
  userRatingRequest,
  userRatingSuccess,
  userRatingError,
  setIsCompare,
  setIsSameEndpointsCompare,
  toggleSelectionResultsLoading,
  setFilters,
  sortFields,
  updateFields,
  resetFilters,
  fetchCompareRequest,
  setSessionResultLoading,
  toogleLayout,
  multiLineView,
  addGraphPreviewImgSrc,
  addPosteriorDistributionImgSrc,
  updateResultsViewRadio,
  setInitialMtdSpecialValues,
  updateMtdTabSpecialValues,
  setInitialPosteriorDistributionTabsValues,
  updatePosteriorDistributionValues,
  updateResults,
  cancelSimulationRequest,
  cancelSimulationStatus,
  cancelSimulationFailure,
  resetSimulationStatus
} = sessionResulSlice.actions;

export default sessionResulSlice.reducer;

export const ratingAvg = (item: number): number => {
  if(item >= 5) {
    return 5;
  } else if(item >= 4 && item < 5) {
    return 4;
  } else if(item >= 3 && item < 4) {
    return 3;
  } else if(item >= 2 && item < 3) {
    return 2;
  } else if(item >= 1 && item < 2) {
    return 1;
  } else {
    return 0;
  }
}

export const filterDataByField = (data: any, fieldValue: any, filterBy: string) => {
  if(filterBy === 'avgRating'){
  return data.filter((f: any) => {
      return fieldValue.some((item: any) => {
        const props = parseFloat(f[filterBy]) || 0;
        if(item === 1 && (props <= 1 && props < 2)){
          return true;
        } else if(item === 2 && (props >= 2 && props < 3)){
          return true;
        } else if(item === 3 && (props >= 3 && props < 4)){
          return true;
        } else if (item === 4 && (props >= 4 && props < 5)){
          return true;
        } else if(item === 5 && props === 5){
          return true;
        } else if(item === 0 && props === 0){
          return true;
        } else {
          return false;
        }
      })
    });
  }
  return data.filter((f: any) => fieldValue.some((item: any) => item === f[filterBy]));
};

export const getAvgRatingData = (data: any) => {
  return data.map((f: any) => ({ ...f, avgRating: (f.avgRating === null || f.avgRating === undefined) ? 0 : f.avgRating }));
};

const sort = (data: any, fieldName: any, sortBy: any = 'asc') => {
  const getData = fieldName === 'avgRating' ? getAvgRatingData(data) : data;
  return orderBy(getData, [fieldName], [sortBy]);
};

export const renderFields = (sessionData: any[], fieldName?: any) => {
  const projSessionId = getUniqueItem(sessionData, 'projSessionId')
  const designOption = getUniqueItem(sessionData, 'designOption')
  const curveName = getUniqueItem(sessionData, 'curveName')
  const avgRating = [...new Set(sessionData.map((v: any) => ratingAvg(v.avgRating)))];
  if(fieldName === 'projSessionId'){
    return { designOption, curveName, avgRating };
  } else if(fieldName === 'designOption'){
    return { curveName, avgRating, projSessionId };
  } else if (fieldName === 'curveName'){
    return { avgRating, designOption, projSessionId };
  } else if (fieldName === 'avgRating'){
    return { designOption, curveName, projSessionId };
  } else{
    return { designOption, curveName, avgRating, projSessionId };
  }
}

const getUniqueItem = (list: any[], key: string) => [...new Set(list.map((item: any) => item[key]))];
