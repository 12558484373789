import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DesignOptionState,
  DesignOptionListTypes,
  DesignOptionsTypes,
} from '../types/SessionTypes';
import { incrementLetter } from '../../../common/utils/incrementLetters';
import { findLast } from 'lodash';
import { getPropertyName } from '../utils/designOptionsUtils';
import { parseToPrecision } from '../../../common/utils';

const initialState: DesignOptionState = {
  isLoading: false,
  data: [],
  error: '',
  selectedDesigns: {
    '3+3': [],
    'i3+3': [],
    CRM: [],
    'BOIN': [],
    'mTPI-2': [],
    REDOS: [],
    BLRM: [],
    'PRINTE': []
  },
  sdesign: [] as any[],
  // totalDesigns: 0,
  valid: true,
  removedDesignList:[],
  defaultFields:{
    'ALL':{},
    'mplusn':{},
    'BOIN': {}
  },
  computedFields:{},
  graphData: []
};

export const designOptionSlice = createSlice({
  name: 'designOption',
  initialState: initialState as DesignOptionState,
  reducers: {
    fetchRequest: (state: DesignOptionState) => {
      state.isLoading = true;
    },
    fetchSuccess: (state: DesignOptionState, action: PayloadAction<any>) => {
      const {
        payload: { data },
      } = action;

      state.data = data;
      state.isLoading = false;
      data.length &&
        data.forEach((el: DesignOptionsTypes) => {
          const des: keyof DesignOptionListTypes = el.design_option as keyof DesignOptionListTypes;
          state.selectedDesigns[des] = [];
        });
    },
    fetchFailure: (state: DesignOptionState, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    selectDesignsRequest: (state: DesignOptionState, action) => {
      state.isLoading = true;
    },
    selectedDesign: (
      state: DesignOptionState,
      action: PayloadAction<{
        design_option: 'string', // keyof DesignOptionListTypes;
        design_id: 'string';
      }>
    ) => {
      const { design_option, design_id } = action.payload;

      const lastdesign = findLast(state.sdesign, (el) => {
        return el.design_option === design_option;
      });
      
      let nextLetter = '';
      if(lastdesign?.hasOwnProperty('meta')) {
        nextLetter = incrementLetter(lastdesign?.meta?.designLetter?.letter)
      } else {
        if (
          typeof lastdesign?.design_option === 'undefined' ||
          lastdesign.letter
        ) {
          nextLetter = lastdesign?.design_option
            ? incrementLetter(lastdesign?.letter)
            : incrementLetter('');
        } else {
          const lengthByDesign = state.sdesign.filter(
            (el:any) => el.design_option === design_option
          ).length;
          const lastletter = String.fromCharCode(64 + lengthByDesign);
          nextLetter = incrementLetter(lastletter);
        }
      }

      const referenceName = `${design_option} : ${nextLetter}`;
      let rstr = Math.random().toString().substring(7);

      // Extract Form Data
      let defaultFields = state.defaultFields[getPropertyName(design_option)];
      let initialStateParams:any = {};
      for (let key in defaultFields) {
        const fieldData = defaultFields[key];
        initialStateParams[fieldData.name] = fieldData.value;
      }
      
      state.sdesign.push({
        ...initialStateParams,
        design_id,
        design_option,
        referenceName,
        letter: nextLetter,
        valid: true,
        uid: rstr,
        meta:{ designLetter:{letter:nextLetter}},
      });
      
      const isvalid = state.sdesign.every((el) => {
        return el.valid;
      });
      state.valid = isvalid;
      state.isLoading = false;
    },
    removeDesignRequest: (state: DesignOptionState, action) => {
      state.isLoading = true;
    },
    removeDesign: (
      state: DesignOptionState,
      action: PayloadAction<{
        design_option: keyof DesignOptionListTypes;
        designId: string;
        index: number;
      }>
    ) => {
      const { index } = action.payload;
      const checkElWithDesignId = state.sdesign[index]?.smltn_dsgn_ssn_id;

      if (checkElWithDesignId) {
        state.sdesign[index]['is_del_flg'] = 1;
        const newDesigns = state.sdesign.filter((el, i) => {
          return i !== index;
        });
        state.removedDesignList.push(state.sdesign[index]);
        state.sdesign = [...newDesigns];
      } else {
        const newDesigns = state.sdesign.filter((el, i) => {
          return i !== index;
        });
        const isvalid =
          newDesigns.filter((el) => {
            return el.valid;
          }).length > 0;
        state.valid = isvalid;
        state.sdesign = [...newDesigns];
        
        delete state.computedFields['']
      }
      state.isLoading = false;
    },
    resetDesignOptions: (state: DesignOptionState) => {
      state.sdesign = initialState.sdesign;
      state.removedDesignList = [];
      state.computedFields = {};
    },
    resetremovedDesignList: (state: DesignOptionState) => {
      state.removedDesignList = [];
    },
    updateDesignSelection: (state: DesignOptionState, action: any) => {
      const {
        data: {
          index,
          design_option,
          referenceName,
          letter,
          design_id,
          smltn_dsgn_ssn_id,
          uid,
          meta
        },
        valid,
      } = action.payload;
      state.sdesign[index] = {
        ...action.payload.data,
        design_option,
        referenceName,
        letter,
        valid,
        design_id,
        smltn_dsgn_ssn_id: smltn_dsgn_ssn_id ? smltn_dsgn_ssn_id : null,
        uid,
        meta
      }
      const isvalid = state.sdesign.every((el) => {
        return el.valid;
      });
      state.valid = isvalid;
    },
    initialzeDesignOptions: (state: DesignOptionState, action: any) => {
      const designs: any[] = getParseDesign(action.payload);
      state.sdesign = designs.map((el: any) => {
        if (el.design_option === 'BOIN') {
          return {
            ...el,
            λeboin: parseToPrecision(el.λeboin),
            λdboin: parseToPrecision(el.λdboin),
          };
        }
        return el;
      });

      state.computedFields = designs.reduce((acc: any, curr: any) => {
        if (curr.design_option === 'BOIN') {
          return {
            ...acc,
            ...{
              [curr.referenceName]: {
                referenceName: curr.referenceName,
                λeboin: curr.λeboin,
                λdboin: curr.λdboin,
              },
            },
          };
        }
        return acc;
      }, {});


    },
    updateDesignSimulationId: (state: DesignOptionState, action) => {
      state.sdesign = [];
      state.sdesign = action.payload.map((el: any) => {
        return {
          design_option: el.design_name,
          referenceName: el.ref_dsgn_name,
          letter: el.letter,
          maxSubjectSize: el?.dsgn_params[0]?.value,
          cohortSize: +el?.dsgn_params[1]?.value,
          valid: true,
          design_id: el.dsgn_id,
          smltn_dsgn_ssn_id: el.smltn_dsgn_ssn_id,
        };
      });
    },
    fetchRequestDefaultFields:(state:DesignOptionState) => {
      state.isLoading = true;
    },
    getDesignOptionsDefaultFields:(state: DesignOptionState,action:any) => {
      state.isLoading = false;
      state.defaultFields = mapToInputs(action.payload);
    },
    updateComputedValues: (state: DesignOptionState,action:any) => {
      const data = action.payload;
       if(state.computedFields[data.referenceName]){
          state.computedFields[data.referenceName] = {...state.computedFields[data.referenceName], ...data}
       } else {
        state.computedFields[data.referenceName] = data
       }
    },
    removeComputedValues: (state: DesignOptionState, action:any) => {
        const refName = action.payload;
        delete state.computedFields[refName];
    },
    fetchUtilityFuncGraph: (state: DesignOptionState, action:any) => {
      const { index } = action.payload;
      let initialGraphData = Object.assign({}, graphDataSkeleton);
      initialGraphData.isInputGraphLoading = true;
      state.graphData[index] = initialGraphData;
    },
    updateUtilityFuncGraph: (state: DesignOptionState, action:any) => {
      const { graphData, index } = action.payload;
      let initialGraphData = Object.assign({}, graphDataSkeleton);

      if(graphData?.length) {
        initialGraphData.utilityGraph = graphData[0];
        initialGraphData.regionGraph = graphData[1];
        initialGraphData.isInputGraphLoading = false;
        initialGraphData.hasRcodeError  = false;
      } else {
        initialGraphData.isInputGraphLoading = false;
        initialGraphData.hasRcodeError  = true;
      }
      state.graphData[index] = initialGraphData;
    },
    removeUtilityFuncGraph:  (state: DesignOptionState, action:any) => {
      let initialGraphData = Object.assign({}, graphDataSkeleton);
      const { index } = action.payload;
      state.graphData[index] = initialGraphData;
    }
  },
});

export const {
  fetchRequest,
  fetchFailure,
  fetchSuccess,
  selectDesignsRequest,
  selectedDesign,
  removeDesignRequest,
  removeDesign,
  resetDesignOptions,
  updateDesignSelection,
  initialzeDesignOptions,
  updateDesignSimulationId,
  resetremovedDesignList,
  getDesignOptionsDefaultFields,
  fetchRequestDefaultFields,
  updateComputedValues,
  removeComputedValues,
  fetchUtilityFuncGraph,
  updateUtilityFuncGraph,
  removeUtilityFuncGraph
} = designOptionSlice.actions;
export default designOptionSlice.reducer;

const getParseDesign = (data: any): any[] => {
  return data.map((el: any) => {
      let initialStateParams:any = {};
      for (let key in el.designParams) {
        const fieldData = el.designParams[key];
        initialStateParams[fieldData.name] = fieldData.value;
      }
      const metaData = generateFormMetaData(el, initialStateParams);
      
      return {
        ...initialStateParams,
        design_option: el.designOption,
        referenceName: el.designName,
        design_id: el.designId,
        smltn_dsgn_ssn_id: el.simulationDesignSessionId,
        valid:true,
        uid: Math.random().toString().substring(7),
        meta: metaData
      };
  });
};

// Create & add any designoption or Session specific data liek hide/show.
// Perform any manipulations which are restricted to frontend.
const generateFormMetaData = (design: any, initialStateParams: any) => {
  if(design.designOption === "BOIN") {
    return {
      ...design?.meta,
      fields: {
        'offsetboin': { 
          hidden: !initialStateParams.extraSafeboin
        }
      }
    }
  };
  
  return design?.meta
};

export const mapToInputs = (data: [any]) => {
  if (!data.length) {
    return [];
  }
  return data.reduce((acc: any, cur: any) => {
    const attr = cur['dsgn_option'];
    if (attr) {
      acc[getPropertyName(attr)] = 
      cur['details'].reduce(
        (p:any, c:any) => ({
          ...p,
          ...{
            [c?.name]: {...c}
          },
        }),
        {}
      )
    }
    return acc;
  }, {});
};

export const graphDataSkeleton = {
  isInputGraphLoading: false,
  hasRcodeError: false,
  utilityGraph: {
    filename: '',
    plots: ''
  },
  regionGraph: {
    filename: '',
    plots: '' 
  }
};