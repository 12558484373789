import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { filterObjByRegEx, sortSesssionCurves } from '../../../common/utils/toxicityUtil';
import { LogisticCurveHillCurveType, LinearType,ExponentialSaturation, CurveParams,QuadraticConcave,TwoParamEquationParamType } from '../types/SessionTypes';
import { differenceBy, isEqual } from 'lodash';
import { CONSTANTS } from '../../../common/constants/index';
import { ToxicityCurve } from '../types/SessionTypes';
import { DOSE_ESC_CONSTANTS } from '../../../common/constants/doseEscalation';
import { formEfficacyCurvesSessionLoad, sortSesssionEfficacyCurves } from '../../../common/utils/efficacyUtil';

export type ProjectLevelCurves = {
  toxicityCurveId: number;
  toxicityCurveType: string;
  projectId: number;
  toxicityCurveName: string;
  curveParams: any;
};

export const toxiCityCurveSlice = createSlice({
  name: 'toxiCityCurve',
  initialState: {
    rows: [] as any,
    curveName: '',
    isLoading: false,
    curveType: '',
    curvesData: {
      tableData: {} as any,
      detail: {} as any,
    },
    curvesList: [] as any,
    doseAmounts: [] as any,
    placebo: 0.1 as number | string,
    slope: 100 as number | string,
    linearEqParamsValidity: true,
    YAxisValues: true,
    empericalIsIncremental: true,
    logisticCurve: {} as LogisticCurveHillCurveType,
    hillCurve: {} as LogisticCurveHillCurveType,
    quadratic: {} as QuadraticConcave,
    projectLevelCurves: {} as any,
    duplicateProjectCurveName: null,
    getProjectCurvesLoading: false,
    linearCurveParams: {} as LinearType,
    removedCurvesList:[] as Array<ToxicityCurve>,
    exponential : {} as ExponentialSaturation,
    isEdit:false,
    editRow: {} as any,
    previewRow:{} as any,
    editedCurveName:{},
    curveDoseLevel:'' as any,
    sessionEquationCurvesValid:false,
    projectCurves:{
      empirical:[] as Array<ToxicityCurve>,
      nonEmpirical:[] as Array<ToxicityCurve>,
      rows:[] as Array<ToxicityCurve>
    },
    sessionCurves:{
      empirical:[] as Array<ToxicityCurve>,
      nonEmpirical:[] as Array<ToxicityCurve>,
      rows:[] as Array<ToxicityCurve>
    },
    empiricalCurveData:{} as any,
    isConcaveUP:true,
    efficacyCurveType:'',
    twoParamEquationParams:{} as TwoParamEquationParamType 
  },
  reducers: {
    curveRequest: (state) => {
      state.isLoading = true;
    },
    setrowData: (state, action) => {
      state.rows = action.payload.isToxEfficacy?action.payload.newRow.flat():action.payload.newRow;
      if (
        action.payload.removedCurve &&
        action.payload.isToxEfficacy ?action.payload.removedCurve[0].smltn_txcty_id :action.payload.removedCurve.smltn_txcty_id
      ) {
        action.payload.isToxEfficacy ?state.removedCurvesList=[...state.removedCurvesList,action.payload.removedCurve] :
        state.removedCurvesList = [
          ...state.removedCurvesList,
          { ...action.payload.removedCurve, is_del_flg: 1 },
        ];
      }

    },
    addCurves: (state,action) => {
      let curveName = state.curvesData.tableData.curveName;
      const index = curveName?.indexOf('(');
      curveName = index > 0 ? curveName.slice(0, index).trim(' ') : curveName;
      state.curvesData.tableData.curveName = curveName;
      state.curvesData.tableData.is_carry_fwd_flg = 0;
      state.curvesData.tableData.projSessionId = 'Current';
      if(!state.curvesData.tableData.hasOwnProperty('meta')) {
        state.curvesData.tableData.meta = {};
        state.curvesData.tableData.meta['crv_dose_amounts']=action.payload?.doseAmounts;
        state.curvesData.tableData.meta['maxDoseLevel']= action.payload?.maxDoseLevel;
      } 
      if (state.curvesData.tableData.curveType === 'Empirical') {
        state.curvesData.tableData.curveId = 1;
        state.rows.push(state.curvesData.tableData);
      }
      if (state.curvesData.tableData.curveType === 'Linear') {
        state.curvesData.tableData.curveId = 2;
        state.curvesData.tableData.formulaParams = {
          e0Linear: state.linearCurveParams.e0Linear,
          b1: state.linearCurveParams.b1,
        };
        state.rows.push(state.curvesData.tableData);
      }
      if (state.curvesData.tableData.curveType === 'Quadratic') {
        state.curvesData.tableData.curveId = 3;
        state.curvesData.tableData.formulaParams = {
            a:state.quadratic.a,
            k:state.quadratic.k,
            concaveH:state.quadratic.concaveH,
            isConcaveUp:state.quadratic.isConcaveUp
        };
        state.rows.push(state.curvesData.tableData);
      }
      if (state.curvesData.tableData.curveType === 'Hill') {
        state.curvesData.tableData.curveId = 4;
        state.curvesData.tableData.formulaParams = {
          e0: state.hillCurve.e0,
          eD50: state.hillCurve.eD50,
          eMax: state.hillCurve.eMax,
          h: state.hillCurve.h,
        };
        state.rows.push(state.curvesData.tableData);
      }
      if (state.curvesData.tableData.curveType === 'Logistic') {
        state.curvesData.tableData.curveId = 5;
        state.curvesData.tableData.formulaParams = {
          e0: state.logisticCurve.e0,
          eD50: state.logisticCurve.eD50,
          eMax: state.logisticCurve.eMax,
          h: state.logisticCurve.h,
        };
        state.rows.push(state.curvesData.tableData);
      }
      if (state.curvesData.tableData.curveType === 'Exponential Saturation') {
        state.curvesData.tableData.curveId = 6;
        state.curvesData.tableData.formulaParams = {
          e0: state.exponential.e0,
          eMax: state.exponential.eMax,
          h: state.exponential.h,
        };
        state.rows.push(state.curvesData.tableData);
      }
      state.sessionCurves.rows.push(state.curvesData.tableData);
      const sortedData = sortSesssionCurves(state.sessionCurves.rows);
      state.sessionCurves.rows = [...sortedData];
      state.projectLevelCurves.totalCurvesCounts = state.projectLevelCurves.totalCurvesCounts.map((e:any)=> {
        if(e.curveType === state.curvesData.tableData.curveType ) {
          e.count = +e.count + 1;
        }
        return e
      })
    },
    resetsRows: (state) => {
      state.rows = [];
      state.curveName = '';
      state.curveType = '';
      state.curvesData.tableData = {};
      state.curvesData.detail = {};
      state.doseAmounts = [];
      state.removedCurvesList = [];
    },
    resetRemovedCurvedlist:(state) =>{
      state.removedCurvesList = [];
    },
    resetsCurveDetails: (state) => {
      state.curveName = '';
      state.curveType = '';
      state.curvesData.tableData = {};
      state.curvesData.detail = {};
    },
    setCurve: (state, action) => {
      state.curveName = action.payload;
      state.isLoading = false;
    },
    setCurveType: (state, action) => {
      state.curveType = action.payload;
      state.isLoading = false;
    },
    setEfficacyCurveType:(state,action) => {
      state.efficacyCurveType = action.payload;
    },
    updateCurvesData: (state, action) => {
      state.curvesData.tableData = action.payload?.tableData;
      state.curvesData.detail = action.payload;
      state.isLoading = false;
    },
    updateTablesData: (state, action) => {
      state.curvesData.tableData = action.payload?.tableData;
    },
    getCurvesList: (state) => {
      state.isLoading = true;
    },
    setCurvesList: (state, action) => {
      state.isLoading = false;
      state.curvesList = action.payload;
    },
    updateDoseAmounts: (state, action) => {
      state.doseAmounts = action.payload;
    },
    updatePlaceboValue: (state, action) => {
      state.placebo = action.payload;
    },
    updateSlopeValue: (state, action) => {
      state.slope = action.payload;
    },
    updateLinearCurve: (state, action: PayloadAction<LinearType>) => {
      state.linearCurveParams = { ...action.payload };
    },
    updateLinearEqParams: (state, action) => {
      state.linearEqParamsValidity = action.payload;
    },
    updateYAxisValues: (state, action) => {
      state.YAxisValues = action.payload;
    },
    updateIsIncremental: (state, action) => {
      state.empericalIsIncremental = action.payload;
    },
    updateLogisticCurve: (
      state,
      action: PayloadAction<LogisticCurveHillCurveType>
    ) => {
      state.logisticCurve = { ...action.payload };
    },
    updateHillCurve: (
      state,
      action: PayloadAction<LogisticCurveHillCurveType>
    ) => {
      state.hillCurve = { ...action.payload };
    },
    initialzeCurves: (state, action) => {
      const {endPoint} = action.payload?.trialParams;
      if(endPoint !== DOSE_ESC_CONSTANTS.ENDPOINTS.TOXEFFICACY) {
        const rows = action.payload?.data.map((el: any) => {
          return {
            ...el,
            ...el.curveParams,
            smltn_txcty_id: el.simulationToxicityCurveId,
            is_carry_fwd_flg: el.isProjectLevelCurve
              ? 1
              : 0,
            formulaParams:{...el.curveEquationParams}
          };
        });
        state.rows = rows;
        const projCurves = state.projectLevelCurves?.projectLevelCurves?.map((e:any)=> ({...e}))
        let curvesData = formCurvesSessionLoad(rows,projCurves,action.payload?.trialParams);
        state.projectCurves.empirical = curvesData?.empiricalProj;
        state.projectCurves.nonEmpirical = curvesData?.nonEmpProj;
        state.sessionCurves.rows = curvesData?.rows;
        state.projectCurves.rows= [...curvesData?.empiricalProj,...curvesData?.nonEmpProj]
        state.sessionCurves.empirical = curvesData?.empiricalSession;
        state.sessionCurves.nonEmpirical = curvesData?.nonEmpiricalSession;
      } else {
        let formatedRows =  action.payload?.data.map((curves:any)=>curves.map((curve:any)=>({
          ...curve,
          ...curve.curveParams, 
          projSessionId:'Current',
          smltn_txcty_id: curve.simulationToxicityCurveId,
          is_carry_fwd_flg: curve.isProjectLevelCurve
            ? 1
            : 0,
          formulaParams:{...curve.curveEquationParams}
        })))
        const projCurves = state.projectLevelCurves?.projectLevelEfficacyCurves?.map((e:any)=> [...e])
        const { matchingProjectCurves,sessionCurves } =formEfficacyCurvesSessionLoad(formatedRows, 
          projCurves,
          action.payload?.trialParams
          )
          state.sessionCurves.rows = sessionCurves;
          state.projectCurves.rows = matchingProjectCurves as unknown as ToxicityCurve[];
      }
    },
    updateQuadraticCurve: (state, action: PayloadAction<QuadraticConcave>) => {
      state.quadratic = { ...action.payload };
    },
    getProjectLevelCurves: (state, action) => {
      state.getProjectCurvesLoading = true;
    },
    getProjectLevelCurvesSuccess: (state, action) => {
      state.getProjectCurvesLoading = false;
      state.projectLevelCurves = {...action.payload?.data};
      state.projectCurves = formatProjectCurves(action.payload?.data?.projectLevelCurves,action?.payload?.sid);
      state.rows = action?.payload?.data?.projectLevelCurves.map((curve: any) => {
        let params = curve.hasOwnProperty('curveParams')
          ? curve.curveParams
          : filterObjByRegEx(curve, /^d/);
        return {
          curveName: curve.curveName,
          ...params,
          is_carry_fwd_flg: curve.isProjectLevelCurve ? 1 : 0,
          curveId: curve.curveId,
          curveType: curve.curveType,
          formulaParams: {...curve.curveEquationParams},
          smltn_txcty_id:curve.simulationToxicityCurveId,
          ...curve
        };
      });
    },
    getProjectLevelCurvesFailure: (state, action) => {
      state.getProjectCurvesLoading = false;
      state.projectLevelCurves = action.payload;
    },
    toggleLoading: (state, action) => {
      state.getProjectCurvesLoading = action.payload;
    },
    updateDuplicateProjectCurveName: (state, action) => {
      state.duplicateProjectCurveName = action.payload;
      state.getProjectCurvesLoading = false;
    },
    updateProjectLevelCurves: (state, action) => {
      state.projectLevelCurves = action.payload;
    },
    updateCurveToxictyId: (state,action) =>{
      state.rows = action.payload.map((el: any) => {
        return {
          is_carry_fwd_flg: el.is_carry_fwd_flg,
          curveId: el.txcty_type_id,
          curveType: '',
          curveName: el.txcty_crv_nm,
          smltn_txcty_id: el.smltn_txcty_id,
          ...el.crv_params,
        };
      });
    },
    updateExponentialSaturation : (state,action:PayloadAction<ExponentialSaturation>) => {
      state.exponential = { ...action.payload }
    },
    toggleIsEdit:(state,action:PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
    setEditRow:(state,action) => {
      state.editRow = action.payload;
    },
    saveEdit:(state,action) => {
      state.sessionCurves.rows = action.payload?.data;
    },
    setPreviewRow:(state,action) => {
      state.previewRow = action.payload;
    },
    setEditedPreviewName:(state,action) => {
      state.editedCurveName = action.payload;
    },
    updateCurveDoseLevel:(state,action) => {
      state.curveDoseLevel = action.payload;
    },
    updateRowsDoseAmountChange:(state,action) => {
      const sorted = sortSesssionCurves(action.payload);
      state.sessionCurves.rows=[...sorted];
    },
    updateSessionEquationCurvesValidity:(state,action) => {
      state.sessionEquationCurvesValid = action.payload;
    },
    resetSessionCurves:(state)=> {
      state.sessionCurves.empirical =[];
      state.sessionCurves.nonEmpirical =[];
      state.sessionCurves.rows =[];
    },
    resetProjectLevelCurves:(state) => {
      state.projectCurves.empirical = [];
      state.projectCurves.nonEmpirical = [];
      state.projectCurves.rows = [];
    },
    removeCurves:(state,action) => {
        const sorted = sortSesssionCurves(action?.payload?.data?.rows)
        state.sessionCurves.rows = [...sorted]
    },
    updateProjectCurves:(state,action) => {
      state.projectCurves.empirical = action.payload?.empirical;
      state.projectCurves.nonEmpirical = action.payload?.nonEmpirical;
      state.projectCurves.rows = action.payload?.rows;
    },
    updateRemovedCurveList:(state,action) => {
      state.removedCurvesList = [...state.removedCurvesList,...action.payload];
    },
    updateEmpiricalCurveData:(state,action) => {
      state.empiricalCurveData = action.payload;
    },
    addProjCurveToSession:(state,action) => {
      const {data,isToxEfficacy} = action.payload;
      const sortedData = sortSesssionCurves([...state.sessionCurves.rows,!isToxEfficacy?{...data}:data]);
      state.sessionCurves.rows=[...sortedData];
    },
    toggleConcaveType:(state,action) => {
      state.isConcaveUP = action.payload
    },
    setTwoParamEquation:(state,action:PayloadAction<TwoParamEquationParamType>) => {
      state.twoParamEquationParams = {...action.payload}
    },
    addEfficacyCurves:(state,action) => {
      let efficacyCurves =  state.curvesData.tableData;
      let curveName = state.curveName;
      efficacyCurves = efficacyCurves.map((curve:any,i:number)=>(
        {...curve,
          curveName,
          is_carry_fwd_flg :0,
          projSessionId:'Current',
          meta:{
          crv_dose_amounts:action.payload?.doseAmounts,
          maxDoseLevel:action.payload?.maxDoseLevel,
        },
        ...i === 0 ?{formulaParams:{...state.twoParamEquationParams?.toxicity || {}}} : {formulaParams:{...state.twoParamEquationParams?.efficacy || {}}}
      }))
      state.sessionCurves.rows.push(efficacyCurves);
      const sortedData = sortSesssionEfficacyCurves(state.sessionCurves.rows as unknown as ToxicityCurve[][]);
      state.sessionCurves.rows=[...sortedData] as any
      state.projectLevelCurves.totalEfficacyCurvesCounts = state?.projectLevelCurves?.totalEfficacyCurvesCounts?.map((e:any)=> 
        ({count:+e.count+1})
      )
    }
  },
});

export const {
  setrowData,
  addCurves,
  resetsRows,
  setCurve,
  curveRequest,
  setCurveType,
  updateCurvesData,
  updateTablesData,
  resetsCurveDetails,
  getCurvesList,
  setCurvesList,
  updateDoseAmounts,
  updatePlaceboValue,
  updateSlopeValue,
  updateLinearEqParams,
  updateYAxisValues,
  updateIsIncremental,
  updateLogisticCurve,
  updateHillCurve,
  updateQuadraticCurve,
  getProjectLevelCurves,
  getProjectLevelCurvesSuccess,
  getProjectLevelCurvesFailure,
  toggleLoading,
  updateDuplicateProjectCurveName,
  updateLinearCurve,
  updateProjectLevelCurves,
  initialzeCurves,
  updateCurveToxictyId,
  resetRemovedCurvedlist,
  updateExponentialSaturation,
  toggleIsEdit,
  setEditRow,
  saveEdit,
  setPreviewRow,
  setEditedPreviewName,
  updateCurveDoseLevel,
  updateRowsDoseAmountChange,
  updateSessionEquationCurvesValidity,
  resetSessionCurves,
  resetProjectLevelCurves,
  removeCurves,
  updateProjectCurves,
  updateRemovedCurveList,
  updateEmpiricalCurveData,
  addProjCurveToSession,
  toggleConcaveType,
  setEfficacyCurveType,
  setTwoParamEquation,
  addEfficacyCurves
} = toxiCityCurveSlice.actions;

export default toxiCityCurveSlice.reducer;

const formatProjectCurves= (rows:any,sid:any) => {
  let empiricalCurves:any[] = rows?.filter((r:any)=> r?.curveId === 1).map((el: any) => {
    return {
      ...el,
      ...el.curveParams,
      smltn_txcty_id: el.simulationToxicityCurveId,
      is_carry_fwd_flg: el.isProjectLevelCurve
        ? 1
        : 0,
      formulaParams:{...el.curveEquationParams},
      isHeader:false
    };
  });
  return {
    empirical:[...empiricalCurves.filter((e:any)=>(+e?.meta?.maxDoseLevel === CONSTANTS.DOSE_ESCALATION.DEFAULT_DOSE_LEVEL && isEqual(e?.meta?.crv_dose_amounts,Array(CONSTANTS.DOSE_ESCALATION.DEFAULT_DOSE_LEVEL).fill(''))))],
    nonEmpirical:[],
    rows:[]
  }
}

export const getCurveParamsInRange = (curveParams:CurveParams={},maxDoseLevel:string|number) => {
  let formatedCurveParams:CurveParams= {};
  for(let i=0 ;i <+maxDoseLevel;i++) {
    formatedCurveParams['d'+ (i+1)] = curveParams['d'+(i+1)]
  }
  return formatedCurveParams;
}
export const formCurvesSessionLoad = (sessionCurves:Array<ToxicityCurve>,projectCurves:Array<ToxicityCurve>,trialParams:{maxDoseLevels:string|number,doseAmounts:Array<number | string>}) => {
  let sessionProjectCurvesFiltered:Array<ToxicityCurve> = sessionCurves.filter((ss:ToxicityCurve)=>(projectCurves?.find((p:ToxicityCurve)=>(p?.curveName === ss?.curveName)))).map((e:ToxicityCurve)=> {
    let projCurve = projectCurves?.find((p:ToxicityCurve) => p?.curveName === e?.curveName);
    if(projCurve) {
        return {...e,projSessionId:projCurve?.projSessionId} }
    else return {...e}
}
);
  let sessionCurvesFiltered:Array<ToxicityCurve>  = differenceBy(sessionCurves,projectCurves,'curveName');
  let empiricalCurvesProject:Array<ToxicityCurve> = projectCurves?.filter((r:ToxicityCurve)=> r?.curveId === 1).filter((e:ToxicityCurve)=>(+e?.meta?.maxDoseLevel >= trialParams?.maxDoseLevels))
  .filter((v:ToxicityCurve)=> {
   if(isEqual(v?.meta?.crv_dose_amounts.slice(0,+trialParams?.maxDoseLevels),Array(+trialParams?.maxDoseLevels).fill(''))) {
     return v
   }else { 
    return undefined
  }
  }).filter((e:ToxicityCurve) => e !== undefined)
  .map((el: ToxicityCurve) => {
    return {
      ...el,
      ...getCurveParamsInRange(el.curveParams,+trialParams?.maxDoseLevels),
      smltn_txcty_id: null,
      is_carry_fwd_flg: 1,
      formulaParams:{...el.curveEquationParams},
      meta:{...el?.meta,crv_dose_amounts:el?.meta?.crv_dose_amounts.slice(0,+trialParams?.maxDoseLevels)}
    };
  });

  let nonEmpiricalCurvesProject:Array<ToxicityCurve> = projectCurves?.filter((e:ToxicityCurve)=>(+e?.meta?.maxDoseLevel >= trialParams?.maxDoseLevels))
  .filter((v:ToxicityCurve)=> {
    if(isEqual(v?.meta?.crv_dose_amounts.slice(0,+trialParams?.maxDoseLevels),trialParams?.doseAmounts)) {
      return v
    } else { 
      return undefined
    }
   }).filter((e:ToxicityCurve) => e !== undefined)
  .map((el: ToxicityCurve) => {
    return {
      ...el,
      ...getCurveParamsInRange(el.curveParams,+trialParams?.maxDoseLevels),
      smltn_txcty_id: null,
      is_carry_fwd_flg: 1,
      formulaParams:{...el.curveEquationParams},
      meta:{...el?.meta,crv_dose_amounts:el?.meta?.crv_dose_amounts.slice(0,+trialParams?.maxDoseLevels)}
    };
  });
  let empiricalCurvesSession:Array<ToxicityCurve> = sessionCurvesFiltered.filter((r:ToxicityCurve)=> r?.curveId === 1)
  .filter((v:ToxicityCurve)=> {
    if(isEqual(v?.meta?.crv_dose_amounts.slice(0,+trialParams?.maxDoseLevels),Array(+trialParams?.maxDoseLevels).fill(''))) {
      return v
    }else { 
     return undefined
   }
   }).filter((e:ToxicityCurve) => e !== undefined)
  .map((el: ToxicityCurve) => {
    return {
      ...el,
      ...el.curveParams,
      smltn_txcty_id: el.simulationToxicityCurveId,
      is_carry_fwd_flg: 0,
      formulaParams:{...el.curveEquationParams},
      projSessionId:'Current'
    };
  });
  let nonEmpiricalCurvesSession:Array<ToxicityCurve> = sessionCurvesFiltered.filter((v:ToxicityCurve)=> {
    if(isEqual(v?.meta?.crv_dose_amounts.slice(0,+trialParams?.maxDoseLevels),trialParams?.doseAmounts)) {
      return v
    }else { 
     return undefined
   }
   }).filter((e:ToxicityCurve) => e !== undefined).map((el: ToxicityCurve) => {
    return {
      ...el,
      ...el.curveParams,
      smltn_txcty_id: el.simulationToxicityCurveId,
      is_carry_fwd_flg: 0,
      formulaParams:{...el.curveEquationParams},
      projSessionId:'Current'
    };
  });
  let sessionProjCurve:Array<ToxicityCurve>   = sessionProjectCurvesFiltered.filter((e:ToxicityCurve)=>(+e?.meta?.maxDoseLevel >= trialParams?.maxDoseLevels))
  .filter((v:ToxicityCurve)=> {
    if(isEqual(v?.meta?.crv_dose_amounts.slice(0,+trialParams?.maxDoseLevels),trialParams?.doseAmounts)) {
      return v
    } else { 
      return undefined
    }
   }).filter((e:ToxicityCurve) => e !== undefined)
  .map((el: ToxicityCurve) => {
    return {
      ...el,
      ...getCurveParamsInRange(el.curveParams,+trialParams?.maxDoseLevels),
      smltn_txcty_id: el.simulationToxicityCurveId,
      is_carry_fwd_flg: 1,
      formulaParams:{...el.curveEquationParams},
      meta:{...el?.meta,crv_dose_amounts:el?.meta?.crv_dose_amounts.slice(0,+trialParams?.maxDoseLevels)}
    };
  });
  return {
    empiricalProj:[...empiricalCurvesProject],
    nonEmpProj:[...nonEmpiricalCurvesProject],
    empiricalSession:[...empiricalCurvesSession],
    nonEmpiricalSession:[...nonEmpiricalCurvesSession],
    rows:[...empiricalCurvesSession,...nonEmpiricalCurvesSession,...sessionProjCurve]
  }
}