import { call, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../../services/api';
import { ENDPOINTS } from '../../../services/endpoint';
import {
  addTenant as addTenantAction,
  addTenantSuccess,
  addTenantError,
  fetchOrganization as fetchOrganizationAction,
  fetchOrganizationError,
  fetchOrganizationSuccess,
  editTenant as editTenantAction,
  editTenantSuccess,
  editTenantError
} from '../slices/AddTenantSlice';

export function* watchAddTenant() {
  yield takeEvery(addTenantAction, addTenant);
  yield takeEvery(fetchOrganizationAction, fetchOrganization);
  yield takeEvery(editTenantAction, editTenant);

}

export function* addTenant({ payload }: any) {
  try {
    const { userid, tenantData } = payload;
    const url = `${ENDPOINTS.ADD_TENANT}?userid=${userid}`;
    const data = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(tenantData),
    });
    yield put(addTenantSuccess(data));
  } catch (error) {
    yield put(addTenantError(JSON.stringify(error?.error)));
  }
}

export function* fetchOrganization() {
  try {
    const url = ENDPOINTS.GET_ORGANIZATION;
    const data = yield call(callApi, url);
    yield put(fetchOrganizationSuccess({data}));
  } catch (error) {
    yield put(fetchOrganizationError(JSON.stringify(error?.error)));
  }
}



export function* editTenant({ payload }: any) {
  try {
    const { userId, tenantId, tenantData } = payload;
    const url = `${ENDPOINTS.EDIT_TENANT}?userid=${userId}&tenantid=${tenantId}`;
    const data = yield call(callApi, url, {
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify(tenantData),
    });
    yield put(editTenantSuccess(data));
  } catch (error) {
    yield put(editTenantError(JSON.stringify(error?.error)));
  }
}
