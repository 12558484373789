import { put, takeEvery, call, select } from 'redux-saga/effects';
import { ENDPOINTS } from '../../../services/endpoint';
import { callApi } from '../../../services/api';
import { uploadDocument,toggleLoading, getProjectDocuments,setDocuments,handleError,deleteProjectDocument,fetchDownloadURL } from '../slices/projectDocumentsSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';


type  fileResponse = {
    preSignedURL:any;
    data:any
}

export function* watchDocumentsSaga() {
    yield takeEvery(uploadDocument, uploadDocumentSaga);
    yield takeEvery(getProjectDocuments, getProjectDocumentSaga);
    yield takeEvery(deleteProjectDocument, deleteProjectDocumentSaga);
    yield takeEvery(fetchDownloadURL,getDownloadUrlSaga);
}

export function* uploadDocumentSaga(action: PayloadAction<{tenantId: string; projectId: string,userId:string, fileName:string, fileSize:any,file:File, isReplace:number }>) {
    try {
        const documents = yield select((state:RootState)=> state.documents.documents);
        const { tenantId,projectId,userId, fileName ,fileSize,file,isReplace } = action.payload;
        const url = `${ENDPOINTS.UPLOAD_PROJECT_DOCUMENT}?projectid=${projectId}&userid=${userId}&tenantid=${tenantId}&filename=${fileName}&filesize=${fileSize}&isreplace=${isReplace}`;
        const response = yield call(callApi, url , {
            method:'POST',
        });
        const data = yield makePutRequest(response.data,response.preSignedURL,file, documents)
        yield put(setDocuments(data));
        yield put(toggleLoading(false));
    } catch (error) {
        yield put(handleError(error?.error));
        yield put(toggleLoading(false));
    }
}

export const makePutRequest = async(data:any,url:any,file:any,originalDocs:any) => {
    const res  = await fetch(url, {
        method:'PUT',
        body:file,
        headers: { "Content-Type": "multipart/form-data" }}
    )
    if(res.status === 200) {
        return data;
    } else {
        return originalDocs;
    }
}
export function* getProjectDocumentSaga(action: PayloadAction<{projectId:string}>) {
    const { projectId } = action.payload;
    const url = `${ENDPOINTS.GET_PROJECT_DOCUMENTS}?projectid=${projectId}`;
    try {
        const { data } = yield call(callApi, url);
        yield put(setDocuments(data));
        yield put(toggleLoading(false));
    } catch (error) {
        yield put(setDocuments([]));
        yield put(toggleLoading(false));
    }
}

export function* deleteProjectDocumentSaga(action: PayloadAction<{userid:string,projectid:string,projectdocumentid:string}>) {
    const { userid, projectdocumentid,projectid } = action.payload;
    const url = `${ENDPOINTS.DELETE_PROJECT_DOCUMENT}?userid=${userid}&projectid=${projectid}&projectdocumentid=${projectdocumentid}`;
    try {
        const { data } = yield call(callApi, url, {
            method:'DELETE'
        });
        yield put(setDocuments(data));
        yield put(toggleLoading(false));
    } catch (error) {
        yield put(setDocuments([]));
        yield put(toggleLoading(false));
        yield put(handleError(error?.error));
    }
}

export function* getDownloadUrlSaga(action:PayloadAction<{projectid:any,tenantdocid:any}>) {
    const { projectid, tenantdocid } = action.payload;
    const url = `${ENDPOINTS.DOWNLOAD_PROJECT_DOCUMENT}?projectid=${projectid}&tenantdocid=${tenantdocid}`;
    try {
        const { preSignedURL } = yield call(callApi, url);
        window.location.href= preSignedURL;
        yield put(toggleLoading(false));
    } catch (error) {
        yield put(toggleLoading(false));
        yield put(handleError(error?.error));
    }
}