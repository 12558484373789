const localDomain: string = 'localhost';
const devDomain: string = 'dev.atd.solutions.iqvia.com';
const testDomain: string = 'test.atd.solutions.iqvia.com';
const demoDomain: string = 'demo.atd.solutions.iqvia.com';
const formalSVTCFDomain: string = 'd16wwzxkz6dosk.cloudfront.net';
const formalSVTDomain: string = 'formalsvt.atd.solutions.iqvia.com';
const formalUATDomain: string = 'uat.atd.solutions.iqvia.com';
const preprodDomain: string = 'preprod.atd.solutions.iqvia.com';
const svtDomain: string = 'svt.atd.solutions.iqvia.com';
const prodDomain: string = 'atd.solutions.iqvia.com';
const dev2Domain: string = 'dev2.atd.solutions.iqvia.com';
const informal2Domain: string = 'test2.atd.solutions.iqvia.com';
const demo2Domain: string = 'demo2.atd.solutions.iqvia.com';

const LAUNCH_URL = {
  devDomain: {
    sdaURL: 'https://dev2-fedsvc.solutions.iqvia.com:443/oauth2/authorize?response_type=code&scope=openid',
    clientID: 'd7qsIQsew1Ir2g5mRn7BAe1cD5Qa',
  },
  testDomain: {
    sdaURL: 'https://dev2-fedsvc.solutions.iqvia.com:443/oauth2/authorize?response_type=code&scope=openid',
    clientID: 'eo7NMUrf59epi0ATCiV83jrrHAUa',
  },
  demoDomain: {
    sdaURL: 'https://dev2-fedsvc.solutions.iqvia.com:443/oauth2/authorize?response_type=code&scope=openid',
    clientID: 'wZ2e4ME3hS3smD9wFFSTyqlVuRMa',
  },
  formalSVTCFDomain: {
    sdaURL: 'https://dev2-fedsvc.solutions.iqvia.com:443/oauth2/authorize?response_type=code&scope=openid',
    clientID: 'Gzf8bjOf0oZT1hQt2FkQriUfv1oa',
  },
  formalSVTDomain: {
    sdaURL: 'https://dev2-fedsvc.solutions.iqvia.com:443/oauth2/authorize?response_type=code&scope=openid',
    clientID: '0RHxdVdMVLGY72BZuOBpYCDuwsMa',
  },
  localDomain: {
    sdaURL: 'https://dev2-fedsvc.solutions.iqvia.com:443/oauth2/authorize?response_type=code&scope=openid',
    clientID: 'KFZUCKGuvfKjAz8IIrBqaVJECiYa',
  },
  formalUATDomain: {
    sdaURL: 'https://dev2-fedsvc.solutions.iqvia.com:443/oauth2/authorize?response_type=code&scope=openid',
    clientID: 'KNzFDPLX462pwde81MBF9MbQun0a',
  },
  preprodDomain: {
    sdaURL: 'https://uat-fedsvc.solutions.iqvia.com:443/oauth2/authorize?response_type=code&scope=openid',
    clientID: 'I5xponKb_HEhahlfoZz9kVFygh4a',
  },
  svtDomain: {
    sdaURL: 'https://dev2-fedsvc.solutions.iqvia.com:443/oauth2/authorize?response_type=code&scope=openid',
    clientID: 'iqe7JJgOn_QYMXNfkZADVn1KbKca',
  },
  prodDomain: {
    sdaURL: 'https://fedsvc.myaccount.iqvia.com:443/oauth2/authorize?response_type=code&scope=openid',
    clientID: 'MdcC_0I13aFX3XfMVEnvEfpHkK4a',
  },
  dev2Domain: {
    sdaURL: 'https://dev2-fedsvc.solutions.iqvia.com:443/oauth2/authorize?response_type=code&scope=openid',
    clientID: 'YoN4I66OVMW9OrgmjCCeFFcff0wa',
  },
  informal2Domain: {
    sdaURL: 'https://dev2-fedsvc.solutions.iqvia.com:443/oauth2/authorize?response_type=code&scope=openid',
    clientID: '79f70BEXRpqfXlfCMe1YMPtH7wwa',
  },
  demo2Domain: {
    sdaURL: 'https://dev2-fedsvc.solutions.iqvia.com:443/oauth2/authorize?response_type=code&scope=openid',
    clientID: 'UJeD84_Xh6wb0eN0fYjZBLyFh9oa',
  },
};

let redirectURI = `${window.location.protocol}//${window.location.hostname}/auth/route`;
let clientID: any;
let sdaURL: any;
let env: any;
let googleAnalyticsID: string;
let gtmID: string;
let accountSettingsUrl: string;

// TODO: will remove once socket url starting serving from userprofileApi
let socketUrl: any = '';
const devSocketUrl = 'wss://504llu9zv9.execute-api.us-east-1.amazonaws.com/dev';
const testSocketUrl = 'wss://lgb6hfoooh.execute-api.us-east-1.amazonaws.com/test';
const demoSocketUrl = 'wss://ckn0sh8l6l.execute-api.us-east-1.amazonaws.com/demo';
const formalSVTCFSocketUrl = 'wss://ju7dmzbicb.execute-api.us-east-1.amazonaws.com/formalsvtcf';
const formalSVTSocketUrl = 'wss:///wg4wayqu7i.execute-api.us-east-1.amazonaws.com/formalsvt';
const formalUATWebSocketUrl = 'wss://mx6gb2b0ub.execute-api.us-east-1.amazonaws.com/uat';
const preprodWebSocketUrl = 'wss://g4kvw5nl3b.execute-api.us-east-1.amazonaws.com/preprod';
const svtDomainWebScoketUrl = 'wss://0laz7ew1q8.execute-api.us-east-1.amazonaws.com/svt';
const prodWebSocketUrl = 'wss://vv9fik6eu3.execute-api.us-east-1.amazonaws.com/prod';
const dev2WebSocketUrl = 'wss://6qg1tvkjs3.execute-api.us-east-1.amazonaws.com/dev2';
const informal2SocketUrl = 'wss://p7s6a27lce.execute-api.us-east-1.amazonaws.com/test2';
const demo2SocketUrl = 'wss://zhsnkh7zm2.execute-api.us-east-1.amazonaws.com/demo2';

switch (window.location.hostname) {
  case devDomain:
    clientID = LAUNCH_URL.devDomain.clientID;
    sdaURL = LAUNCH_URL.devDomain.sdaURL;
    redirectURI = `${window.location.protocol}//${window.location.hostname}/auth/route`;
    env = 'dev';
    socketUrl = devSocketUrl;
    googleAnalyticsID = 'UA-193864608-1';
    gtmID = 'GTM-N45ZTZV';
    accountSettingsUrl = 'https://dev2-myacct.solutions.iqvia.com/iqvia-persona-endpoint/my-account.jsp';
    break;

  case testDomain:
    clientID = LAUNCH_URL.testDomain.clientID;
    sdaURL = LAUNCH_URL.testDomain.sdaURL;
    redirectURI = `${window.location.protocol}//${window.location.hostname}/auth/route`;
    env = 'test';
    socketUrl = testSocketUrl;
    googleAnalyticsID = 'UA-193864608-6';
    gtmID = 'GTM-P7R68G9';
    accountSettingsUrl = 'https://dev2-myacct.solutions.iqvia.com/iqvia-persona-endpoint/my-account.jsp';
    break;

  case demoDomain:
    clientID = LAUNCH_URL.demoDomain.clientID;
    sdaURL = LAUNCH_URL.demoDomain.sdaURL;
    redirectURI = `${window.location.protocol}//${window.location.hostname}/auth/route`;
    env = 'demo';
    socketUrl = demoSocketUrl;
    googleAnalyticsID = 'UA-193864608-4';
    gtmID = 'GTM-TDWQHGV';
    accountSettingsUrl = 'https://dev2-myacct.solutions.iqvia.com/iqvia-persona-endpoint/my-account.jsp';
    break;

  case localDomain:
    clientID = LAUNCH_URL.localDomain.clientID;
    sdaURL = LAUNCH_URL.localDomain.sdaURL;
    redirectURI = `${window.location.protocol}//${window.location.hostname}:3000/auth/route`;
    env = 'local';
    socketUrl = devSocketUrl;
    googleAnalyticsID = 'UA-193864608-1';
    gtmID = 'GTM-N45ZTZV';
    accountSettingsUrl = 'https://dev2-myacct.solutions.iqvia.com/iqvia-persona-endpoint/my-account.jsp';
    break;

  case formalSVTCFDomain:
    clientID = LAUNCH_URL.formalSVTCFDomain.clientID;
    sdaURL = LAUNCH_URL.formalSVTCFDomain.sdaURL;
    env = 'formalsvtcf';
    socketUrl = formalSVTCFSocketUrl;
    accountSettingsUrl = 'https://dev2-myacct.solutions.iqvia.com/iqvia-persona-endpoint/my-account.jsp';
    break;

  case formalSVTDomain:
    clientID = LAUNCH_URL.formalSVTDomain.clientID;
    sdaURL = LAUNCH_URL.formalSVTDomain.sdaURL;
    env = 'formalsvt';
    socketUrl = formalSVTSocketUrl;
    accountSettingsUrl = 'https://dev2-myacct.solutions.iqvia.com/iqvia-persona-endpoint/my-account.jsp';
    break;

  case formalUATDomain:
    clientID = LAUNCH_URL.formalUATDomain.clientID;
    sdaURL = LAUNCH_URL.formalUATDomain.sdaURL;
    env = 'uat';
    socketUrl = formalUATWebSocketUrl;
    accountSettingsUrl = 'https://dev2-myacct.solutions.iqvia.com/iqvia-persona-endpoint/my-account.jsp';
    break;

  case preprodDomain:
    clientID = LAUNCH_URL.preprodDomain.clientID;
    sdaURL = LAUNCH_URL.preprodDomain.sdaURL;
    env = 'preprod';
    socketUrl = preprodWebSocketUrl;
    accountSettingsUrl = 'https://uat-myacct.solutions.iqvia.com/iqvia-persona-endpoint';
    break;

  case svtDomain:
    clientID = LAUNCH_URL.svtDomain.clientID;
    sdaURL = LAUNCH_URL.svtDomain.sdaURL;
    env = 'svt';
    socketUrl = svtDomainWebScoketUrl;
    accountSettingsUrl = 'https://dev2-myacct.solutions.iqvia.com/iqvia-persona-endpoint/my-account.jsp';
    break;

  case prodDomain:
    clientID = LAUNCH_URL.prodDomain.clientID;
    sdaURL = LAUNCH_URL.prodDomain.sdaURL;
    env = 'prod';
    socketUrl = prodWebSocketUrl;
    accountSettingsUrl = 'https://myacct.myaccount.iqvia.com/iqvia-persona-endpoint';
    break;

  case dev2Domain:
    clientID = LAUNCH_URL.dev2Domain.clientID;
    sdaURL = LAUNCH_URL.dev2Domain.sdaURL;
    env = 'dev2';
    socketUrl = dev2WebSocketUrl;
    googleAnalyticsID = 'UA-193864608-2';
    gtmID = 'GTM-WX6DP64';
    accountSettingsUrl = 'https://dev2-myacct.solutions.iqvia.com/iqvia-persona-endpoint/my-account.jsp';
    break;

  case informal2Domain:
    clientID = LAUNCH_URL.informal2Domain.clientID;
    sdaURL = LAUNCH_URL.informal2Domain.sdaURL;
    env = 'test2';
    socketUrl = informal2SocketUrl;
    googleAnalyticsID = 'UA-193864608-3';
    gtmID = 'GTM-WB79S7X';
    accountSettingsUrl = 'https://dev2-myacct.solutions.iqvia.com/iqvia-persona-endpoint/my-account.jsp';
    break;

  case demo2Domain:
    clientID = LAUNCH_URL.demo2Domain.clientID;
    sdaURL = LAUNCH_URL.demo2Domain.sdaURL;
    env = 'demo2';
    socketUrl = demo2SocketUrl;
    googleAnalyticsID = 'UA-193864608-5';
    gtmID = 'GTM-MDTNNWZ';
    accountSettingsUrl = 'https://dev2-myacct.solutions.iqvia.com/iqvia-persona-endpoint/my-account.jsp';
    break;

  default: //some invalid page, exit
    break;
}

export const REDIRECT_LAUNCH_URL = `${sdaURL}&client_id=${clientID}&redirect_uri=${redirectURI}`;
export const REDIRECT_ENV = env;

export const localURL = window.location.hostname === 'localhost' ? `https://${devDomain}` : '';
// const localAPIURL =  (window.location.hostname === 'localhost') ? `http://localhost:4000/dev` : '';

const apiURLSaveAccrualData = () => {
  if (window.location.hostname === demoDomain)
    return 'https://abmepztpslw7qdovszsukagrmq0mhwrh.lambda-url.us-east-1.on.aws';
  else if (window.location.hostname === localDomain)
    return 'https://abmepztpslw7qdovszsukagrmq0mhwrh.lambda-url.us-east-1.on.aws';
  else if (window.location.hostname === devDomain)
    return 'https://tb5cqe57vfkg6hc3psx6ezsba40ihkou.lambda-url.us-east-1.on.aws';
  else if (window.location.hostname === testDomain)
    return 'https://tb5cqe57vfkg6hc3psx6ezsba40ihkou.lambda-url.us-east-1.on.aws';
  else if (window.location.hostname === formalSVTCFDomain)
    return 'https://tb5cqe57vfkg6hc3psx6ezsba40ihkou.lambda-url.us-east-1.on.aws';
  else if (window.location.hostname === formalSVTDomain)
    return 'https://tb5cqe57vfkg6hc3psx6ezsba40ihkou.lambda-url.us-east-1.on.aws';
  else if (window.location.hostname === formalUATDomain)
    return 'https://tb5cqe57vfkg6hc3psx6ezsba40ihkou.lambda-url.us-east-1.on.aws';
  else if (window.location.hostname === preprodDomain)
    return 'https://tb5cqe57vfkg6hc3psx6ezsba40ihkou.lambda-url.us-east-1.on.aws';
  else if (window.location.hostname === svtDomain)
    return 'https://uf5fr5tzc6otuhsvg5ec2rpvry0ifqwf.lambda-url.us-east-1.on.aws';
  else if (window.location.hostname === prodDomain)
    return 'https://fbus2anlay2oynqykfyt24kthm0hrmqc.lambda-url.us-east-1.on.aws';
  else if (window.location.hostname === dev2Domain)
    return 'https://tb5cqe57vfkg6hc3psx6ezsba40ihkou.lambda-url.us-east-1.on.aws';
  else if (window.location.hostname === informal2Domain)
    return 'https://tb5cqe57vfkg6hc3psx6ezsba40ihkou.lambda-url.us-east-1.on.aws';
  else if (window.location.hostname === demo2Domain)
    return 'https://tb5cqe57vfkg6hc3psx6ezsba40ihkou.lambda-url.us-east-1.on.aws';
  else return 'https://tb5cqe57vfkg6hc3psx6ezsba40ihkou.lambda-url.us-east-1.on.aws';
};
export const ENDPOINTS = {
  ADD_NEW_COMMENT: localURL + '/api/addNewComment',
  GET_SPONSOR: localURL + '/api/getProjectField',
  GET_PROJECT_FIELDS: localURL + '/api/getProjectField',
  GET_PROTOCOLS: localURL + '/api/getProjectField',
  GET_THERAPEUTIC_AREAS: localURL + '/api/getProjectField',
  GET_OTHER_THERAPEUTIC_AREAS: localURL + '/api/getProjectField',
  GET_OBJECTIVES: localURL + '/api/getProjectField',
  GET_PHASES: localURL + '/api/getProjectField',
  CREATE_PROJECT: localURL + '/api/createProject',
  UPDATE_PROJECT: localURL + '/api/updateProject',
  GET_PROJECT_DETAIL: localURL + '/api/getProject',
  PROJECT_LIST: localURL + '/api/projects',
  DESIGN_OPTIONS: localURL + '/api/dose',
  USER_PROFILE: localURL + '/api/userProfile',
  COMMON_TRIAL_PARAMS: localURL + '/api/commonTrialParameters',
  GET_ALL_USERS: localURL + '/api/getAllUsers',
  GET_ALL_PROJECT_USERS: localURL + '/api/getAllProjectUsers',
  ADD_PROJECT_USER: localURL + '/api/addProjectUser',
  DELETE_PROJECT_USER: localURL + '/api/deleteProjectUser',
  DESIGN_PARAMS: localURL + '/api/designParameters',
  GET_CURVES_LIST: localURL + '/api/getCurvesList',
  GET_PROJECT_LEVEL_CURVES: localURL + '/api/getProjectLevelCurves',
  GET_ALL_OPERATIONAL_DESIGN_PARAMS: localURL + '/api/getAllOperationalDesignParameters',
  SAVE_LAUNCH_SIMULATION_PARAMETERS: localURL + '/api/saveLaunchSimulationParameters',
  GET_ALL_PROJECT_COMMENTS: localURL + '/api/getAllProjectComments',
  GET_PROJECT_RESULTS: localURL + '/api/getProjectResults',
  GET_PROJECT_RESULTS_NEW: localURL + '/api/getProjectResultSession',
  GET_OPTION_DATA: localURL + '/api/getOptionsData',
  GET_SIMULATION_RESULTS: localURL + '/api/getSimulationResults',
  POST_SESSION_OUTPUT_RATING: localURL + '/api/postSessionOutputRating',
  USER_MANAGEMENT_INFO: localURL + '/api/getAllUsersManagementInfo',
  TENANT_MANAGEMENT_INFO: localURL + '/api/getAllAdminTenantsInfo',
  INTEGRATION_MANAGEMENT_INFO: localURL + '/api/getAllAppsInfo',
  PROJECT_SESSION_DATA: localURL + '/api/getSessionData',
  ADD_USER_TO_TENANT: localURL + '/api/addUserToTenant',
  ADD_APPLICATION: localURL + '/api/createAppIntegration',
  UPDATE_APPLICATION: localURL + '/api/updateAppIntegration',
  UPDATE_APPLICATION_STATUS: localURL + '/api/updateAppStatus',
  REGENERATE_APPLICATION_TOKEN: localURL + '/api/regenerateAppToken',
  GET_PROJECT_MEMBERS: localURL + '/api/getProjectMembers',
  UPDATE_PROJECT_OWNER: localURL + '/api/updateProjectOwner',
  AUTHENTICATE_USER: localURL + '/api/authenticate',
  EDIT_USER_ROLE: localURL + '/api/editUserRole',
  GET_ALL_TENANT_DESIGNERS: localURL + '/api/getProjectMembers',
  GET_ALL_WORKSPACES: localURL + '/api/getAllTenants',
  EDIT_USER_STATUS: localURL + '/api/editUserStatus',
  GET_ALL_DEPENDENCY_LIBS: localURL + '/api/getAllLibraries',
  GET_ALL_CUSTOM_DESIGN_PARAMS: localURL + '/api/getCustomDesignParams',
  SAVE_NEW_CUSTOM_DESIGN_SIMULATION: localURL + '/api/saveNewCustomDesignSimulation',
  SAVE_EXISTING_CUSTOM_DESIGN_SIMULATION: localURL + '/api/saveExistingCustomDesignSimulation',
  GET_ALL_CODE_SNIPPETS: localURL + '/api/getAllCodeSnippets',
  GET_ALL_DESIGN_PARAMS_DEFAULT_FIELDS: localURL + '/api/getAllDesignParams',
  GET_UTILITY_FUNCTION_GRAPHS: localURL + '/api/makePlots',
  GET_CUSTOM_SESSION_DATA: localURL + '/api/getCustomSessionData',
  GET_CUSTOM_SESSION_RESULT_DATA: localURL + '/api/getSimulationResults',
  GET_CUSTOM_GRAPH_DATA: localURL + '/api/getCustomGraphData',
  POST_CUSTOM_GRAPH_DATA: localURL + '/api/postCustomSessionGraphData',
  REMOVE_CUSTOM_GRAPH_DATA: localURL + '/api/removeCustomGraphData',
  UPDATE_CUSTOM_GRAPH_DATA: localURL + '/api/updateCustomGraphData',
  GET_ORGANIZATION: localURL + '/api/getAllOrgs',
  ADD_TENANT: localURL + '/api/addTenant',
  EDIT_TENANT: localURL + '/api/editTenant',
  GET_ALL_ADMIN_TENANTS_INFO: localURL + '/api/getAllAdminTenantsInfo',
  UPDATE_TENANT_STATUS: localURL + '/api/updateTenantStatus',
  GET_AUTHENTICATION_TOKEN: localURL + '/api/getTokenFromAuthCode',
  UPLOAD_PROJECT_DOCUMENT: localURL + '/api/uploadProjectDocument',
  GET_PROJECT_DOCUMENTS: localURL + '/api/getProjectDocuments',
  DELETE_PROJECT_DOCUMENT: localURL + '/api/deleteProjectDocument',
  DOWNLOAD_PROJECT_DOCUMENT: localURL + '/api/downloadProjectDocument',
  GET_CUSTOM_OUTPUT_COMPARISION_RESULTS: localURL + '/api/getOutputComparisionResults',
  GET_TEMPLATE: localURL + '/api/getTemplateUrl',
  GET_FLOW_CHART_DECISION_TABLE_RESULTS: localURL + '/api/getFlowchartAndDecisionTableData',
  SET_DEFAULT_TENANT: localURL + '/api/setDefaultTenant',
  SOCKET_URL: socketUrl,
  SAVE_GROUP_SEQUENTIAL_PARAMETERS: localURL + '/api/saveGSDesignSimulation',
  INVOKE_R_SIMULATION_LAMBDA: localURL + '/api/lambdaRExecution',
  ADJUST_GS_INPUTS: localURL + '/api/adjustGSDesignSimulationInput',
  UPDATE_GROUP_SEQUENTIAL_PARAMETERS: localURL + '/api/updateGSDesignSimulation',
  TERMINATE_SIMULATION: localURL + '/api/terminateSimulation',
  AUDIT_EXPORT_DOCUMENT: localURL + '/api/auditDocumentExport',
  DELETE_PROJECT_SESSION: localURL + '/api/deleteSession',
  PUBLISH_PROJECT_SESSION: localURL + '/api/publishSession',
  SUBMIT_FEEDBACK: localURL + '/api/submitFeedback',
  SAVE_ACCRUAL_DATA: apiURLSaveAccrualData() + '/api/saveAccrualData',
  GET_SAVED_ACCRUL_DATA: localURL + '/api/getAccrualData',
  DELETE_ACCRUAL_VERSION: localURL + '/api/deleteAccrualVersion',
  GET_TRAINING_VIDEOS: localURL + '/api/getTrainingDocuments',
  GET_SHARED_SESSION: localURL + '/api/shareSession',
  SAVE_TEMPLATE: localURL + '/api/upsertMyTemplate',
};

export const getEnv = () => env;
export const getGtmID = () => gtmID;
export const getGoogleAnaylictsID = () => googleAnalyticsID;
export const getAccountSettingsUrl = () => accountSettingsUrl;
