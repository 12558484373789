import { put, takeEvery, call } from "redux-saga/effects";
import {
  fetchSuccess,
  fetchFailure,
  fetchRequest,
  updateRequestFailure,
  updateRequestSuccess,
  updateTenantStatusRequest,
} from "../slices/TenantManagementTableSlice";
import { ENDPOINTS } from "../../../services/endpoint";
import { callApi } from "../../../services/api";
import { PayloadAction } from "@reduxjs/toolkit"; 
import { ACTION_USER_ACTIVATION, isUserActive } from '../utils/tenantManagmentTableCommon';

export default function* watchGetTenantManagementList() {
  yield takeEvery(fetchRequest, getTenantManagementList);
  yield takeEvery(updateTenantStatusRequest, updateTenantStatus);

}

export function* getTenantManagementList(
  action: PayloadAction<{
    page: string;
    limit: number;
    userid: string;
    sortedColumn: string;
    sortOrder: string;
    userType: string;
  }>
) {
  try {
    const {
      page,
      limit,
      userid,
      sortedColumn,
      sortOrder,
      userType,
    } = action.payload;
    const url = `${ENDPOINTS.TENANT_MANAGEMENT_INFO}?userid=${userid}&page=${page}&limit=${limit}&sortedcolumn=${sortedColumn}&sortorder=${sortOrder}&usertype=${userType}`;

    const result = yield call(callApi, url);

    yield put(fetchSuccess(result));
  } catch (error) {
    console.warn(error?.error);
    yield put(fetchFailure(error?.error));
  }
}

export function* updateTenantStatus(
  action: PayloadAction<{
    userId: string | number;
    tenantId: string | number;
    status: string;
  }>
) {
  try {
    const {
      status,
      userId,
      tenantId
    } = action.payload;
    //const { tenantid, userid } = yield select(getActiveuser);
    const url = `${ENDPOINTS.UPDATE_TENANT_STATUS}?userid=${userId}&tenantid=${tenantId}`;
    const body = {
      activate: isUserActive(status) ? 0 : 1,
    };
    const data = yield call(callApi, url, {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify(body),
    });

    if (data) {
      yield put(updateRequestSuccess({ requestType: ACTION_USER_ACTIVATION }));
    }
  } catch (error) {
    console.warn(error?.error);
    yield put(updateRequestFailure({ ...error, requestType: ACTION_USER_ACTIVATION }));
  }
}
