import { call, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../../services/api';
import { ENDPOINTS } from '../../../services/endpoint';
import {
    defaultTenantSelect as defaultTenantSelectAction,
    defaultTenantSelectSuccess,
    defaultTenantSelectError
} from '../slices/DefaultTenantSlice';

export default function* watchDefaultTenantSelect() {
  yield takeEvery(defaultTenantSelectAction, defaultTenantSelect);
}

export function* defaultTenantSelect({ payload }: any) {
    try {
  
      const { userId, defaultTenantId } = payload;
      const url = `${ENDPOINTS.SET_DEFAULT_TENANT}?userid=${userId}`;
  
      const data  = yield call(callApi, url, {
        method: 'PUT',
        mode: 'cors',
        body: JSON.stringify({tenantid:defaultTenantId}),
      });
      if (data) {
        yield put(defaultTenantSelectSuccess(data));
      }
    } catch (error) {
      yield put(defaultTenantSelectError(JSON.stringify(error?.error)));
    }
  }