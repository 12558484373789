import React from 'react';
import Footer from 'apollo-react/components/Footer';

export default () => {
  return (
    <Footer
      className="footerContainer"
      buttonProps={[
        {
          label: 'Terms of Use',
          href: 'https://www.iqvia.com/about-us/terms-of-use',
          target: '_blank',
        },
        {
          label: 'Privacy Policy',
          href: 'https://www.iqvia.com/about-us/privacy/privacy-policy',
          target: '_blank',
        },
      ]}
      maxWidth={"100%"}
    />
  );
};
