import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export const initialState: any = {
  isLoading: false,
  error: '',
  data: [],
  stages: [],
  isTestRun: false,
  isProjectOwner: false,
  fullSimulationCode: '',
  sessionName: '',
  sessionId: '',
};

export const resultSlice = createSlice({
  name: 'results',
  initialState: initialState as any,
  reducers: {
    fetchRequest: (state: any, action) => {
      state.isLoading = true;
    },
    fetchSuccess: (state: any, action: PayloadAction<any>) => {
      const {results, stages, fullSimulationCode} = action.payload;
      const {data, isTestRun, isProjOwner, sessionName} = results;
      state.data = data;
      state.isTestRun = isTestRun;
      state.isProjectOwner = isProjOwner;
      state.sessionName = sessionName;
      state.sessionId = data.length ? data[0].projSessionId : '';
      state.fullSimulationCode = fullSimulationCode;
      state.stages = stages || [];
      state.isLoading = false;
    },
    fetchFailure: (state: any, action) => {
      state.isLoading = false;
      state.error = action?.payload;
    },
    addGraphSrcRequest: (state, action) => {
      const { resultId, caseNumber} = action.payload;
      state.data = state.data.map((result: any) => {
        if (result.resultId === resultId && result.caseNumber === caseNumber) {
          result.isGraphLoading = true;
        }
        return result;
      })
    },
    addGraphSrcFailure: (state, action) => {
      const { resultId, caseNumber} = action.payload;
      state.data = state.data.map((result: any) => {
        if (result.resultId === resultId && result.caseNumber === caseNumber) {
          result.isGraphLoading = false;
        }
        return result;
      })
    },
    addGraphSrcSuccess: (state, action) => {
      const { resultId, caseNumber, chartPreview, graphName} = action.payload;
      state.data = state.data.map((result: any) => {
        if (result.resultId === resultId && result.caseNumber === caseNumber) {
          result.graphsData.map((data: any) => {
            if (data.name === graphName) {
              data.graphPreviewSrc = chartPreview;
            }
            return data;
          })
          result.isGraphLoading = false;
        }
        return result;
      });
    },
    userRatingRequest: (state: any, action: any) => {
      state.isLoading = true;
    },
    userRatingSuccess: (state: any, action: any) => {
      state.isLoading = false;
      const {
        resultid,
        data: {avgRating},
        ratingvalue,
      } = action.payload;
      const index = state.data.findIndex((el: any) => el.resultId === resultid);
      if (index > -1) {
        state.data[index].avgRating = avgRating ? avgRating : state.data[index].avgRating;
        state.data[index].userRating = ratingvalue ? ratingvalue : state.data[index].userRating;
      }
    },
    userRatingError: (state: any, action: any) => {
      state.isLoading = false;
      state.error = JSON.stringify(action?.payload);
    },
    resetResults: (state: any) => {
      state.data = [];
      state.isTestRun = false;
      state.isProjectOwner = false;
      state.sessionName = '';
      state.sessionId = '';
      state.fullSimulationCode = '';
      state.stages = [];
    },
    switchColumns: (
      state,
      action: PayloadAction<{ index: number; arrowPos: string }>
    ) => {
      const { index, arrowPos } = action.payload;

      if (arrowPos === 'right') {
        [state.data[index], state.data[index + 1]] = [
          state.data[index + 1],
          state.data[index],
        ];
      }
      if (arrowPos === 'left') {
        [state.data[index], state.data[index - 1]] = [
          state.data[index - 1],
          state.data[index],
        ];
      }
    },
    fetchCompareRequest: (state: any, action) => {
      state.isLoading = true;
    },
    fetchCompareSuccess: (state: any, action) => {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
    },
    fetchCompareFailure: (state: any, action) => {
      state.isLoading = false;
      state.error = action?.payload;
    },
  },
});

export const {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  userRatingRequest,
  userRatingSuccess,
  userRatingError,
  resetResults,
  addGraphSrcRequest,
  addGraphSrcSuccess,
  addGraphSrcFailure,
  switchColumns,
  fetchCompareRequest,
  fetchCompareSuccess,
  fetchCompareFailure,
} = resultSlice.actions;
export default resultSlice.reducer;
