import {put, takeEvery, call, select} from 'redux-saga/effects';
import {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  userRatingRequest,
  userRatingSuccess,
  userRatingError,
  addGraphSrcRequest,
  addGraphSrcSuccess,
  addGraphSrcFailure,
  fetchCompareRequest,
  fetchCompareSuccess,
  fetchCompareFailure,
} from '../../slices/customDesignSlice/ResultsSlice';

import {ENDPOINTS} from '../../../../services/endpoint';
import {callApi} from '../../../../services/api';
import {getActiveuser} from '../../selectors/userDetailsSelector';
import HtmlToImage from 'html-to-image';

export default function* watchCustomDesignResultsSagas() {
  yield takeEvery(fetchRequest, getResultsData);
  yield takeEvery(userRatingRequest, userRatingDesignSaga);
  yield takeEvery(addGraphSrcRequest, addGraphSrc);
  yield takeEvery(fetchCompareRequest, getCompareResults);
}

export function* addGraphSrc({payload}: any) {
  try {
    const {chart} = payload;
    const resp = yield HtmlToImage.toPng(chart);
    yield put(addGraphSrcSuccess({...payload, chartPreview: resp}));
  } catch (error) {
    yield put(addGraphSrcFailure({resultId: payload.resultId, caseNumber: payload.caseNumber}));
  }
}

export function* getResultsData({payload}: any): any {
  try {
    const {tenantid, userid} = yield select(getActiveuser);
    const {sid, projectId} = payload;
    let stages = [];
    let fullSimulationCode: string = '';
    const resultAPIurl = `${ENDPOINTS.GET_CUSTOM_SESSION_RESULT_DATA}?sessionid=${sid}&tenantid=${tenantid}&userid=${userid}&projectid=${projectId}`;
    const stageAPIurl = `${ENDPOINTS.GET_CUSTOM_SESSION_DATA}?sessionid=${sid}&tenantid=${tenantid}&userid=${userid}&projectid=${projectId}`;
    const results = yield call(callApi, resultAPIurl);
    if (results.isProjOwner) {
      const stagesResult = yield call(callApi, stageAPIurl);
      stages = stagesResult?.data?.stages?.data || [];
      fullSimulationCode = stagesResult?.fullSimulationCode || '';
    }
    const modifedResult = yield transformResults(results);
    yield put(fetchSuccess({results: modifedResult, stages, fullSimulationCode}));
  } catch (error) {
    console.warn(error?.error);
    yield put(fetchFailure(error?.error));
  }
}

export function* userRatingDesignSaga({payload}: any) {
  try {
    const {tenantid, userid} = yield select(getActiveuser);
    const {resultid, sessionid, projectid, ratingvalue} = payload;
    const url = `${ENDPOINTS.POST_SESSION_OUTPUT_RATING}?userid=${userid}&tenantid=${tenantid}`;

    const body = {
      resultid,
      sessionid,
      projectid,
      ratingvalue,
    };
    const data = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
    });
    const response = {...data, ...payload};
    if (response) {
      yield put(userRatingSuccess(response));
    }
  } catch (error) {
    yield put(userRatingError(error?.error));
  }
}

export function* getCompareResults({payload}: any) {
  try {
    const {tenantid, userid} = yield select(getActiveuser);
    const {resultids, projectId} = payload;
    const url = `${ENDPOINTS.GET_CUSTOM_OUTPUT_COMPARISION_RESULTS}?resultids=${resultids}&userid=${userid}&tenantid=${tenantid}&projectid=${projectId}`;
    const data = yield call(callApi, url);
    if (data) {
      const modifedResult = yield transformResults(data);
      yield put(fetchCompareSuccess(modifedResult));
    }
  } catch (error) {
    yield put(fetchCompareFailure(error?.error));
  }
}

export function* transformResults(payload: any): any {
  if (payload?.data?.length > 0) {
    const data = yield payload.data.map((result: any) => {
      const {outputTable = {}, ...rest} = result;
      if (Object.keys(outputTable).length) {
        const newOutputTable: any = transformOutput(outputTable);
        return {
          ...rest,
          outputTable: newOutputTable,
        };
      }
      return {
        ...rest,
      };
    });
    return {...payload, data};
  } else {
    return payload;
  }
}

const transformOutput = (payload: any) => {
  const results = Object.keys(payload).reduce((newFormat: any, key: any) => {
    const {key: nKey, ...props} = mapper(key, payload[key]);
    newFormat[nKey] = {...props};
    return newFormat;
  }, {});
  return results;
};

const getColumns = (payload: any): Array<string> => {
  const columns = Object.keys(payload[0]);
  return columns;
};

const mapper = (key: any, value: any) => {
  let column;
  column = getColumns(value);
  return {key: key, column, row: value};
};
