import React, { forwardRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import Button from 'apollo-react/components/Button';
import Box from '@material-ui/core/Box';
import Typography from 'apollo-react/components/Typography';
import { CONSTANTS } from '../../constants';
import CheckIcon from '@material-ui/icons/Check';
import './style.scss';
import { autoCompleteProps } from './type';
import { getDuplicateValueExists } from '../../../features/projects/selectors/CreateProjectSelectors';
import { useSelector } from 'react-redux';
const filter = createFilterOptions();
const CustomAutoComplete = forwardRef<HTMLInputElement, autoCompleteProps>(
  (
    {
      label,
      options,
      labelKey,
      onChange,
      onInputChange,
      helperText,
      error,
      multiple=true,
      ...rest
    }: autoCompleteProps,
    ref
  ) => {
    const [value, setValue] = React.useState<any>([]);
    const duplicateValue = useSelector(getDuplicateValueExists);
    const addOption = (option: any) => {
      return (
        <div className='autocompleteNewOption'  data-testid="autocompleteNewOption">
          <Box display='flex'>
            <Box flexGrow='1'>
              <Typography variant='body1'>
                <strong>{option[labelKey]}</strong>
              </Typography>
            </Box>
            {(label !== CONSTANTS.LABELS.THERAPEUTIC_AREAS)? (
              !error ?
                <Box>
                  <Button
                    variant='secondary'
                    disabled={duplicateValue}
                    size='small'
                    className='addBtn'
                    data-testid="autocompleteNewOptionButton"
                  >
                    {CONSTANTS.LABELS.ADD}
                  </Button>
              </Box> : null
            ) : null}
          </Box>
          <br />
          <Typography variant='body2' className='readOnly'>
            {`No results found for "${option[labelKey]}"`}
          </Typography>
        </div>
      );
    };

    const listItem = (option: any, selected: boolean) => {
      return (
        <div className='autocompleteItem'>
          <Box display='flex'>
            {selected && (
              <Box>
                <CheckIcon fontSize='small' color='primary' />
              </Box>
            )}
            <Box flexGrow='1'>
              <Typography variant='body1' className='listItemLabel'>
                {option[labelKey]}
              </Typography>
            </Box>
          </Box>
        </div>
      );
    };

    const getLabel = () => {
      return (
        <label>
          {label}
          {rest.required && <span className='asterik'>*</span>}
        </label>
      );
    };

    const getHelperText = (msg:any) => {
      return (<div className={label === 'Organization Name'? msg ===  CONSTANTS.VALIDATION_MESSAGES.TOO_LONG?  'orgValidation' : 'validation' : 'validation'}>{!Array.isArray(msg) ? msg : msg[0]?.name}</div>)
    }
    
    return (
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          onChange && onChange(newValue);
          /* if (typeof newValue === 'string') {
                  setValue({
                    label: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setValue({
                    label: newValue.inputValue,
                  });
                } else {
                  setValue(newValue);
                } */
        }}
        filterOptions={(options, params: any) => {
          const filtered: any = filter(options, params);

          // Suggest the creation of a new value
          if (filtered.length === 0 && params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              [labelKey]: params.inputValue,
              isNew: true,
            });
          }

          return filtered;
        }}
        renderTags={(items: any) => {
          return (
            <div className='autocompleteTags' data-testid="autocompleteTags">
              {items.map((item: any, index: number) => {
                return <span key={index}>{item[labelKey]}</span>;
              })}
            </div>
          );
        }}
        selectOnFocus
        clearOnBlur
        openOnFocus
        classes={{
          endAdornment: 'actionSection',
          paper: 'autoCompletePaper',
          popper: 'autoCompletePoper',
          inputRoot: 'autoCompleteInputRoot',
          input: 'autoCompleteInput',
        }}
        disableClearable
        options={options}
        getOptionLabel={(option: any) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option[labelKey] || '';
        }}
        getOptionDisabled={(option) => option.isNew}
        getOptionSelected={(option, value) => {
          if (value === "") {
            return true;
          }
          if(Array.isArray(value)){
            return option.name === value[0]?.name
          }
          return option.name === value?.name
        }}
        renderOption={(option, { selected }) => {
          if (option.inputValue) {
            return addOption(option);
          }
          return listItem(option, selected);
        }}
        fullWidth
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              label={getLabel()}
              placeholder={CONSTANTS.LABELS.START_TYPING}
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={ref}
              helperText={getHelperText(helperText)}
            />
          </>
        )}
        multiple={multiple}
        onInputChange={(event, newInputValue) => {
          onInputChange && onInputChange(newInputValue);
        }}
        {...rest}
        data-testid={`customAutoComplete${label.replace(/ /g, '').toLowerCase()}`}
      />
    );
  }
);

export default CustomAutoComplete;
