import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export const initialState: any = {
  isLoading: false,
  error: '',
  data: {
    stages: {},
    sessionDetail: {},
    caseConfiguration: {},
  },
  openModal: false,
  projSessionId: null,
  sessionId: null,
  sessionLabel: '',
  sessionName: '',
  getSessionLoading: false,
  isNewSession:false,
  sessionStatus:'draft'
};

export const saveCustomSessionSlice = createSlice({
  name: 'saveCustomSession',
  initialState: initialState as any,
  reducers: {
    fetchRequest: (state: any, action) => {
      state.isLoading = true;
    },
    fetchSuccess: (state: any, action: PayloadAction<any>) => {
      state.data = action?.payload?.jsonFile?.data || {};
      state.projSessionId = action.payload?.projSessionId;
      state.sessionId = action.payload?.sessionId;
      state.sessionLabel = action.payload?.sessionLabel;
      state.sessionName = `Session ${action.payload?.projSessionId ?? ''}`;
      state.sessionStatus = action?.payload?.sessionStatus;
      state.isNewSession = action.payload?.isNewSession;
      state.isLoading = false;
      state.openModal = action.payload?.status ==='Completed' ? false: true;
    },
    fetchFailure: (state: any, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.openModal = true;
    },
    resetSessionData: (state: any) => {
      state.data = {
        stages: {},
        sessionDetail: {},
        caseConfiguration: {},
      };
      state.error = '';
      state.projSessionId = '';
      state.sessionId = '';
      state.sessionLabel = '';
      state.sessionName = '';
      state.sessionStatus = 'draft';
      state.isLoading = false;
      state.openModal = false;
      state.isNewSession = false;
    },
    closeModal: (state: any) => {
      state.openModal = false;
      state.error = '';
    },
    fetchRequest_getSession: (state: any, action: any) => {
      state.getSessionLoading = true;
    },
    fetchSuccess_getSession: (state: any, action: any) => {
      state.data = action?.payload?.data?.data || {};
      state.projSessionId = action.payload?.data?.projSessionId;
      state.sessionId = action.payload?.isClonedSession ? '' : action.payload?.data?.sessionId;
      state.sessionLabel = action.payload?.data?.sessionLabel;
      state.sessionName = `Session ${action.payload?.data?.projSessionId ?? ''}`;
      state.isNewSession = action.payload?.data?.isNewSession;
      state.getSessionLoading = false;
      state.openModal = false;
      state.sessionStatus = action?.payload?.isClonedSession ? 'draft' : action.payload?.data?.sessionStatus;
    },
    fetchFailure_getSession: (state: any, action: any) => {
      state.getSessionLoading = false;
      state.error = String(action.payload);
      state.openModal = true;
    },
    updateSavedSessionCasesData: (state: any, action: any) => {
      state.data.caseConfiguration.data =  action.payload?.data;
    }
  },
});

export const {
  fetchRequest,
  fetchFailure,
  fetchSuccess,
  resetSessionData,
  closeModal,
  fetchRequest_getSession,
  fetchSuccess_getSession,
  fetchFailure_getSession,
  updateSavedSessionCasesData
} = saveCustomSessionSlice.actions;
export default saveCustomSessionSlice.reducer;
