import {call, put, takeLatest} from 'redux-saga/effects';
import {callApi} from '../../../services/api';
import {ENDPOINTS} from '../../../services/endpoint';
import {
  submitFeedback as submitFeedbackAction,
  submitFeedbackSuccess,
  submitFeedbackError,
} from '../slices/SubmitFeedbackSlice';

export function* watchSubmitFeedback() {
  yield takeLatest(submitFeedbackAction, submitFeedback);
}

function* submitFeedback({payload}: any) {
  try {
    const {subject, message, attachment, emailMeCopy, tenantid} = payload;

    const url = `${ENDPOINTS.SUBMIT_FEEDBACK}?tenantid=${tenantid}`;

    // FormData is being used here since we are sending the binary file content and not only the json data
    const body = new FormData();
    body.append('subject', subject);
    body.append('message', message);
    attachment && body.append('files', attachment);
    body.append('emailMeCopy', emailMeCopy);

    const data = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body,
    });
    if (data) {
      yield put(submitFeedbackSuccess(data?.data?.id));
    }
  } catch (error) {
    yield put(submitFeedbackError(JSON.stringify(error?.error || error?.message)));
  }
}
