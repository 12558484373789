import React from 'react';
import moment from "moment";
import Settings from '@material-ui/icons/Settings';

export const getRowsPerPageOptions = (size: number) => {
  const TotalLength = Math.round(size / 10) * 10;
  const rowsPerPage = [];
  for (let i = 1; i <= TotalLength / 10; i++) {
    if (i <= 3) {
      rowsPerPage.push(i * 10);
    } else {
      break;
    }
  }
  return rowsPerPage;
};

export const CreatedAtCell = ({ row }: any) => formatDate(row.createdat);

export const AddedOnCell = ({ row }: any) => formatDate(row.addedOn);

export const formatDate = (dateStr: any) => {
  return (
    <span>{moment(dateStr).format('DD-MMM-YYYY').toUpperCase()}</span>
  );
};

export const IconMenuHeader = () => {
  return (
    <Settings fontSize='small' style={{ marginTop: 6, marginLeft: 10 }} />
  );
};

export const DateCell = ({ row }: any) => {
  return <span>{moment(row.addedon).format("DD-MMM-YYYY").toUpperCase()}</span>;
};

export const CommonDateCell = ({ row, column }: any) => {
  const dateValue = row[column.accessor];
  return dateValue ? formatDate(dateValue) : '';
};
