import { put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import {
  fetchSuccess,
  fetchFailure,
  fetchRequest,
  fetchPostRequest,
  fetchPostSuccess,
  fetchPostError,
  fetchAllSuggestions,
  fetchAllSuggestionsSuccess,
  fetchAllSuggestionsError,
  loadMoreComments,
  loadMoreCommentsFailure,
} from '../slices/projectCommentSlice';
import { ENDPOINTS } from '../../../services/endpoint';
import { callApi } from '../../../services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { getActiveuser } from '../selectors/userDetailsSelector';
import { select } from 'redux-saga/effects';

export default function* watchGetProjectComments() {
  yield takeEvery([fetchRequest, loadMoreComments], getProjectComments);
  yield takeEvery(fetchPostRequest, postProjectComment);
  yield takeLatest(fetchAllSuggestions, fetchAllUsers);
}

type GET_COMMENTS_API_PAYLOAD = {
  projid: string;
  page: string;
  limit: number;
}

export function* getProjectComments(action: PayloadAction<GET_COMMENTS_API_PAYLOAD>) {
  try {
    const { page, limit = 5, projid } = action.payload;
    const url = `${ENDPOINTS.GET_ALL_PROJECT_COMMENTS}?page=${page}&limit=${limit}&projid=${projid}`;
    const result = yield call(callApi, url);
    yield put(fetchSuccess({ ...result, type: action.type }));
  } catch (error) {
    console.warn(error);
    if(action.type.includes('loadMoreComments')){
      yield put(loadMoreCommentsFailure(error?.error));
    } else {
      yield put(fetchFailure(error?.error));
    }
  }
}

export function* postProjectComment({ payload }: any) {
  try {
    const { tenantid, userid } = yield select(getActiveuser);

    const { projid, comment } = payload;
    const url = `${ENDPOINTS.ADD_NEW_COMMENT}?tenantid=${tenantid}&projid=${projid}`;
    const body = {
      userid,
      comment,
    };
    const data = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
    });

    if (data) {
      yield put(fetchPostSuccess(data));
    } else {
      yield put(fetchPostSuccess({} as any));
    }
  } catch (error) {
    yield put(fetchPostError(error?.error));
  }
}

export function* fetchAllUsers(action: PayloadAction<{
  projid: string;
}>) {
  try {
    const { projid } = action.payload;
    const { tenantid } = yield select(getActiveuser);
    const url = `${ENDPOINTS.GET_ALL_PROJECT_USERS}?tenantId=${tenantid}&projectId=${projid}&members=all`;
    const data = yield call(callApi, url);
    if (data) {
      yield put(fetchAllSuggestionsSuccess(data));
    } else {
      yield put(fetchAllSuggestionsSuccess({} as any));
    }
  } catch (error) {
    yield put(fetchAllSuggestionsError(error?.error));
  }
}