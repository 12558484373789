import {put, takeEvery, call, select, delay, takeLatest} from 'redux-saga/effects';
import {
  fetchRequest,
  fetchSuccess,
  fetchError,
  userRatingRequest,
  userRatingSuccess,
  userRatingError,
  fetchCompareRequest,
  toogleLayout,
  multiLineView,
  cancelSimulationRequest,
  cancelSimulationStatus,
} from '../slices/SessionResultSlice';
import {setReloadProjectResults} from '../slices/projectDetailSlice';
import {ENDPOINTS} from '../../../services/endpoint';
import {callApi} from '../../../services/api';
import {PayloadAction} from '@reduxjs/toolkit';
import {getActiveuser} from '../selectors/userDetailsSelector';
import {openBanner} from '../slices/BannerSlice';
import Sockette from 'sockette';
import {getSessionStorage} from '../../../common/utils/useSessionStorage';
import {CONSTANTS} from '../../../common/constants/index';
import {forwardTo} from './projectDetail';
import {queryTrailParams} from '../components/Objectives/helper/DEMultiLineHelper';
import {uniqBy, sortBy, filter, isEmpty, map, omit, pick, has, cloneDeep} from 'lodash';
import {parseToPrecision, parseToSignificantFigure} from '../../../common/utils';
import nanoid from 'nanoid';
import parser from '../utils/parser';

type UserRatingType = {
  resultid: number;
  sessionid: number;
  ratingvalue: number;
  projectid: number;
  designSessionId: number;
  tenantid: string;
  userid: string;
};

export function* watchSessionResult() {
  yield takeEvery(fetchRequest, sessionResultSaga);
  yield takeEvery(userRatingRequest, userRatingDesignSaga);
  yield takeEvery(fetchCompareRequest, getCompareResults);
  yield takeLatest(toogleLayout, switchLayout);
  yield takeEvery(cancelSimulationRequest, cancelSimulationSaga)
}

export function* sessionResultSaga({payload}: any) {
  const {
    sid,
    projectId,
    projectSessionId,
    label,
    webSocketInitiated = false,
    socketIdentifier,
  } = payload;
  try {
    
    //sessionid=126&page=1&limit=2
    const {userid, tenantid} = yield select(getActiveuser);
    let newSocketIdentifier = socketIdentifier ? socketIdentifier : nanoid();
    newSocketIdentifier = newSocketIdentifier?.replace(/-/gi, '');
    const totalCount = getSessionStorage('totalOptionsCount');
    let pageLimit=1;
    let simulationData:any=[];
    let totalRecords;
    let isTestRun;
    if(totalCount){
      pageLimit=Math.ceil(totalCount/6)
    }
    console.log('webSocketInitiated', webSocketInitiated)
    yield delay(10000);
   // if(!webSocketInitiated){
    let url = `${ENDPOINTS.GET_SIMULATION_RESULTS}?sessionid=${sid}&userid=${userid}&tenantid=${tenantid}&projectid=${projectId}&socketIdentifier=${newSocketIdentifier}`;
      for(let i=1; i<=pageLimit; i++){
        const paginatedUrl = `${url}&page=${i}&limit=6`;
        let limitData = yield call(callApi, paginatedUrl);
        let {data, totalRecords:records, isTestRun:isTest} = limitData;
        simulationData=[...simulationData, ...data];
        totalRecords=records;
        isTestRun=isTest;
      }
  //  }
    if (simulationData && simulationData.length) {
      // exclude failed results
      simulationData = exculdeFailedResults(simulationData);
      const newData = simulationData;
      const processedDatForMultiLineView: any = yield call(processMultiLineData,newData);
      const parseData = simulationData.length ? getParseData(simulationData, false) : [];
      yield put(multiLineView(processedDatForMultiLineView));
      yield put(fetchSuccess({data: parseData, isCompare: false, totalRecords, isTestRun}));
    } else {
      yield put(fetchSuccess({} as any));
    }

    // if (simulationData && simulationData.length) {
      // const isRCodeCompleted = simulationData.map((d: any) => d.rCodeResult).every((e: any) => e === 'Success');
      // console.log('>>>>>>>>>>>>Heelo>>>>>>>>>>>>>',isRCodeCompleted, webSocketInitiated)
      // if (!isRCodeCompleted && !webSocketInitiated) {
      //   yield call(
      //     OpenResultSocket,
      //     {...payload, tenantid, userid, projectId, socketIdentifier: newSocketIdentifier},
      //     payload.dispatch,
      //   );
      // }
    // }
  } catch (error) {
    console.log(error);
    yield put(fetchError(error?.error));
    if (error?.isSessionDeleted) {
      yield put(
        openBanner({
          message: CONSTANTS.INFORMATIONAL_MESSAGES.SESSION_RESULT_DELETE_MESSAGE(projectSessionId, label),
          bannerType: 'warning',
          autoClose: true,
        }),
      );
      yield call(forwardTo, `/projects/detail/${projectId}`);
    }
  }
}

export function* cancelSimulationSaga({payload}: any){
  const { tenantid } = yield select(getActiveuser);
  const socketIdentifier =sessionStorage.getItem('socketIdentifier') as string
  
  try {
    let param = {
      sessionId:payload,
      tenantId: tenantid ,
      socketIdentifier
    }
    const url = `${ENDPOINTS.TERMINATE_SIMULATION}?tenantid=${tenantid}`
      let data = yield call(callApi, url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(param),
      });
      if(data.success){
        yield put(cancelSimulationStatus("Successful"))
        yield call(forwardTo, `/projects/session/${payload}`);
      }
      else if(data.success === false){
        yield put(cancelSimulationStatus("Failure"))
      }
  } catch (error) {
    yield put(cancelSimulationStatus("Failure"))
  }
}
export function* handleSessionResult({
  data,
  payload,
  totalResults,
  testrunflg,
  webSocketInitiated = false,
  socketIdentifier,
}: any) {
  const {userid, tenantid} = yield select(getActiveuser);
  const {projectId} = payload;

  try {
    if (data) {
      // exclude failed results
      data = exculdeFailedResults(data);
      const newData = data;
      const processedDatForMultiLineView: any = yield call(processMultiLineData, newData);
      const parseData = data.length ? getParseData(data, false) : [];
      yield put(multiLineView(processedDatForMultiLineView));
      yield put(
        fetchSuccess({data: parseData, isCompare: false, totalRecords: totalResults, isTestRun: testrunflg}),
      );
    } else {
      yield put(fetchSuccess({} as any));
    }

    if (data) {
      if (!webSocketInitiated) {
        const projectName = data[0].projectName || '';
        const projSessionId = data[0].projSessionId || '';
        yield call(OpenResultSocket, {...payload, tenantid, userid, projectId, totalResults, projectName, projSessionId, socketIdentifier}, payload.dispatch);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function parseResults(data: any[]) {
  data = exculdeFailedResults(data);
  const parseData = data.length ? getParseData(data, false) : [];
  const processedDatForMultiLineView = processMultiLineData(parseData);
  return {
    processedDatForMultiLineView,
    parseData,
  };
}

export function OpenResultSocket({sid, projectId, tenantid, userid, socketIdentifier, projectName, projSessionId, totalCount}: any, dispatch: any) {
  try {
    // Add AuthToken & tenantid as query params to base socketUrl.
    const socketUrl = `${ENDPOINTS.SOCKET_URL}?Authorization=Bearer ${sessionStorage.authToken}&tenantid=${tenantid}`;
    sessionStorage.setItem("socketIdentifier", socketIdentifier)
    let simulationCounter = 0;
    const ws = new Sockette(socketUrl, {
      timeout: 5e3,
      maxAttempts: 10,
      onopen: (e) => {
        ws.json({route: 'saveSession', data: {sessionId: sid, userId: userid, socketIdentifier}});
      },
      onmessage: (e) => {
        const res = JSON.parse(e?.data || '{}');
        const {status, sessionType, objective, sessionId} = res.data || {};
       // console.log('>>>>>>>>>>>onMessage>>>>>>>>>>>>>>', objective, status, simulationCounter)
        const projectSessionId = projSessionId || res.data?.projectSessionId || '';
        const projectTitle = projectName || res.data?.projectName || '';
        const resultTenantId = res?.data?.tenantId;
        const activeTenantId = getSessionStorage('tenantid');
        const isSameTenant = () => Number(resultTenantId) === Number(activeTenantId);
        // const sessionID = sid || sessionId;
        // const isUserOnOutputPage = window.location.pathname.includes(`/projects/output/${sessionID}`);
        if (status === "Terminated") {
          // Close socket connection if the simulation has been terminated by user
          ws.close();
          sessionStorage.removeItem(sessionId);
          sessionStorage.removeItem("socketIdentifier");
        }
        if(objective=== "atd:dose" && isSameTenant()){
            sessionStorage.setItem(sessionId, 'inprogress');

          if((status === "Inprogress" || status === 'Completed')){
            // console.log(">>>Inside Inprogress", status, simulationCounter, totalCount);
            simulationCounter++;
            if(simulationCounter===totalCount){
              dispatch(fetchRequest({sid: sid || sessionId, projectId, projectSessionId, label:'', dispatch, webSocketInitiated:true,socketIdentifier, hideLoader:true, totalCount}));
              sessionStorage.removeItem(sessionId);
           // isUserOnOutputPage && dispatch(fetchRequest({sid: sid || sessionId, projectId, projectSessionId, label:'', dispatch, webSocketInitiated:true,socketIdentifier, hideLoader:true}));
            sessionStorage.removeItem("socketIdentifier");
            setTimeout(() => {
              dispatch(
                openBanner({
                  message: CONSTANTS.INFORMATIONAL_MESSAGES.SIMULATION_SUCCESS(projectSessionId, projectTitle),
                  autoClose: true,
                }),
              );
            }, totalCount*150);
            if (window.location.pathname.includes(`/projects/detail/${projectId}`)) {
              // If the user is in Project details page, reload sessions data.
              dispatch(setReloadProjectResults(true));
            }
              ws.close();
            }
          }
          //TODO - Remove commented code if simulationCounter approach works fine
          // if(status === 'Completed'){
          //   sessionStorage.removeItem(sessionId);
          //   isUserOnOutputPage && dispatch(fetchRequest({sid: sid || sessionId, projectId, projectSessionId, label:'', dispatch, webSocketInitiated:true,socketIdentifier, hideLoader:true}));
          //   sessionStorage.removeItem("socketIdentifier");
          //   setTimeout(() => {
          //     dispatch(
          //       openBanner({
          //         message: CONSTANTS.INFORMATIONAL_MESSAGES.SIMULATION_SUCCESS(projectSessionId, projectTitle),
          //         autoClose: true,
          //       }),
          //     );
          //   }, 1000);

          //    // If you are in the results page of the same session. reload.
          //   if (window.location.pathname.includes(`/projects/detail/${projectId}`)) {
          //     // If the user is in Project details page, reload sessions data.
          //     dispatch(setReloadProjectResults(true));
          //   }
          //   ws.close();
          // }

          return;
        }

        // TODO: Remove this if not necssary
        if (res?.data?.status === 'completed') {
          if (status === 'completed' && isSameTenant()) {
            // Do no show successBanner for GroupSequential.
            if (sessionType !== 'atd:groupsequential') {
              dispatch(
                openBanner({
                  message: CONSTANTS.INFORMATIONAL_MESSAGES.SIMULATION_SUCCESS(projectSessionId, projectTitle),
                  autoClose: true,
                }),
              );
            }

            // If you are in the results page of the same session. reload.
            if (window.location.pathname.includes(`/projects/output/${sid}`)) {
              window.location.reload();
            } else if (window.location.pathname.includes(`/projects/detail/${projectId}`)) {
              // If the user is in Project details page, reload sessions data.
              dispatch(setReloadProjectResults(true));
            }
            ws.close();
          }
        } else if (res?.data?.status === 'terminated') {
          // Close socket connection if the simulation has been terminated by user
          ws.close();
        }
      },
      onreconnect: (e) => {},
      onmaximum: (e) => {},
      onclose: (e) => {},
      onerror: (e) => console.log('Error:', e),
    });
  } catch (error) {
    // yield put(fetchError(error));
    console.warn(error?.error);
  }
}

export function* userRatingDesignSaga({payload}: PayloadAction<UserRatingType>) {
  try {
    const {resultid, sessionid, userid, projectid, tenantid, ratingvalue} = payload;
    const url = `${ENDPOINTS.POST_SESSION_OUTPUT_RATING}?userid=${userid}&tenantid=${tenantid}`;

    const body = {
      resultid,
      sessionid,
      projectid,
      ratingvalue,
    };
    const data = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
    });
    const response = {...data, ...payload};
    if (response) {
      yield put(userRatingSuccess(response));
    }
  } catch (error) {
    yield put(userRatingError(error?.error));
  }
}

export function* getCompareResults({payload}: any) {
  const {resultids, projectId} = payload;
  try {
    const {tenantid, userid} = yield select(getActiveuser);
    const url = `${ENDPOINTS.GET_CUSTOM_OUTPUT_COMPARISION_RESULTS}?resultids=${resultids}&userid=${userid}&tenantid=${tenantid}&projectid=${projectId}`;
    let {data} = yield call(callApi, url);
    if (data) {
      // exclude failed results
      data = exculdeFailedResults(data);
      const processedDatForMultiLineView: any = yield call(processMultiLineData, data);
      const parseData = data.length ? getParseData(data, true) : [];
      yield put(multiLineView(processedDatForMultiLineView));
      yield put(fetchSuccess({data: parseData, isTestRun: 0, totalRecords: 0, isCompare: true}));
    } else {
      yield put(fetchSuccess({} as any));
    }
  } catch (error) {
    yield put(fetchError(error?.error));
    if (error?.isSessionDeleted) {
      yield put(
        openBanner({
          message: CONSTANTS.INFORMATIONAL_MESSAGES.SESSION_COMPARE_RESULTS_DELETE_MESSAGE,
          bannerType: 'warning',
          autoClose: true,
        }),
      );
      yield call(forwardTo, `/projects/detail/${projectId}`);
    }
  }
}

export function* switchLayout({payload}: any) {
  try {
    yield delay(1000);
    yield put(toogleLayout(false));
  } catch (error) {
    console.log('error');
  }
}

const normalizeStatisticsData = (data: any) => {
  if (data.length) {
    const parseData = parseToSignificantFigure(data);
    const columns = map(parseData, 'name');
    const rows = Object.keys(omit(parseData[0], 'name')).map((i) => ({name: i, values: map(parseData, i)}));
    return {
      columns,
      rows,
    };
  }
  return {
    columns: [],
    rows: [],
  };
};

export const getParseData = (tempData: any[], isCompare: boolean) => {
  let parseData = [];
  const data = cloneDeep(tempData);
  if (Array.isArray(data) && !isEmpty(data)) {
    if (!isCompare) {
      parseData = sortBy(data, (el) => el.sessionDesignId);
    } else {
      parseData = data;
    }
    parseData = parseData
      .map((el) => {
        el['curveParams'] = parser(el['curveParams']);
        el['designParams'] = parser(el['designParams']);
        el['trialParams'] = parser(el['trialParams']);
        el['graphData'] = parseToSignificantFigure(el['graphData']);
        el['summaryStatisticsData'] = normalizeStatisticsData(parser(el['summaryStatisticsData'] || '[]'));
        el['mtdAnalysisData'] = parseToSignificantFigure(parser(el['mtdAnalysisData'] || '[]'));

        // for Output Compare Page append projSession id to sessionName
        // if(isCompare){
        //   el.sessionName = `Session ${el?.projSessionId} (${el.sessionName})`;
        // }

        return el;
      })
      .map((item) => {
        item['trialParams'].trialParams = item?.trialParams?.trialParams.reduce((acc: any, el: any) => {
          return {...acc, [el.name]: el};
        }, {});
        item['designParams'] = item.designParams.dsgn_params.reduce(
          (acc: any, el: any, _: any, arr: any[]) => {
            const newAcc = {...acc, [el.name]: el};

            if (item.designOption === 'BOIN') {
              newAcc[el.name].value = parseToPrecision(newAcc[el.name].value);

              // Convert boolean values to Yes or No for extraSafeboin,acceleratedTitrationboin & MTDboundeboin
              if (
                el.name === 'extraSafeboin' ||
                el.name === 'acceleratedTitrationboin' ||
                el.name === 'MTDboundeboin' ||
                el.name === 'EarlyStoppingBoin'
              ) {
                newAcc[el.name].value = newAcc[el.name].value ? 'Yes' : 'No';
              }

              // remove/dont display offsetboin if extraSafeboin is false/No
              if (
                el.name === 'offsetboin' &&
                (!newAcc['extraSafeboin'].value || newAcc['extraSafeboin'].value === 'No')
              ) {
                delete newAcc['offsetboin'];
              }
            }

            if (item.designOption === "i3+3") {
              newAcc[el.name].value = parseToPrecision(newAcc[el.name].value);

              // Convert boolean values to Yes or No for extraSafeboin,acceleratedTitrationboin & MTDboundeboin
              if (
                
                el.name === "EarlyStoppingI3+3"
              ) {
                newAcc[el.name].value = newAcc[el.name].value ? 'Yes' : 'No';
              }
            }
            if (item.designOption === "mTPI-2") {
              newAcc[el.name].value = parseToPrecision(newAcc[el.name].value);

              // Convert boolean values to Yes or No for extraSafeboin,acceleratedTitrationboin & MTDboundeboin
              if (
                el.name === "EarlyStoppingMTPI-2"
              ) {
                newAcc[el.name].value = newAcc[el.name].value ? 'Yes' : 'No';
              }
            }

            return newAcc;
          },
          {},
        );
        return item;
      });

    return parseData;
  }
  return data;
};

const getUniqueData = (data: any, identifier: string) => uniqBy(data, (x: any) => x[identifier]);

const processData = (data: Array<any>, identifier: string, orignalData: Array<any>) => {
  const isDesignOptions = identifier === 'designSessionId';
  const processedData: any = data.map((item: any) => ({
    ...(isDesignOptions
      ? {
          label: `S ${item.projSessionId}, ${item.refName}`,
          id: item[identifier],
          results: filterAndParseSpecficTabsData(orignalData, item[identifier], identifier),
        }
      : {
          label: item[identifier],
          id: item[identifier],
          results: filterAndParseSpecficTabsData(orignalData, item[identifier], identifier),
        }),
  }));
  return sortBy(processedData, 'label');
};

const filterAndParseSpecficTabsData = (
  orignalData: Array<any>,
  compareTo: string | number,
  identifier: string | number,
) => {
  const results = filter(orignalData, (v: any) => v[identifier] === compareTo);
  // const getTargetProbabiltyToxicity =
  const pickSomeData = map(results, (item) =>
    pick(item, [
      'graphData',
      'curveParams',
      'designSessionId',
      'projSessionId',
      'curveName',
      'refName',
      'curveType',
      'designOption',
      'trialParams',
    ]),
  );
  return pickSomeData;
};

const TOXICITY = 'TOX';

const processViews = (data: Array<any>, endPoint: string) => {
  const views = map(data[0].graphData, (item: any) => item.name);
  const filteredViews = filter(endPoint === TOXICITY ? DefaultViews : PrintEView, (item) =>
    views.includes(item?.id),
  );
  return filteredViews;
};

export const processMultiLineData = (data: any) => {
  try {
    const findSessionSelected = uniqBy(data, 'projSessionId');
    const hasMultipleSessions = findSessionSelected.length > 1;
    const uniqueCurves: Array<any> = processData(getUniqueData(data, 'curveName'), 'curveName', data);
    const uniquedesignOptions: Array<any> = hasMultipleSessions
      ? []
      : processData(getUniqueData(data, 'designOption'), 'designOption', data);
    const endPoint = queryTrailParams(data[0].trialParams, 'endpoint');
    const EOSTM = queryTrailParams(data[0].trialParams, 'EOSTM');
    const views = processViews(data, endPoint);

    return {curves: uniqueCurves, designOptions: uniquedesignOptions, endPoint, metaInfo: {EOSTM}, views};
  } catch (error) {
    console.log('error');
    const endPoint = data[0]?.trialParams ? queryTrailParams(data[0].trialParams, 'endpoint') : '';
    return {curves: [], designOptions: [], endPoint, views: []};
  }
};

export const exculdeFailedResults = (data: Array<any>) => filter(data, (result) => !has(result, 'error'));

export const DefaultViews = [
  {
    id: 'Reported MTD Distribution',
    title: 'Reported MTD Distribution',
    info: 'In addition to the doses, x-axis also displays out-of-range cases with lower than lowest dose, higher than highest dose and maximum subjects(maxN) reached.',
    isDefaultOpen: true,
  },
  {
    id: 'Subject Distribution',
    title: 'Subject Distribution',
    isDefaultOpen: false,
  },
  {
    id: 'Sample Size Distribution',
    title: 'Sample Size',
    isDefaultOpen: false,
  },
  {
    id: 'Total # Cohorts Distribution',
    title: 'Total # Cohorts Distribution',
    isDefaultOpen: false,
  },
  {
    id: 'Trial Duration Distribution',
    title: 'Trial Duration',
    isDefaultOpen: false,
  },
  {
    id: 'Expected # DLTs by Dose',
    title: 'Expected # DLTs by Dose',
    isDefaultOpen: false, 
  },
];

export const PrintEView = [
  {
    id: 'Reported RP2D(Recommended Phase 2 Dose) Distribution',
    title: 'Reported RP2D(Recommended Phase 2 Dose) Distribution',
    info: 'In addition to the doses, x-axis also displays out-of-range cases with early termination and no dose chosen',
    isDefaultOpen: true,
  },
  {
    id: 'Subject Distribution',
    title: 'Subject Distribution',
    isDefaultOpen: false,
  },
  {
    id: 'Sample Size Distribution',
    title: 'Sample Size',
    isDefaultOpen: false,
  },
  {
    id: 'Total # Cohorts Distribution',
    title: 'Total # Cohorts Distribution',
    isDefaultOpen: false,
  },
  {
    id: 'Trial Duration Distribution',
    title: 'Trial Duration',
    isDefaultOpen: false,
  },
  {
    id: `Expected # DLTs`,
    title: 'Expected # DLTs and # Effectiveness by Dose',
    isDefaultOpen: false,
  },
  {
    id: 'Posterior Distribution',
    title: 'Posterior Distribution',
    tooltip:
      'To make this graph system first form a set, A, which contains only the simulation runs where the modal dose was chosen. For each of the runs in the set A, a posterior distribution can be formed. From each posterior distribution, system takes 1,000 draws and counts how many of the draws fall inside the graduation region in each case. Then the elements of the set A are sorted from ‘least inside’ to ‘most inside’ the graduation region. From this sorted set  three percentiles are taken: the 25^th^, the 50^th^ (the median), and the 75^th^. For each of these posteriors, system plots the 950 most likely of the 1,000 draws',
      isDefaultOpen: false,
  },
];
