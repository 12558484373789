import { put, takeEvery, call, select } from 'redux-saga/effects';
import {
  launchSimulation,
  updateLaunchSimulation,
  simulationError,
} from '../slices/LaunchSimulationSlice';
import { ENDPOINTS } from '../../../services/endpoint';
import { callApi } from '../../../services/api';
import { sessionRequest } from '../slices/saveSessionSlice';
import { resetRemovedCurvedlist,getProjectLevelCurves } from '../slices/ToxicityCurveSlice';
import { resetremovedDesignList } from '../slices/DesignOptionSlice';
import { logOutRequest } from '../../loginPage/slices/index';
import { addGAEvent } from '../../../services/googleAnalytics';
import {RootState} from '../../../app/store';
import { handleSessionResult, OpenResultSocket } from './sessionResult';
import { forwardTo } from './projectDetail';
import nanoid from 'nanoid';
import { addSessionStorage } from '../../../common/utils/useSessionStorage'

export function* watchLaunchSimulation() {
  yield takeEvery(launchSimulation, launchSimulationSaga);
}

export function* launchSimulationSaga({ payload }: any) {
  try {
    const isLogoutButtonClicked:boolean = yield select((state: RootState) => state.authInfo.isLogoutButtonClicked);
    const { projid, userid, testRun, status, sid, tenantid, sessionName, isLoggingOut, userCfgParam, newUserCfgParam } = payload;
    let socketIdentifier = nanoid(12) || '';
    socketIdentifier = socketIdentifier.replace(/-/ig, '')
    const totalCount = payload?.designParams?.length  * payload?.curveParams?.length;
    status === 'Completed' && OpenResultSocket({...payload, tenantid, userid, projectId:projid, socketIdentifier, totalCount}, payload.dispatch);

    let url = `${
      ENDPOINTS.SAVE_LAUNCH_SIMULATION_PARAMETERS
    }?projid=${projid}&userid=${userid}&testrunflg=${+testRun}&status=${status}&tenantid=${tenantid}`;
    const isNewSession = sid ? false : true;
    
    if (!isNewSession) {
      const sID = `&sessionid=${sid}`;
      url += sID;
    }

    if(isLoggingOut || status === 'Draft') {
      url += `&type=autosave`;
    }
    if(status === 'Completed' || status !== 'Draft') {
      url += `&socketIdentifier=${socketIdentifier}`
    }

    const body = {
      trialParams: payload.trialParams,
      designParams: payload.designParams,
      curveParams: payload.curveParams,
      trialDurationParams: payload.trialDurationParams,
      includeTrialDuration: payload.includeTrialDuration,
      sessionName,
      ...payload.endpoint!=='TOX' && userCfgParam && { userCfgParam: payload.userCfgParam },
      ...payload.endpoint!=='TOX' && newUserCfgParam && { newUserCfgParam: payload.newUserCfgParam },
    };


    let data = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
    });
    
    totalCount && addSessionStorage('totalOptionsCount', totalCount)
    
    //status === 'Completed' && OpenResultSocket({...payload, tenantid, userid, projectId:projid, socketIdentifier, totalCount}, payload.dispatch);

    if (isNewSession && data) {
      addGAEvent({
        category: 'Project',
        event: 'CREATE_SESSION',
        action: 'DE_CREATE_SESSION',
        label: 'DE_CREATE_SESSION'
      });
    }

    if(isLoggingOut) {
      // If autosave, simply save & logout. No post-processing.
      yield put(logOutRequest(isLogoutButtonClicked ? '' : 'autologout'));
    } else {
      if (data) {
        data['isNewSession'] = isNewSession;
        yield put(updateLaunchSimulation(data));

        if (status === 'Completed') {
          const {
            totalResults,
            data: {results,sessionID},
          } = data;         

          yield handleSessionResult({
            data: results,
            payload: {projectId: projid,sid:sessionID, dispatch:payload.dispatch},
            totalResults,
            testrunflg: +testRun, 
            socketIdentifier,   
            webSocketInitiated:true,
          });

          yield call(forwardTo, `/projects/output/${sessionID}`);         
        }
       
        if(status === 'Draft'){
          yield put(resetRemovedCurvedlist());
          yield put(resetremovedDesignList());
  
          // If its not a newSession, reload sessionData & curves data.
          // If its a newSession, we are redirecting the user to session show page. Hence, data need not be fetched here.

          const isUserOnSessionPage = window.location.pathname.includes(`/projects/session`);
          if(!isNewSession && isUserOnSessionPage) {
            yield put(
              sessionRequest({
                projectid: projid,
                userid,
                sid: data?.data?.sessionID,
                tenantid,
              })
            );
            yield put(getProjectLevelCurves({
              sid:'',
              tenantId:tenantid,
              projectId:projid,
              userId:userid,
              curveName:''
            }))
          }
        }
  
      } else {
        yield put(updateLaunchSimulation({} as any));
      }
    }

  } catch (error) {
    console.log(error)
    if(payload.isLoggingOut) {
      yield put(logOutRequest(''));
    }
    yield put(simulationError(String(error?.error)));
  }
}
