import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type INITIAL_STATE = {
  isLoading: boolean;
  isRatingLoading: boolean;
  error: string;
  data: Array<any>;
  isTestRun: boolean;
  isProjectOwner: boolean;
  accrualWorkflowAccepted: boolean;
  sessionName: string | number;
  projSessionId: string | number;
  sessionId: string | number;
  lastOptionData: any;
  addedAdjustInputs: boolean;
  updateRangeSessionData:any;
};

export const initialState: INITIAL_STATE = {
  isLoading: false,
  isRatingLoading: false,
  error: '',
  data: [],
  isTestRun: false,
  isProjectOwner: false,
  accrualWorkflowAccepted: false,
  sessionName: '',
  sessionId: '',
  projSessionId:'',
  lastOptionData:{},
  addedAdjustInputs: false,
  updateRangeSessionData:{}
};

export const gsSessionResultsSlice = createSlice({
  name: 'gsSessionResults',
  initialState: initialState as INITIAL_STATE,
  reducers: {
    fetchRequest: (state: INITIAL_STATE, action) => {
      state.isLoading = true;
    },
    fetchSuccess: (state: INITIAL_STATE, action: PayloadAction<any>) => {
      const {data, isTestRun, isProjOwner, bypassAccuralWorkflowCheckAccepted} = action.payload;
      state.data = data;
      state.isTestRun = isTestRun;
      state.isProjectOwner = isProjOwner;
      state.accrualWorkflowAccepted = bypassAccuralWorkflowCheckAccepted;
      state.sessionName = data.length ? data[0].sessionName  : '';
      state.sessionId = data.length ? data[0].projSessionId : '';
      state.projSessionId = data.length ? data[0].projSessionId : '';
      state.isLoading = false;
    },
    fetchFailure: (state: INITIAL_STATE, action) => {
      state.isLoading = false;
      state.error = action?.payload;
    },
    userRatingRequest: (state: INITIAL_STATE, action: any) => {
      state.isRatingLoading = true;
    },
    userRatingSuccess: (state: INITIAL_STATE, action: any) => {
      state.isRatingLoading = false;
      const {
        resultid,
        data: {avgRating},
        ratingvalue,
      } = action.payload;
      const index = state.data.findIndex((el: any) => el.resultId === resultid);
      if (index > -1) {
        state.data[index].avgRating = avgRating ? avgRating : state.data[index].avgRating;
        state.data[index].userRating = ratingvalue ? ratingvalue : state.data[index].userRating;
      }
    },
    userRatingError: (state: INITIAL_STATE, action: any) => {
      state.isRatingLoading = false;
      state.error = JSON.stringify(action?.payload);
    },
    resetResults: (state: INITIAL_STATE) => {
      state.data = [];
      state.isTestRun = false;
      state.isProjectOwner = false;
      state.sessionName = '';
      state.projSessionId = '';
      state.sessionId = '';
    },
    switchColumns: (state: INITIAL_STATE, action: PayloadAction<{index: number; arrowPos: string}>) => {
      const {index, arrowPos} = action.payload;

      if (arrowPos === 'right') {
        [state.data[index], state.data[index + 1]] = [state.data[index + 1], state.data[index]];
      }
      if (arrowPos === 'left') {
        [state.data[index], state.data[index - 1]] = [state.data[index - 1], state.data[index]];
      }
    },
    fetchCompareRequest: (state: any, action) => {
      state.isLoading = true;
    },
    fetchCompareSuccess: (state: any, action) => {
      const { data, bypassAccuralWorkflowCheckAccepted } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.accrualWorkflowAccepted = bypassAccuralWorkflowCheckAccepted;
    },
    fetchCompareFailure: (state: any, action) => {
      state.isLoading = false;
      state.error = action?.payload;
    },
    getLastOptionDataRequest: (state: INITIAL_STATE, action) => {
      state.isLoading = true;
    },
    getLastOptionDataSuccess: (state: INITIAL_STATE, action) => {
      const { input } = action.payload;
      state.lastOptionData = input;
      state.isLoading = false;
    },
    getLastOptionDataFailure: (state: INITIAL_STATE, action) => {
      state.isLoading = false;
      state.error = action?.payload;
    },
    adjustInputsRequest: (state: INITIAL_STATE, action) => {
      state.isLoading = true;
    },
    adjustInputsSuccess: (state: INITIAL_STATE, action) => {
      state.isLoading = false;
      state.addedAdjustInputs=true;
    },
    adjustInputsFailure: (state: INITIAL_STATE, action) => {
      state.isLoading = false;
      state.error = action?.payload;
    },
    resetAdjustInputs: (state: any) => {
      state.addedAdjustInputs = false;
      state.isLoading = false;
    },
    addChartPreviewImgSrc: (state: INITIAL_STATE, action: PayloadAction<any>) => {
      const { chartPreviewImgSrc, resultId, curveName } = action.payload;
      state.data = state.data
        .map(sessionData => {
          if(sessionData.resultId === resultId) {
              let graphsData = sessionData?.graphData?.map((graphData: any) => (
                (graphData.fileName === curveName) ? {...graphData, chartPreviewImgSrc: chartPreviewImgSrc} : graphData
              ));
              sessionData.graphData = graphsData;
            } 
            return sessionData
          })
    },
    updateGraphRange:(state:INITIAL_STATE,action:any) => {
      state.isLoading = true
    },
    updateGraphRangeSuccess:(state:INITIAL_STATE,action:any) => {
      const {data} = action.payload
      state.isLoading = false;
      state.data =data;
    },
    updateGraphRangeFailure:(state:INITIAL_STATE,action:any) => {
      state.isLoading = false;
      state.error = action?.payload;
    },
    fetchUpdateRangeSessionData:(state:INITIAL_STATE,action:any) => {
      state.isLoading = true;
    },
    fetchUpdateRangeSessionDataSucess:(state:INITIAL_STATE,action:any) => {
      const {data,sessionName} = action.payload
      state.isLoading = false;
      state.updateRangeSessionData = {...data,sessionName}
    },
    fetchUpdateRangeSessionDataFailure:(state:INITIAL_STATE,action:any) => {
      state.isLoading = false;
      state.updateRangeSessionData = {};
      state.error = action?.payload;
    },
    resetUpdateRangeSessionData:(state:INITIAL_STATE) => {
      state.updateRangeSessionData ={}
    },
    addSSRChartPreviewSource:(state: INITIAL_STATE, action: PayloadAction<any>) => {
      const { chartPreviewImgSrc, resultId } = action.payload;
      state.data = state.data
      .map(sessionData => {
        if(sessionData.resultId === resultId) {
          sessionData.ssr.ssrGraphData.graph = {...sessionData?.ssr?.ssrGraphData?.graph,chartPreviewImgSrc}
          } 
          return sessionData
        })
    }
  },
});

export const {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  userRatingRequest,
  userRatingSuccess,
  userRatingError,
  resetResults,
  switchColumns,
  fetchCompareRequest,
  fetchCompareSuccess,
  fetchCompareFailure,
  adjustInputsRequest,
  adjustInputsSuccess,
  adjustInputsFailure,
  getLastOptionDataRequest,
  getLastOptionDataSuccess,
  getLastOptionDataFailure,
  resetAdjustInputs,
  addChartPreviewImgSrc,
  updateGraphRange,
  updateGraphRangeSuccess,
  updateGraphRangeFailure,
  fetchUpdateRangeSessionData,
  fetchUpdateRangeSessionDataSucess,
  fetchUpdateRangeSessionDataFailure,
  resetUpdateRangeSessionData,
  addSSRChartPreviewSource,
} = gsSessionResultsSlice.actions;
export default gsSessionResultsSlice.reducer;
