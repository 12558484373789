import React, {useEffect, useState} from 'react';
import Table from 'apollo-react/components/Table';
import {useDispatch, useSelector} from 'react-redux';
import {fetchRequest} from '../../slices/TableSlice';
import {RootState} from '../../../../app/store';
import {selectProjectList, selectProjectTotalCount} from '../../selectors/ProjectHomeSelectors';
import { getProjectTableConfig} from './TableConfiguration';
import { getRowsPerPageOptions } from '../../../../common/utils/tableUtils';
import styles from './Table.module.scss';
import {TableCompProps} from '../../types/ProjectListType';
import {getActiveuser} from '../../selectors/userDetailsSelector';
import { IFilters } from '../../../../common/components/TableFilters/types';
import { transformFilters } from '../../../../common/components/TableFilters/utils';
import UserMessageBanner from '../../../../common/components/UserMessageBanner';

export const ProjectTable: React.FC<TableCompProps> = ({tab, showFilters, setShowFilters, tableKey, filters, setFilters}) => {
  const dispatch = useDispatch();
  let rows = useSelector(selectProjectList) || [];
  const size: number = Number(useSelector(selectProjectTotalCount)) || 0;
  let [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isLoading = useSelector((state: RootState) => state.projects.isLoading);
  const {userid, tenantid} = useSelector(getActiveuser);
  const [sortedColumn, setSortedColumn] = useState('createdat');
  const [sortOrder, setSortOrder] = useState('desc');

  useEffect(() => {
    dispatch(
      fetchRequest({ page: ++page, limit: rowsPerPage, tab, userid, tenantid, sortedColumn, sortOrder, type: tab, filters})
    );
    window.scrollTo(0, 0);
  }, [dispatch, rowsPerPage, tab, page, userid, tenantid, sortedColumn, sortOrder, filters]);

  const onChangeHandler = (
    newRowsPerPage: number,
    _sortedColumn: string,
    _sortOrder: string,
    _filters: IFilters,
    _page = 0,
  ) => {
    setFilters(transformFilters(_filters));
    setSortedColumn(_sortedColumn);
    setSortOrder(_sortOrder);
    if (newRowsPerPage !== rowsPerPage) {
      setPage(0);
      setRowsPerPage(newRowsPerPage);
    } else {
      if (page !== _page) {
        setPage(_page);
      }
    }
  };
  return (
    <div id='table' className='tableContainer' data-testid="projectTable" >
      <Table
        columns={getProjectTableConfig(tab)}
        classes={{
          tableWrapper: styles.tableWrapper
        }}
        key={tableKey}
        showFilters={showFilters}
        showFilterIcon={true}
        onToggleFilters={() => setShowFilters(!showFilters)}
        rows={rows}
        rowsPerPageOptions={getRowsPerPageOptions(size)}
        tablePaginationProps={{
          labelDisplayedRows: ({from, to, count}: any) => (
            <>
              {'Projects '}
              <span style={{whiteSpace: 'nowrap'}}>{`${from}-${to} of ${count}`}</span>
            </>
          ),
        }}
        sortedColumn={sortedColumn}
        sortOrder={sortOrder}
        isLoading={isLoading}
        onChange={onChangeHandler}
        size={size}
        page={page}
        rowsPerPage={rowsPerPage}
      />
      <UserMessageBanner />
    </div>
  );
};

export default ProjectTable;
