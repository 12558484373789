import React, { useEffect, Suspense } from 'react';
import styles from './App.module.scss';
import {
  Route,
  Switch,
  withRouter,
  useLocation,
  Redirect,
} from 'react-router-dom';
import Navigation from './common/components/Navigationbar';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  fetchUserRequest, setActiveTenant,
} from './features/projects/slices/userDetailsSlice';
import { RootState } from './app/store';
import Loader from 'apollo-react/components/Loader';
import { Alert, AlertTitle } from '@material-ui/lab';
import { GetURLQuery } from './common/utils/queryParams';
import ProjectWelcome from './features/loginPage/components/ProjectWelcome';
import AccessDenied from './common/components/AccessDenied';
import RedirectAuthentication from './features/loginPage/components/RedirectAuthentication';
import { getTenantAdminRole, getActiveUserRole } from './features/userManagement/selectors/userSelector';
import ProjectsHome from './features/projects/components/ProjectsHome';
import DefaultTenantSelect from './features/loginPage/components/DefaultTenantSelect';
import get from 'lodash/get'
import AutoLogout from './common/components/AutoLogout';
import Footer from './common/components/Footer';
import history from './common/utils/history';

const Projects = React.lazy(() => import('./features/projects'));
const UserManagement = React.lazy(() => import('./features/userManagement'));
const TenantManagement = React.lazy(() => import('./features/tenantManagement'));
const IntegrationManagement = React.lazy(() => import('./features/integrationManagement/components/IntegrationManagementHome'));

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user, shallowEqual);
  const isTenantAdmin = useSelector(getTenantAdminRole);
  const activeUserRole = useSelector(getActiveUserRole);
  const aunthenticatedUser = sessionStorage.getItem('aunthenticatedUser');
  const errorMsg = get(user, 'error.message', null);
  //To Do - Use default  user id receivedfrom authentication
  //const { authenticationSuccess } = useSelector((state: RootState) => state.redirectAuthentication);
  const isDefaultTenantSelected = useSelector((state: RootState) => state.user?.details?.defaultTenantId, shallowEqual);
  const isLoading = useSelector((state: RootState) => state.authInfo.isLoading);
  const userID =
    GetURLQuery(location.search, 'userid') || user.details?.userid || 1;

  useEffect(() => {
    aunthenticatedUser && userID && dispatch(fetchUserRequest(userID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.search]);

  useEffect(() => {
    const activeTenant = GetURLQuery(location.search, 'workspace');
    if(activeTenant){
      dispatch(setActiveTenant(+activeTenant));
      history.push('/');
    }
  }, [dispatch, location.search]);

  if (user.isLoading) {
    return <Loader />;
  }

  if (errorMsg) {
    const { type, message } = user.error;
    if(type === 'Error'){
      return (
        <Alert severity='error'>
          <AlertTitle>Error</AlertTitle>
            App encountered an Error , Please find the below Log —{' '}
          <strong>{`${message}`}</strong>
        </Alert>
      );
    } else if(type === 'Warning'){
      return (
        <AccessDenied />
      );
    } else{
    return <p>Error {message}</p>
    }
  }

  const RedirectRoute = () => {
    if(activeUserRole === "System Admin") {
      return <Redirect to='/tenantManagement' />;
    } 
    else if(isDefaultTenantSelected){
            if (isTenantAdmin) {
              return <Redirect to='/userManagement' />;
            } else {
              return <Redirect to='/projects' />;
            }
          }
  };
  
  const AuthenticatedRoutes = () => {
    return (
    <div className={styles.App}>
      <Navigation user={user.details} />
      {activeUserRole !== "System Admin" && !isDefaultTenantSelected && <DefaultTenantSelect />}
      <AutoLogout />
      {isLoading && <Loader classes={{ loaderOverlay: styles.loaderRoot }} />}
      <div className={styles.mainContent}>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route path='/' exact>
                {RedirectRoute()}
              </Route>
              <Route path='/userManagement' component={UserManagement} exact />
              <Route path='/tenantManagement' component={TenantManagement} exact />
              <Route path='/integrationManagement' component={IntegrationManagement} exact />
              <Route path='/projects' component={ProjectsHome} exact />
              <Route path='/projects/*' component={Projects} exact />
              <Route path='/logout' component={AccessDenied} exact />
              <Route path={'*'} exact>
                {RedirectRoute()}
              </Route>
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </div>
      <Footer />
    </div>
  );
}

if(aunthenticatedUser && user?.details?.userID){
  return AuthenticatedRoutes() 
} else if(!aunthenticatedUser) {
  return <Switch>
  <Route path='/' component={ProjectWelcome} exact />
  <Route path='/auth/route' component={RedirectAuthentication} exact />
  <Route path={['/accessRestricted', '/logout']} component={AccessDenied} exact />
  <Route path={'*'} component={ProjectWelcome} />
</Switch>
} else {
  return <Loader />;
}
};

export default withRouter(App);

class ErrorBoundary extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state as any;
    if (hasError) {
      return <p>Loading failed! Please reload.</p>;
    }

    return this.props.children;
  }
}
