import {getSessionStorage} from './../../../../../common/utils/useSessionStorage';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  averageSiteDuration,
  GSDesignTypes,
  medianRecruitmentRates,
} from '../../../components/Objectives/GroupSequential/SessionResults/Results/AccrualCalculator/utils/constants';
import {isEmpty} from 'lodash';
import {
  extractFieldValue,
  checkIfResultsChanged,
  checkResultRcodeError,
} from '../../../utils/accrualCalculatorUtils';
import {current} from '@reduxjs/toolkit';
import {limitDecimal} from '../../../components/Objectives/GroupSequential/SessionResults/Results/AccrualCalculator/Forms/eventHazardRatesTTE/utils';

type INTIAL_STATE = {
  isLoading: boolean;
  error: string;
  formFields: any;
  formInitialValues: any;
  sessionId: number | string | null;
  showModal?: boolean;
  isSavedSession: boolean;
  schema: Array<any>;
  IsNewSession: boolean;
  isComputed: boolean;
  projSessionId?: number | string | null;
  defaultValues?: any;
  simulationDesginId: number | null;
  configureGroupsModal: {
    isLoading: boolean;
    error?: any;
    isGroupsConfigurationCompleted: boolean;
    isSaved: boolean;
  };
  resultData: any;
  hideSimulation: boolean;
  hasRcodeError: boolean;
  versionClonedFrom: number | null;
  noOfSimulation: number | string;
  designType: string;
  calculatedResults: Array<any>;
  version: any;
  versionName: string | null;
  status: string;
  simulationOutputResults: any;
  versions: Array<any>;
  dropDownValues: Array<any>;
  accrualVersions: {
    loading: boolean;
  };
  isChangeinResults: boolean;
  updatedCalculateStudyDuration: any;
  isConfigModalOpen: boolean;
  rFormattedOutput: any;
};

export const initialState: any = {
  isLoading: false,
  error: '',
  formFields: {},
  formInitialValues: {},
  schema: [],
  sessionId: null,
  showModal: false,
  isSavedSession: false,
  IsNewSession: false,
  isComputed: false,
  projSessionId: '',
  defaultValues: {},
  simulationDesginId: null,
  configureGroupsModal: {
    isLoading: false,
    error: '',
    isGroupsConfigurationCompleted: false,
    isSaved: false,
  },
  resultData: {},
  hideSimulation: false,
  hasRcodeError: false,
  versionClonedFrom: null,
  noOfSimulation: '',
  designType: '',
  calculatedResults: [],
  version: '',
  versionName: null,
  status: '',
  simulationOutputResults: {},
  versions: [],
  dropDownValues: [],
  accrualVersions: {
    loading: false,
  },
  isChangeinResults: false,
  updatedCalculateStudyDuration: {},
  isConfigModalOpen: false,
  rFormattedOutput: {},
};

// Reverting re-setting of formInitialValues.
const getMedianRatesInitial = (input: any) => {
  const {
    medianRecruitmentRates,
    createNewModel: {ConfigureGroups},
  } = input;
  const {reportingTimeUnitMRR, medianRecruitmentRates: medianRates} = medianRecruitmentRates;
  return {
    reportingTimeUnitMRR,
    medianInitialValues: [
      ...ConfigureGroups.configureGroupsSites.map((data: any, index: number) => {
        const {group} = data;
        const [groupInfo] = group;
        return {
          groupName: groupInfo.name,
          groupId: groupInfo.id,
          meanAccruingMRR: medianRates[index]?.meanAccruingMRR,
          stdDevMRR: medianRates[index]?.stdDevMRR,
          medianCensorDisMRR: medianRates[index]?.medianCensorDisMRR,
        };
      }),
    ],
  };
};

const getMedianRatesTTEInitial = (input: any) => {
  const {
    medianRecruitmentRatesTTE,
    createNewModel: {ConfigureGroups},
  } = input;
  const {reportingTimeUnitMRR, medianRecruitmentRatesTTE: medianRates} = medianRecruitmentRatesTTE;
  return {
    reportingTimeUnitMRR,
    medianInitialValues: [
      ...ConfigureGroups.configureGroupsSites.map((data: any, index: number) => {
        const {group} = data;
        const [groupInfo] = group;
        return {
          groupName: groupInfo.name,
          groupId: groupInfo.id,
          meanAccruingMRR: medianRates[index]?.meanAccruingMRR,
          stdDevMRR: medianRates[index]?.stdDevMRR,
          medianTimeToEventMRR: medianRates[index]?.medianTimeToEventMRR,
          medianTimeToCensoringMRR: medianRates[index]?.medianTimeToCensoringMRR,
        };
      }),
    ],
  };
};

const getAverageSitesInitial = (input: any) => {
  const {
    averageSiteDuration,
    createNewModel: {ConfigureGroups},
  } = input;
  const {reportingTimeUnitASD, averageSiteDuration: averageSiteRates} = averageSiteDuration;
  return {
    reportingTimeUnitASD,
    averageSiteRates: [
      ...ConfigureGroups.configureGroupsSites.map((data: any, index: number) => {
        const {group} = data;
        const [groupInfo] = group;
        return {
          groupName: groupInfo.name,
          groupId: groupInfo.id,
          plndDateOfFrstPatientStdEntASD: averageSiteRates[index]?.plndDateOfFrstPatientStdEntASD,
          meanIntrlBwtFstPatientEntAddSiteOpenASD:
            averageSiteRates[index]?.meanIntrlBwtFstPatientEntAddSiteOpenASD,
          // avgLOTBeforeSiteOpensASD: averageSiteRates[index]?.avgLOTBeforeSiteOpensASD,
          avgLOTSiteStaysOpensASD: averageSiteRates[index]?.avgLOTSiteStaysOpensASD,
        };
      }),
    ],
  };
};

const formatInitialValues = (initialValues: any, input: any) => {
  const {meanAccruingMRR, stdDevMRR, medianCensorDisMRR, reportingTimeUnitMRR} =
    initialValues[medianRecruitmentRates.formKey];
  const {
    plndDateOfFrstPatientStdEntASD,
    meanIntrlBwtFstPatientEntAddSiteOpenASD,
    avgLOTSiteStaysOpensASD,
    reportingTimeUnitASD,
  } = initialValues[averageSiteDuration.formKey];
  const {
    reportingTimeUnitMRR: reportingTimeUnitMRRTTE,
    meanAccruingMRR: meanAccruingMRRTTE,
    stdDevMRR: stdDevMRRTTE,
    medianTimeToEventMRR,
    medianTimeToCensoringMRR,
  } = initialValues['medianRecruitmentRatesTTE'] || {};
  if (input) {
    const {
      medianRecruitmentRates: inputMedianRecruitmentRates,
      averageSiteDuration: inputAverageSiteDuration,
      createNewModel: {ConfigureGroups},
      medianRecruitmentRatesTTE: inputMedianRecruitmentRatesTTE,
    } = input;
    if (!isEmpty(inputMedianRecruitmentRates && inputAverageSiteDuration)) {
      return {
        ...initialValues,
        [averageSiteDuration.formKey]: {
          ...inputAverageSiteDuration,
          [averageSiteDuration.formKey]: [
            ...ConfigureGroups.configureGroupsSites.map((data: any, index: number) => {
              const {group} = data;
              const [groupInfo] = group;
              return {
                groupName: groupInfo.name,
                groupId: groupInfo.id,
                plndDateOfFrstPatientStdEntASD: extractFieldValue(
                  inputAverageSiteDuration[averageSiteDuration.formKey][index]
                    ?.plndDateOfFrstPatientStdEntASD,
                  plndDateOfFrstPatientStdEntASD,
                ),
                meanIntrlBwtFstPatientEntAddSiteOpenASD: extractFieldValue(
                  inputAverageSiteDuration[averageSiteDuration.formKey][index]
                    ?.meanIntrlBwtFstPatientEntAddSiteOpenASD,
                  meanIntrlBwtFstPatientEntAddSiteOpenASD,
                ),
                // avgLOTBeforeSiteOpensASD: extractFieldValue(inputAverageSiteDuration[averageSiteDuration.formKey][index]?.avgLOTBeforeSiteOpensASD, avgLOTBeforeSiteOpensASD),
                avgLOTSiteStaysOpensASD: extractFieldValue(
                  inputAverageSiteDuration[averageSiteDuration.formKey][index]?.avgLOTSiteStaysOpensASD,
                  avgLOTSiteStaysOpensASD,
                ),
              };
            }),
          ],
        },
        [medianRecruitmentRates.formKey]: {
          ...inputMedianRecruitmentRates,
          [medianRecruitmentRates.formKey]: [
            ...ConfigureGroups.configureGroupsSites.map((data: any, index: number) => {
              const {group} = data;
              const [groupInfo] = group;
              return {
                groupName: groupInfo.name,
                groupId: groupInfo.id,
                meanAccruingMRR: extractFieldValue(
                  inputMedianRecruitmentRates[medianRecruitmentRates.formKey][index]?.meanAccruingMRR,
                  meanAccruingMRR,
                ),
                stdDevMRR: extractFieldValue(
                  inputMedianRecruitmentRates[medianRecruitmentRates.formKey][index]?.stdDevMRR,
                  stdDevMRR,
                ),
                medianCensorDisMRR: extractFieldValue(
                  inputMedianRecruitmentRates[medianRecruitmentRates.formKey][index]?.medianCensorDisMRR,
                  medianCensorDisMRR,
                ),
              };
            }),
          ],
          pieceWiseAccruals: [
            ...ConfigureGroups.configureGroupsSites.map((_: any, index: number) => ({
              accrualRateperiod: inputMedianRecruitmentRates?.['pieceWiseAccruals']
                ? inputMedianRecruitmentRates?.['pieceWiseAccruals'][index]?.accrualRateperiod
                : 2,
              accrualDurations: inputMedianRecruitmentRates?.['pieceWiseAccruals']
                ? inputMedianRecruitmentRates?.['pieceWiseAccruals'][index]?.accrualDurations
                : Array.from({length: 1}, (_, i) => ''),
              accrualRates: inputMedianRecruitmentRates?.['pieceWiseAccruals']
                ? inputMedianRecruitmentRates?.['pieceWiseAccruals'][index]?.accrualRates
                : Array.from({length: 2}, (_, i) => ''),
            })),
          ],
        },
        medianRecruitmentRatesTTE: {
          ...(inputMedianRecruitmentRatesTTE
            ? inputMedianRecruitmentRatesTTE
            : {
                reportingTimeUnitMRR: reportingTimeUnitMRRTTE,
                meanAccruingMRR: meanAccruingMRRTTE,
                stdDevMRR: stdDevMRRTTE,
                // medianCensorDisMRR:medianCensorDisMRRTTE,
                medianTimeToEventMRR,
                medianTimeToCensoringMRR,
              }),
          medianRecruitmentRatesTTE: [
            ...ConfigureGroups.configureGroupsSites.map((data: any, index: number) => {
              const {group} = data;
              const [groupInfo] = group;
              return {
                groupName: groupInfo.name,
                groupId: groupInfo.id,
                meanAccruingMRR: extractFieldValue(
                  inputMedianRecruitmentRatesTTE?.['medianRecruitmentRatesTTE'][index]?.meanAccruingMRR ||
                    meanAccruingMRRTTE,
                  meanAccruingMRRTTE,
                ),
                stdDevMRR: extractFieldValue(
                  inputMedianRecruitmentRatesTTE?.['medianRecruitmentRatesTTE'][index]?.stdDevMRR ||
                    stdDevMRRTTE,
                  stdDevMRRTTE,
                ),
                // medianCensorDisMRR:medianCensorDisMRRTTE,
                medianTimeToEventMRR: extractFieldValue(
                  inputMedianRecruitmentRatesTTE?.['medianRecruitmentRatesTTE'][index]
                    ?.medianTimeToEventMRR || medianTimeToEventMRR,
                  medianTimeToEventMRR,
                ),
                medianTimeToCensoringMRR: extractFieldValue(
                  inputMedianRecruitmentRatesTTE?.['medianRecruitmentRatesTTE'][index]
                    ?.medianTimeToCensoringMRR || medianTimeToCensoringMRR,
                  medianTimeToCensoringMRR,
                ),
              };
            }),
          ],
          pieceWiseAccrualsTTE: [
            ...ConfigureGroups.configureGroupsSites.map((_: any, index: number) => ({
              accrualRateperiod: inputMedianRecruitmentRatesTTE?.['pieceWiseAccrualsTTE']
                ? inputMedianRecruitmentRatesTTE?.['pieceWiseAccrualsTTE'][index]?.accrualRateperiod
                : 2,
              accrualDurations: inputMedianRecruitmentRatesTTE?.['pieceWiseAccrualsTTE']
                ? inputMedianRecruitmentRatesTTE?.['pieceWiseAccrualsTTE'][index]?.accrualDurations
                : Array.from({length: 1}, (_, i) => ''),
              accrualRates: inputMedianRecruitmentRatesTTE?.['pieceWiseAccrualsTTE']
                ? inputMedianRecruitmentRatesTTE?.['pieceWiseAccrualsTTE'][index]?.accrualRates
                : Array.from({length: 2}, (_, i) => ''),
            })),
          ],
          hazardRateTTE: [
            ...ConfigureGroups.configureGroupsSites.map((_: any, index: number) => ({
              accrualRateperiod: inputMedianRecruitmentRatesTTE?.['hazardRateTTE']
                ? inputMedianRecruitmentRatesTTE?.['hazardRateTTE'][index]?.accrualRateperiod
                : 2,
              hazardDurations: inputMedianRecruitmentRatesTTE?.['hazardRateTTE']
                ? inputMedianRecruitmentRatesTTE?.['hazardRateTTE'][index]?.hazardDurations
                : Array.from({length: 1}, (_, i) => ''),

              medianTimeToEvents: limitDecimal(
                inputMedianRecruitmentRatesTTE?.['hazardRateTTE']
                  ? inputMedianRecruitmentRatesTTE?.['hazardRateTTE'][index]?.medianTimeToEvents
                  : Array.from({length: 2}, (_, i) => ''),
              ),
              medianTimeToCensoring: limitDecimal(
                inputMedianRecruitmentRatesTTE?.['hazardRateTTE']
                  ? inputMedianRecruitmentRatesTTE?.['hazardRateTTE'][index]?.medianTimeToCensoring
                  : Array.from({length: 2}, (_, i) => ''),
              ),
              HazardRatioUnderH1: limitDecimal(
                inputMedianRecruitmentRatesTTE?.['hazardRateTTE']
                  ? inputMedianRecruitmentRatesTTE?.['hazardRateTTE'][index]?.HazardRatioUnderH1
                  : Array.from({length: 2}, (_, i) => ''),
              ),
              EventHazardRateControlGroup: limitDecimal(
                inputMedianRecruitmentRatesTTE?.['hazardRateTTE']
                  ? inputMedianRecruitmentRatesTTE?.['hazardRateTTE'][index]?.EventHazardRateControlGroup
                  : Array.from({length: 2}, (_, i) => ''),
              ),
              EventHazardRateTreatmentGroup: limitDecimal(
                inputMedianRecruitmentRatesTTE?.['hazardRateTTE']
                  ? inputMedianRecruitmentRatesTTE?.['hazardRateTTE'][index]?.EventHazardRateTreatmentGroup
                  : Array.from({length: 2}, (_, i) => ''),
              ),
              EventHazardRateAccuralCalculation: limitDecimal(
                inputMedianRecruitmentRatesTTE?.['hazardRateTTE']
                  ? inputMedianRecruitmentRatesTTE?.['hazardRateTTE'][index]
                      ?.EventHazardRateAccuralCalculation
                  : Array.from({length: 2}, (_, i) => ''),
              ),
            })),
          ],
        },
      };
    }
  }
  const {
    ConfigureGroups: {configureGroupsSites},
  } = initialValues['createNewModel'];
  return {
    ...initialValues,
    [averageSiteDuration.formKey]: {
      plndDateOfFrstPatientStdEntASD,
      avgLOTSiteStaysOpensASD,
      meanIntrlBwtFstPatientEntAddSiteOpenASD,
      reportingTimeUnitASD,
      [averageSiteDuration.formKey]: [
        ...configureGroupsSites.map((data: any) => {
          const {group} = data;
          const [groupInfo] = group;
          return {
            groupName: groupInfo.name,
            groupId: groupInfo.id,
            plndDateOfFrstPatientStdEntASD,
            meanIntrlBwtFstPatientEntAddSiteOpenASD,
            avgLOTSiteStaysOpensASD,
          };
        }),
      ],
    },
    [medianRecruitmentRates.formKey]: {
      meanAccruingMRR,
      stdDevMRR,
      medianCensorDisMRR,
      reportingTimeUnitMRR,
      [medianRecruitmentRates.formKey]: [
        ...configureGroupsSites.map((data: any) => {
          const {group} = data;
          const [groupInfo] = group;
          return {
            groupName: groupInfo.name,
            groupId: groupInfo.id,
            meanAccruingMRR,
            stdDevMRR,
            medianCensorDisMRR,
          };
        }),
      ],
      pieceWiseAccruals: Array.from({length: configureGroupsSites.length}, (_, i) => ({
        accrualRateperiod: 2,
        accrualDurations: Array.from({length: 1}, (_, i) => ''),
        accrualRates: Array.from({length: 2}, (_, i) => ''),
      })),
    },
    medianRecruitmentRatesTTE: {
      reportingTimeUnitMRR: reportingTimeUnitMRRTTE,
      meanAccruingMRR: meanAccruingMRRTTE,
      stdDevMRR: stdDevMRRTTE,
      // medianCensorDisMRR:medianCensorDisMRRTTE,
      medianTimeToEventMRR,
      medianTimeToCensoringMRR,
      medianRecruitmentRatesTTE: [
        ...configureGroupsSites.map((data: any) => {
          const {group} = data;
          const [groupInfo] = group;
          return {
            groupName: groupInfo.name,
            groupId: groupInfo.id,
            meanAccruingMRR: meanAccruingMRRTTE,
            stdDevMRR: stdDevMRRTTE,
            // medianCensorDisMRR:medianCensorDisMRRTTE,
            medianTimeToEventMRR,
            medianTimeToCensoringMRR,
          };
        }),
      ],
      pieceWiseAccrualsTTE: Array.from({length: configureGroupsSites.length}, (_, i) => ({
        accrualRateperiod: 2,
        accrualDurations: Array.from({length: 1}, (_, i) => ''),
        accrualRates: Array.from({length: 2}, (_, i) => ''),
      })),
      hazardRateTTE: Array.from({length: configureGroupsSites.length}, (_, i) => ({
        accrualRateperiod: 2,
        hazardDurations: Array.from({length: 1}, (_, i) => ''),
        medianTimeToEvents: Array.from({length: 2}, (_, i) => ''),
        medianTimeToCensoring: Array.from({length: 2}, (_, i) => ''),
        HazardRatioUnderH1: Array.from({length: 2}, (_, i) => ''),
        EventHazardRateControlGroup: Array.from({length: 2}, (_, i) => ''),
        EventHazardRateTreatmentGroup: Array.from({length: 2}, (_, i) => ''),
        EventHazardRateAccuralCalculation: Array.from({length: 2}, (_, i) => ''),
      })),
    },
  };
};

export const accrualCalculatorSlice = createSlice({
  name: 'accrualCalculator',
  initialState: initialState as INTIAL_STATE,
  reducers: {
    fetchRequest: (state: INTIAL_STATE, action) => {
      state.isLoading = true;
    },
    fetchSuccess: (state: INTIAL_STATE, action: PayloadAction<any>) => {
      const {
        intialValues,
        fieldInfo,
        schema,
        defaultValues,
        isGroupsConfigurationCompleted,
        simulationDesignId,
        input,
        output,
        version,
        versionName,
        status,
        versions,
        rFormattedOutput,
      } = action.payload;
      state.formFields = fieldInfo;
      state.formInitialValues = formatInitialValues(intialValues, input);
      state.configureGroupsModal.isGroupsConfigurationCompleted = isGroupsConfigurationCompleted;
      state.calculatedResults = output;
      state.simulationDesginId = simulationDesignId;
      state.schema = schema;
      state.defaultValues = defaultValues;
      state.versionClonedFrom = input?.meta?.cloneOf;
      state.noOfSimulation = input?.noOfSimulation;
      state.isLoading = false;
      state.version = version;
      state.versionName = versionName;
      state.status = status;
      state.versions = versions;
      state.rFormattedOutput = rFormattedOutput;
    },
    fetchFailure: (state: INTIAL_STATE, action) => {
      state.isLoading = false;
      state.error = action?.payload;
    },
    updateRequestConfigureGroupsModal: (state: INTIAL_STATE, action) => {
      state.configureGroupsModal.isLoading = true;
    },
    updateRequestConfigureGroupsModalSuccess: (state: INTIAL_STATE, action: PayloadAction<any>) => {
      const {
        smltn_dsgn_mdl_id,
        isGroupsConfigurationCompleted,
        dropDownParams,
        createNewModel,
        createNewModelFormValues,
        version,
        meta,
      } = action.payload;
      state.configureGroupsModal.isGroupsConfigurationCompleted = isGroupsConfigurationCompleted;
      if (smltn_dsgn_mdl_id) {
        state.simulationDesginId = smltn_dsgn_mdl_id;
      }
      if (dropDownParams.length) {
        state.dropDownValues = [...dropDownParams];
        const dropDownOptions = dropDownParams.map((v: any) => ({id: v, name: v}));
        const fieldInfoGroupsDropDownOptions =
          state.formFields.createNewModel.ConfigureGroups.ConfigureGroupsDropDown.options;
        state.formFields.createNewModel.ConfigureGroups.ConfigureGroupsDropDown.options = [
          ...fieldInfoGroupsDropDownOptions,
          ...dropDownOptions,
        ];
      } else {
        state.dropDownValues = [];
      }
      //if(isGroupsConfigurationCompleted){
      state.formInitialValues = {
        ...state.formInitialValues,
        ...{
          createNewModel: {
            ...state.formInitialValues.createNewModel,
            ...createNewModelFormValues,
            ConfigureGroups: createNewModel.ConfigureGroups,
          },
        },
      };
      //}
      state.configureGroupsModal.isLoading = false;
      state.configureGroupsModal.isSaved = true;
      state.versionClonedFrom = meta?.cloneOf;
      if (version) {
        state.version = version;
      }
    },
    setSaveForConfigureGroups: (state: INTIAL_STATE, action: PayloadAction<any>) => {
      state.configureGroupsModal.isSaved = action.payload;
    },
    updateRequestConfigureGroupsModalFailure: (state: INTIAL_STATE, action) => {
      state.configureGroupsModal.isLoading = false;
      state.configureGroupsModal.error = action?.payload;
    },
    fetchResultsData: (state: INTIAL_STATE, action) => {
      state.isLoading = true;
    },
    resetForm: (state: INTIAL_STATE) => {
      state.formFields = {};
      state.formInitialValues = {};
      state.schema = [];
      state.versionClonedFrom = null;
      state.sessionId = null;
      state.showModal = false;
      state.isSavedSession = false;
      state.IsNewSession = false;
      state.isComputed = false;
      state.projSessionId = '';
      state.defaultValues = {};
      state.simulationDesginId = null;
      state.configureGroupsModal = {
        isLoading: false,
        error: '',
        isGroupsConfigurationCompleted: false,
        isSaved: false,
      };
      state.dropDownValues = [];
      state.version = '';
      state.versionName = null;
      state.versions = [];
      state.status = '';
      state.simulationOutputResults = {};
      state.updatedCalculateStudyDuration = {};
      state.hasRcodeError = false;
    },
    fetchResultsDataSuccess: (state: INTIAL_STATE, action) => {
      const resultData = action.payload.data[0];
      state.simulationOutputResults = action.payload.data[0];
      const {designType, endPoint} = resultData;
      const detail = getSessionStorage('accrualData');
      if (designType === GSDesignTypes.GROUP_SEQUENTIAL) {
        if (endPoint?.id === 'TTE') {
          const interimsData = resultData.output?.outTab?.map((ele: any, index: number) =>
            Math.round(ele.events),
          );
          state.resultData = {
            ...state.resultData,
            interimAnalysesPlanned: resultData.interimAnalysesPlanned,
            interimsData: interimsData,
          };
          state.formInitialValues.calculateStudyDurationTTE = {
            ...state.formInitialValues.calculateStudyDurationTTE,
            interimsCountCSD: resultData.interimAnalysesPlanned,
            interimSubjectsCSD: interimsData,
            totalSubjectsToRecruitCSD:
              state.formInitialValues.calculateStudyDurationTTE?.['totalSubjectsToRecruitCSD'] !== ''
                ? state.formInitialValues.calculateStudyDurationTTE?.['totalSubjectsToRecruitCSD'] !==
                  getTotalSubjectsToRecruit(resultData, false)
                  ? state.formInitialValues.calculateStudyDurationTTE?.['totalSubjectsToRecruitCSD']
                  : getTotalSubjectsToRecruit(resultData, false)
                : getTotalSubjectsToRecruit(resultData, false),
          };
          if (detail?.usePiecewiseHazardRate === 'No') {
            state.formInitialValues.eventHazardRatesTTE = {
              ...state.formInitialValues.eventHazardRatesTTE,
              eventHazardRatesForControlEHR:
                state.formInitialValues.eventHazardRatesTTE?.['eventHazardRatesForControlEHR'] !== ''
                  ? state.formInitialValues.eventHazardRatesTTE?.['eventHazardRatesForControlEHR'] !==
                    geteventHazardRatesForControlEHR(resultData, false)
                    ? state.formInitialValues.eventHazardRatesTTE?.['eventHazardRatesForControlEHR']
                    : geteventHazardRatesForControlEHR(resultData, false)
                  : geteventHazardRatesForControlEHR(resultData, false),

              eventHazardRatesForTreatmentEHR:
                state.formInitialValues.eventHazardRatesTTE?.['eventHazardRatesForTreatmentEHR'] !== ''
                  ? state.formInitialValues.eventHazardRatesTTE?.['eventHazardRatesForTreatmentEHR'] !==
                    geteventHazardRatesForTreatmentEHR(resultData, false)
                    ? state.formInitialValues.eventHazardRatesTTE?.['eventHazardRatesForTreatmentEHR']
                    : geteventHazardRatesForTreatmentEHR(resultData, false)
                  : geteventHazardRatesForTreatmentEHR(resultData, false),

              eventHazardRatesForAccrualEHR:
                state.formInitialValues.eventHazardRatesTTE?.['eventHazardRatesForAccrualEHR'] !== ''
                  ? state.formInitialValues.eventHazardRatesTTE?.['eventHazardRatesForAccrualEHR'] !==
                    geteventHazardRatesForAccrualEHR(resultData, false)
                    ? state.formInitialValues.eventHazardRatesTTE?.['eventHazardRatesForAccrualEHR']
                    : geteventHazardRatesForAccrualEHR(resultData, false)
                  : geteventHazardRatesForAccrualEHR(resultData, false),
            };
          } else if (detail?.usePiecewiseHazardRate === 'Yes') {
            state.formInitialValues.eventHazardRatesTTEPiecewise = {
              ...state.formInitialValues.eventHazardRatesTTEPiecewise,

              eventHazardRatesPiecewiseHR:
                state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseHR'] !== ''
                  ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseHR'] !==
                    getEventHazardRatesPiecewiseHR(state.simulationOutputResults)
                    ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseHR']
                    : getEventHazardRatesPiecewiseHR(state.simulationOutputResults)
                  : getEventHazardRatesPiecewiseHR(state.simulationOutputResults),

              eventHazardRatesPiecewiseMTE:
                state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseMTE'] !== ''
                  ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseMTE'] !==
                    getEventHazardRatesPiecewiseMTE(state.simulationOutputResults)
                    ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseMTE']
                    : getEventHazardRatesPiecewiseMTE(state.simulationOutputResults)
                  : getEventHazardRatesPiecewiseMTE(state.simulationOutputResults),

              eventHazardRatesPiecewiseFCG:
                state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseFCG'] !== ''
                  ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseFCG'] !==
                    getEventHazardRatesPiecewiseFCG(state.simulationOutputResults)
                    ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseFCG']
                    : getEventHazardRatesPiecewiseFCG(state.simulationOutputResults)
                  : getEventHazardRatesPiecewiseFCG(state.simulationOutputResults),

              eventHazardRatesPiecewiseHPD:
                state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseHPD'] !== ''
                  ? state.formInitialValues.eventHazardRatesTTEPiesewise?.['eventHazardRatesPiecewiseHPD'] !==
                    getEventHazardRatesPiecewiseHPD(state.simulationOutputResults)
                    ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseHPD']
                    : getEventHazardRatesPiecewiseHPD(state.simulationOutputResults)
                  : getEventHazardRatesPiecewiseHPD(state.simulationOutputResults),

              eventHazardRatesPiecewiseFTG:
                state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseFTG'] !== ''
                  ? state.formInitialValues.eventHazardRatesTTEPiesewise?.['eventHazardRatesPiecewiseFTG'] !==
                    getEventHazardRatesPiecewiseFTG(state.simulationOutputResults)
                    ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseFTG']
                    : getEventHazardRatesPiecewiseFTG(state.simulationOutputResults)
                  : getEventHazardRatesPiecewiseFTG(state.simulationOutputResults),

              eventHazardRatesPiecewiseFAC:
                state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseFAC'] !== ''
                  ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseFAC'] !==
                    getEventHazardRatesPiecewiseFAC(state.simulationOutputResults)
                    ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseFAC']
                    : getEventHazardRatesPiecewiseFAC(state.simulationOutputResults)
                  : getEventHazardRatesPiecewiseFAC(state.simulationOutputResults),
            };
          }
        } else {
          const interimsData = resultData.output?.outTab?.map((ele: any, index: number) =>
            Math.round(ele.Ns),
          );
          state.resultData = {
            ...state.resultData,
            interimAnalysesPlanned: resultData.interimAnalysesPlanned,
            interimsData: interimsData,
          };
          state.formInitialValues.calculateStudyDuration = {
            ...state.formInitialValues.calculateStudyDuration,
            interimsCountCSD: resultData.interimAnalysesPlanned,
            interimSubjectsCSD: interimsData,
          };
        }
      } else {
        if (endPoint?.id === 'TTE') {
          state.formInitialValues.calculateStudyDurationTTE.totalSubjectsToRecruitCSD =
            state.formInitialValues.calculateStudyDurationTTE?.['totalSubjectsToRecruitCSD'] !== ''
              ? state.formInitialValues.calculateStudyDurationTTE?.['totalSubjectsToRecruitCSD'] !==
                getTotalSubjectsToRecruit(resultData, true)
                ? state.formInitialValues.calculateStudyDurationTTE?.['totalSubjectsToRecruitCSD']
                : getTotalSubjectsToRecruit(resultData, true)
              : getTotalSubjectsToRecruit(resultData, true);
          state.formInitialValues.calculateStudyDurationTTE.totalEventsCSD = getTotalEventsTTE(resultData);
        } else {
          state.formInitialValues.calculateStudyDuration.totalNumSubjectsCSD =
            getTotalNumSubjectsCSD(resultData);
        }
      }
      state.resultData.designType = designType;
      state.hasRcodeError = checkResultRcodeError(resultData?.error, resultData?.output);
      state.isLoading = false;
      //This code handles the case of reverting the calculate
      //study duration object to form state in case of completed
      if (action.payload.input) {
        const {
          meta: {designType: inputDesignType},
        } = action.payload.input;
        if (action.payload.status === 'Completed') {
          if (inputDesignType === GSDesignTypes.GROUP_SEQUENTIAL) {
            state.resultData = {
              ...state.resultData,
              interimAnalysesPlanned: action.payload.input?.calculateStudyDuration?.interimsCountCSD,
              interimsData: action.payload.input?.calculateStudyDuration?.interimSubjectsCSD,
            };
            state.formInitialValues.calculateStudyDuration = {
              ...action.payload.input?.calculateStudyDuration,
            };
          } else {
            state.formInitialValues.calculateStudyDuration = {
              ...action.payload.input?.calculateStudyDuration,
            };
          }
          state.resultData.designType = inputDesignType;
          state.updatedCalculateStudyDuration = getCalculateStudyDuration(
            state.simulationOutputResults,
            action.payload.input?.calculateStudyDuration,
            designType,
          );
        }
        const resultsChanged = checkIfResultsChanged(
          action.payload.input,
          getCalculateStudyDuration(
            state.simulationOutputResults,
            action.payload.input?.calculateStudyDuration,
            designType,
          ),
        );
        state.isChangeinResults =
          inputDesignType !== state.simulationOutputResults.designType || resultsChanged;
      }
    },
    updateMedianRatesAverageSites: (state: INTIAL_STATE, action) => {
      const {avgSites, medianRates, calculateStudyDuration, medianRatesTTE, calculateStudyDurationTTE} =
        action.payload;
      // if(isGroupsConfigurationCompleted){ // commenting to test one scenario, will remove once confirmed
      state.formInitialValues = {
        ...state.formInitialValues,
        ...{
          averageSiteDuration: {
            ...state.formInitialValues.averageSiteDuration,
            reportingTimeUnitASD: avgSites.reportingTimeUnitASD,
            averageSiteDuration: [...avgSites.avgRates],
          },
        },
        ...{
          medianRecruitmentRates: {
            ...state.formInitialValues.medianRecruitmentRates,
            reportingTimeUnitMRR: medianRates.reportingTimeUnitMRR,
            medianRecruitmentRates: [...medianRates.medianRates],
            pieceWiseAccruals: [...medianRates.pieceWiseAccruals],
          },
        },
        ...{
          calculateStudyDuration: {
            ...state.formInitialValues.calculateStudyDuration,
            ...calculateStudyDuration,
          },
        },
        ...{
          medianRecruitmentRatesTTE: {
            ...state.formInitialValues.medianRecruitmentRatesTTE,
            reportingTimeUnitMRR: medianRatesTTE?.reportingTimeUnitMRR,
            medianRecruitmentRatesTTE: [...medianRatesTTE?.medianRatesTTE],
            pieceWiseAccrualsTTE: [...medianRatesTTE.pieceWiseAccrualsTTE],
            hazardRateTTE: [...medianRatesTTE.hazardRateTTE],
          },
        },
        ...{
          calculateStudyDurationTTE: {
            ...state.formInitialValues.calculateStudyDurationTTE,
            ...calculateStudyDurationTTE,
          },
        },
      };
      // }
    },
    saveAcrrualRequest: (state: INTIAL_STATE, action) => {
      state.isLoading = true;
    },
    saveAcrrualSuccess: (state: INTIAL_STATE, action) => {
      const {data, output} = action.payload;
      state.isLoading = false;
      state.calculatedResults = output;
      state.formInitialValues = data[0].input;
      state.rFormattedOutput = data[0].rFormattedOutput;
      state.versionClonedFrom = data[0].input?.meta?.cloneOf;
      state.noOfSimulation = data[0].input?.noOfSimulation;
      state.simulationDesginId = data[0].simulationDesignId;
      state.formInitialValues.medianRecruitmentRates = {
        ...state.formInitialValues.medianRecruitmentRates,
        reportingTimeUnitMRR: getMedianRatesInitial(data[0].input).reportingTimeUnitMRR,
        medianRecruitmentRates: [...getMedianRatesInitial(data[0].input).medianInitialValues],
      };
      state.formInitialValues.medianRecruitmentRatesTTE = {
        ...state.formInitialValues.medianRecruitmentRatesTTE,
        reportingTimeUnitMRR: getMedianRatesTTEInitial(data[0].input).reportingTimeUnitMRR,
        medianRecruitmentRatesTTE: [...getMedianRatesTTEInitial(data[0].input).medianInitialValues],
      };
      state.formInitialValues.createNewModel = {
        ...state.formInitialValues.createNewModel,
        AccrualTime: state.formInitialValues.createNewModel.AccrualTime,
      };
      state.formInitialValues.eventHazardRatesTTEPiecewise = {
        ...state.formInitialValues.eventHazardRatesTTEPiecewise,
        eventHazardRatesPiecewiseFAC:
          state.formInitialValues.eventHazardRatesTTEPiecewise.eventHazardRatesPiecewiseFAC,
        eventHazardRatesPiecewiseFCG:
          state.formInitialValues.eventHazardRatesTTEPiecewise.eventHazardRatesPiecewiseFCG,
        eventHazardRatesPiecewiseFTG:
          state.formInitialValues.eventHazardRatesTTEPiecewise.eventHazardRatesPiecewiseFTG,
        eventHazardRatesPiecewiseHPD:
          state.formInitialValues.eventHazardRatesTTEPiecewise.eventHazardRatesPiecewiseHPD,
        eventHazardRatesPiecewiseHR:
          state.formInitialValues.eventHazardRatesTTEPiecewise.eventHazardRatesPiecewiseHR,
        eventHazardRatesPiecewiseMTE:
          state.formInitialValues.eventHazardRatesTTEPiecewise.eventHazardRatesPiecewiseMTE,
      };
      state.formInitialValues.averageSiteDuration = {
        ...state.formInitialValues.averageSiteDuration,
        reportingTimeUnitASD: getAverageSitesInitial(data[0].input).reportingTimeUnitASD,
        averageSiteDuration: [...getAverageSitesInitial(data[0].input).averageSiteRates],
      };
      state.status = data[0].status;
      state.version = data[0].version;
      state.versionName = data[0].versionName;
      //Code which sets the result data design type to late design type from the
      //output of the result,needed because calculateStudyDuration component relies
      //on result data to populate the totalSubject/interims field
      state.resultData.designType = state.simulationOutputResults.designType;
      if (state.simulationOutputResults.endPoint?.id === 'TTE') {
        if (state.simulationOutputResults.designType === GSDesignTypes.GROUP_SEQUENTIAL) {
          const interimsData = state.simulationOutputResults?.output?.outTab?.map((ele: any, index: number) =>
            Math.round(ele.events),
          );
          state.resultData = {
            ...state.resultData,
            interimAnalysesPlanned: state.simulationOutputResults.interimAnalysesPlanned,
            interimsData: interimsData,
          };
          state.formInitialValues.calculateStudyDurationTTE = {
            ...state.formInitialValues.calculateStudyDurationTTE,
            interimsCountCSD: state.simulationOutputResults.interimAnalysesPlanned,
            interimSubjectsCSD: interimsData,
            totalSubjectsToRecruitCSD: getTotalSubjectsToRecruit(state.simulationOutputResults, false),
          };
        } else {
          state.formInitialValues.calculateStudyDurationTTE.totalSubjectsToRecruitCSD =
            getTotalSubjectsToRecruit(state.simulationOutputResults, true);
          state.formInitialValues.calculateStudyDurationTTE.totalEventsCSD = getTotalEventsTTE(
            state.simulationOutputResults,
          );
        }
      } else {
        if (state.simulationOutputResults.designType === GSDesignTypes.GROUP_SEQUENTIAL) {
          const interimsData = state.simulationOutputResults?.output?.outTab?.map((ele: any, index: number) =>
            Math.round(ele.Ns),
          );
          state.resultData = {
            ...state.resultData,
            interimAnalysesPlanned: state.simulationOutputResults.interimAnalysesPlanned,
            interimsData: interimsData,
          };
          state.formInitialValues.calculateStudyDuration = {
            ...state.formInitialValues.calculateStudyDuration,
            interimsCountCSD: state.simulationOutputResults.interimAnalysesPlanned,
            interimSubjectsCSD: interimsData,
          };
        } else {
          state.formInitialValues.calculateStudyDuration.totalNumSubjectsCSD = getTotalNumSubjectsCSD(
            state.simulationOutputResults,
          );
        }
      }
      state.isChangeinResults = false;
    },
    saveAcrrualFailure: (state: INTIAL_STATE, action) => {
      state.isLoading = false;
      state.error = action?.payload;
    },
    createNewVersion: (state: INTIAL_STATE) => {
      const {simulationOutputResults} = state;
      const {designType, endPoint} = simulationOutputResults;
      state.formInitialValues = formatInitialValues(state.defaultValues, undefined);
      state.simulationDesginId = null;
      state.calculatedResults = [];
      state.configureGroupsModal.isGroupsConfigurationCompleted = false;
      state.version = '';
      state.versionName = null;
      state.status = '';
      if (designType === GSDesignTypes.GROUP_SEQUENTIAL) {
        if (endPoint?.id === 'TTE') {
          const interimsData = simulationOutputResults?.output?.outTab?.map((ele: any, index: number) =>
            Math.round(ele.events),
          );
          state.resultData = {
            ...state.resultData,
            interimAnalysesPlanned: simulationOutputResults.interimAnalysesPlanned,
            interimsData: interimsData,
          };
          state.formInitialValues.calculateStudyDurationTTE = {
            ...state.formInitialValues.calculateStudyDurationTTE,
            interimsCountCSD: simulationOutputResults.interimAnalysesPlanned,
            interimSubjectsCSD: interimsData,
            totalSubjectsToRecruitCSD: getTotalSubjectsToRecruit(simulationOutputResults, false),
          };
          state.formInitialValues.eventHazardRatesTTEPiecewise = {
            ...state.formInitialValues.eventHazardRatesTTEPiecewise,

            eventHazardRatesPiecewiseHR: limitDecimal(
              state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseHR'] !== ''
                ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseHR'] !==
                  getEventHazardRatesPiecewiseHR(state.simulationOutputResults)
                  ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseHR']
                  : getEventHazardRatesPiecewiseHR(state.simulationOutputResults)
                : getEventHazardRatesPiecewiseHR(state.simulationOutputResults),
            ),

            eventHazardRatesPiecewiseMTE: limitDecimal(
              state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseMTE'] !== ''
                ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseMTE'] !==
                  getEventHazardRatesPiecewiseMTE(state.simulationOutputResults)
                  ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseMTE']
                  : getEventHazardRatesPiecewiseMTE(state.simulationOutputResults)
                : getEventHazardRatesPiecewiseMTE(state.simulationOutputResults),
            ),

            eventHazardRatesPiecewiseFCG: limitDecimal(
              state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseFCG'] !== ''
                ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseFCG'] !==
                  getEventHazardRatesPiecewiseFCG(state.simulationOutputResults)
                  ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseFCG']
                  : getEventHazardRatesPiecewiseFCG(state.simulationOutputResults)
                : getEventHazardRatesPiecewiseFCG(state.simulationOutputResults),
            ),

            eventHazardRatesPiecewiseHPD: limitDecimal(
              state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseHPD'] !== ''
                ? state.formInitialValues.eventHazardRatesTTEPiesewise?.['eventHazardRatesPiecewiseHPD'] !==
                  getEventHazardRatesPiecewiseHPD(state.simulationOutputResults)
                  ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseHPD']
                  : getEventHazardRatesPiecewiseHPD(state.simulationOutputResults)
                : getEventHazardRatesPiecewiseHPD(state.simulationOutputResults),
            ),

            eventHazardRatesPiecewiseFTG: limitDecimal(
              state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseFTG'] !== ''
                ? state.formInitialValues.eventHazardRatesTTEPiesewise?.['eventHazardRatesPiecewiseFTG'] !==
                  getEventHazardRatesPiecewiseFTG(state.simulationOutputResults)
                  ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseFTG']
                  : getEventHazardRatesPiecewiseFTG(state.simulationOutputResults)
                : getEventHazardRatesPiecewiseFTG(state.simulationOutputResults),
            ),

            eventHazardRatesPiecewiseFAC: limitDecimal(
              state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseFAC'] !== ''
                ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseFAC'] !==
                  getEventHazardRatesPiecewiseFAC(state.simulationOutputResults)
                  ? state.formInitialValues.eventHazardRatesTTEPiecewise?.['eventHazardRatesPiecewiseFAC']
                  : getEventHazardRatesPiecewiseFAC(state.simulationOutputResults)
                : getEventHazardRatesPiecewiseFAC(state.simulationOutputResults),
            ),
          };
        } else {
          const interimsData = simulationOutputResults?.output?.outTab?.map((ele: any, index: number) =>
            Math.round(ele.Ns),
          );
          state.resultData = {
            ...state.resultData,
            interimAnalysesPlanned: simulationOutputResults.interimAnalysesPlanned,
            interimsData: interimsData,
          };
          state.formInitialValues.calculateStudyDuration = {
            ...state.formInitialValues.calculateStudyDuration,
            interimsCountCSD: simulationOutputResults.interimAnalysesPlanned,
            interimSubjectsCSD: interimsData,
          };
        }
      } else {
        if (endPoint?.id !== 'TTE') {
          state.formInitialValues.calculateStudyDuration.totalNumSubjectsCSD =
            getTotalNumSubjectsCSD(simulationOutputResults);
        } else {
          state.formInitialValues.calculateStudyDurationTTE.totalSubjectsToRecruitCSD =
            getTotalSubjectsToRecruit(simulationOutputResults, true);
          state.formInitialValues.calculateStudyDurationTTE.totalEventsCSD =
            getTotalEventsTTE(simulationOutputResults);
        }
      }
      state.resultData.designType = designType;
      state.versionClonedFrom = null;
      state.dropDownValues = [];
      state.isChangeinResults = false;
      state.noOfSimulation = '';
    },
    fetchAccrualVersions: (state: INTIAL_STATE, action: any) => {
      state.accrualVersions.loading = true;
    },
    fetchAccrualVersionSuccess: (state: INTIAL_STATE, action: any) => {
      const {versions} = action.payload;
      state.accrualVersions.loading = false;
      state.versions = versions;
    },
    fetchAccrualVersionFailure: (state: INTIAL_STATE) => {
      state.accrualVersions.loading = false;
      state.versions = [];
    },
    fetchVersionRequest: (state: INTIAL_STATE, action) => {
      state.isLoading = true;
    },
    fetchVersionDetailsSuccess: (state: INTIAL_STATE, action: PayloadAction<any>) => {
      const {versionDetails, output} = action.payload;
      const {simulationDesignId, input, version, status, versionName, rFormattedOutput} = versionDetails[0];
      state.formInitialValues = input;
      state.rFormattedOutput = rFormattedOutput;
      state.formInitialValues.medianRecruitmentRates = {
        ...state.formInitialValues.medianRecruitmentRates,
        reportingTimeUnitMRR: getMedianRatesInitial(input).reportingTimeUnitMRR,
        medianRecruitmentRates: [...getMedianRatesInitial(input).medianInitialValues],
      };
      state.formInitialValues.averageSiteDuration = {
        ...state.formInitialValues.averageSiteDuration,
        reportingTimeUnitASD: getAverageSitesInitial(input).reportingTimeUnitASD,
        averageSiteDuration: [...getAverageSitesInitial(input).averageSiteRates],
      };
      state.formInitialValues.medianRecruitmentRatesTTE = {
        ...state.formInitialValues.medianRecruitmentRatesTTE,
        reportingTimeUnitMRR: getMedianRatesTTEInitial(input).reportingTimeUnitMRR,
        medianRecruitmentRatesTTE: [...getMedianRatesTTEInitial(input).medianInitialValues],
      };
      state.formInitialValues.eventHazardRatesTTEPiecewise = {
        ...state.formInitialValues.eventHazardRatesTTEPiecewise,
        eventHazardRatesPiecewiseHR: limitDecimal(
          getEventHazardRatesPiecewiseHR(current(state)?.simulationOutputResults),
        ),
        eventHazardRatesPiecewiseMTE: limitDecimal(
          getEventHazardRatesPiecewiseMTE(current(state)?.simulationOutputResults),
        ),
        eventHazardRatesPiecewiseFCG: limitDecimal(
          getEventHazardRatesPiecewiseFCG(state.simulationOutputResults),
        ),
        eventHazardRatesPiecewiseHPD: limitDecimal(
          getEventHazardRatesPiecewiseHPD(state.simulationOutputResults),
        ),
        eventHazardRatesPiecewiseFTG: limitDecimal(
          getEventHazardRatesPiecewiseFTG(state.simulationOutputResults),
        ),
        eventHazardRatesPiecewiseFAC: limitDecimal(
          getEventHazardRatesPiecewiseFAC(state.simulationOutputResults),
        ),
      };

      state.calculatedResults = output;
      state.configureGroupsModal.isGroupsConfigurationCompleted = input.isGroupsConfigurationCompleted;
      state.simulationDesginId = simulationDesignId;
      state.version = version;
      state.versionName = versionName;
      state.status = status;
      state.isLoading = false;
      state.versionClonedFrom = input?.meta?.cloneOf;
      state.noOfSimulation = input?.noOfSimulation;
      //Code which checks for the status of the version to either revert to original formdata
      //in case of completed or let the latest output be reflected with latest output
      //on clicking the version from the sidebar
      const newCalculatedStudyDuration = getCalculateStudyDuration(
        state.simulationOutputResults,
        input.calculateStudyDuration,
        state.simulationOutputResults.designType,
      );
      const versionResultChanged =
        state.simulationOutputResults.endPoint?.id === 'TTE'
          ? false
          : checkIfResultsChanged(input, newCalculatedStudyDuration);
      state.isChangeinResults =
        input.meta.designType !== state.simulationOutputResults.designType || versionResultChanged;
      if (status === 'Completed') {
        if (input.meta.designType === GSDesignTypes.GROUP_SEQUENTIAL) {
          if (state.simulationOutputResults.endPoint?.id === 'TTE') {
            state.resultData = {
              ...state.resultData,
              interimAnalysesPlanned: input.calculateStudyDurationTTE?.interimsCountCSD,
              interimsData: [...input.calculateStudyDurationTTE?.interimSubjectsCSD],
            };
            state.formInitialValues.calculateStudyDurationTTE = {
              ...input?.calculateStudyDurationTTE,
            };
          } else {
            state.resultData = {
              ...state.resultData,
              interimAnalysesPlanned: input.calculateStudyDuration?.interimsCountCSD,
              interimsData: [...input.calculateStudyDuration?.interimSubjectsCSD],
            };
            state.formInitialValues.calculateStudyDuration = {
              ...input?.calculateStudyDuration,
            };
          }
        } else {
          if (state.simulationOutputResults.endPoint?.id === 'TTE') {
            state.formInitialValues.calculateStudyDurationTTE = {
              ...input?.calculateStudyDurationTTE,
            };
          } else {
            state.formInitialValues.calculateStudyDuration = {...input?.calculateStudyDuration};
          }
        }
        state.resultData.designType = input.meta.designType;
        state.versionClonedFrom = input?.meta?.cloneOf;
        state.updatedCalculateStudyDuration = getCalculateStudyDuration(
          state.simulationOutputResults,
          input?.calculateStudyDuration,
          state.simulationOutputResults.designType,
        );
      } else {
        if (state.simulationOutputResults.designType === GSDesignTypes.GROUP_SEQUENTIAL) {
          if (state.simulationOutputResults.endPoint?.id === 'TTE') {
            const interimsData = state.simulationOutputResults?.output?.outTab?.map(
              (ele: any, index: number) => Math.round(ele.events),
            );
            state.resultData = {
              ...state.resultData,
              interimAnalysesPlanned: state.simulationOutputResults.interimAnalysesPlanned,
              interimsData: interimsData,
            };
            state.formInitialValues.calculateStudyDurationTTE = {
              ...state.formInitialValues.calculateStudyDurationTTE,
              interimsCountCSD: state.simulationOutputResults.interimAnalysesPlanned,
              interimSubjectsCSD: interimsData,
              totalSubjectsToRecruitCSD: getTotalSubjectsToRecruit(state.simulationOutputResults, false),
            };
          } else {
            const interimsData = state.simulationOutputResults?.output?.outTab?.map(
              (ele: any, index: number) => Math.round(ele.Ns),
            );
            state.resultData = {
              ...state.resultData,
              interimAnalysesPlanned: state.simulationOutputResults.interimAnalysesPlanned,
              interimsData: [...interimsData],
            };
            state.formInitialValues.calculateStudyDuration = {
              ...state.formInitialValues.calculateStudyDuration,
              interimsCountCSD: state.simulationOutputResults.interimAnalysesPlanned,
              interimSubjectsCSD: [...interimsData],
            };
          }
        } else {
          if (state.simulationOutputResults.endPoint?.id === 'TTE') {
            state.formInitialValues.calculateStudyDurationTTE.totalSubjectsToRecruitCSD =
              getTotalSubjectsToRecruit(state.simulationOutputResults, true);
            state.formInitialValues.calculateStudyDurationTTE.totalEventsCSD = getTotalEventsTTE(
              state.simulationOutputResults,
            );
          } else {
            state.formInitialValues.calculateStudyDuration.totalNumSubjectsCSD = getTotalNumSubjectsCSD(
              state.simulationOutputResults,
            );
          }
        }
        state.resultData.designType = state.simulationOutputResults.designType;
      }
    },
    deleteAccrualVersion: (state: INTIAL_STATE, action: any) => {
      state.accrualVersions.loading = true;
    },
    deleteAccrualVersionSuccess: (state: INTIAL_STATE, action: any) => {
      const {versions} = action.payload;
      state.accrualVersions.loading = false;
      state.versions = versions;
    },
    deleteAccrualVersionFailure: (state: INTIAL_STATE) => {
      state.accrualVersions.loading = false;
    },
    toogleLoading: (state: INTIAL_STATE, action: any) => {
      state.isLoading = action.payload;
    },
    addAccrualGraphPreviewImgSrc: (state: INTIAL_STATE, action: any) => {
      const {chartPreviewImgSrc, key, eventsChartPreviewImgSrc} = action.payload;
      state.calculatedResults = state.calculatedResults.map((resultData) => {
        return resultData.key === key
          ? {
              ...resultData,
              chartPreviewImgSrc: chartPreviewImgSrc,
              eventsChartPreviewImgSrc: eventsChartPreviewImgSrc,
            }
          : resultData;
      });
    },
    toggleHideSimulation: (state: INTIAL_STATE, action: any) => {
      state.hideSimulation = action.payload;
    },
    renameAccrualVersion: (state: INTIAL_STATE, action: any) => {
      state.accrualVersions.loading = true;
    },
    renameAccrualVersionSuccess: (state: INTIAL_STATE) => {
      state.accrualVersions.loading = false;
    },
    renameAccrualVersionFailure: (state: INTIAL_STATE) => {
      state.accrualVersions.loading = false;
    },
    toggleConnfigureGroupModal: (state: INTIAL_STATE, action: any) => {
      state.isConfigModalOpen = action.payload;
    },
  },
});

export const {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  updateRequestConfigureGroupsModal,
  updateRequestConfigureGroupsModalSuccess,
  updateRequestConfigureGroupsModalFailure,
  setSaveForConfigureGroups,
  fetchResultsData,
  fetchResultsDataSuccess,
  resetForm,
  updateMedianRatesAverageSites,
  saveAcrrualRequest,
  saveAcrrualSuccess,
  saveAcrrualFailure,
  createNewVersion,
  fetchAccrualVersions,
  fetchAccrualVersionSuccess,
  fetchAccrualVersionFailure,
  fetchVersionRequest,
  fetchVersionDetailsSuccess,
  deleteAccrualVersion,
  deleteAccrualVersionSuccess,
  deleteAccrualVersionFailure,
  toogleLoading,
  addAccrualGraphPreviewImgSrc,
  toggleHideSimulation,
  renameAccrualVersion,
  renameAccrualVersionSuccess,
  renameAccrualVersionFailure,
  toggleConnfigureGroupModal,
} = accrualCalculatorSlice.actions;
export default accrualCalculatorSlice.reducer;

export const getTotalNumSubjectsCSD = (resultData: any) => {
  const fixedDesignType = resultData?.output?.procedure[0];
  let totalNumSubjectsCSD;
  if (fixedDesignType === 'Find sample size for fixed design') {
    // Fixed design Power scenario
    totalNumSubjectsCSD = Math.round(resultData.output['Ns'][0]['n1+n2']);
  } else if (fixedDesignType === 'Find power') {
    // Fixed design - Not power scenario
    totalNumSubjectsCSD = resultData?.output?.powerFromNNormal[0].fixedControlAndTreat;
  } else {
    console.error(`Couldn't find total # subjects for this fixed design: ${fixedDesignType}`);
    totalNumSubjectsCSD = 0;
  }
  return totalNumSubjectsCSD;
};

/**
 * Function which generates latest calculate study duration object
 * @param output
 * @param calculateStudyDuration
 * @param designType
 * @returns
 */
const getCalculateStudyDuration = (output: any, calculateStudyDuration: any, designType: any) => {
  let newCalculateStudyDuration: any = {};
  if (designType === GSDesignTypes.GROUP_SEQUENTIAL) {
    const interimsData = output?.output?.outTab?.map((ele: any, index: number) => Math.round(ele.Ns));
    newCalculateStudyDuration = {
      ...calculateStudyDuration,
      interimsCountCSD: output.interimAnalysesPlanned,
      interimSubjectsCSD: interimsData,
    };
  } else {
    newCalculateStudyDuration = {
      ...calculateStudyDuration,
      totalNumSubjectsCSD: getTotalNumSubjectsCSD(output),
    };
  }
  return newCalculateStudyDuration;
};

const getEventHazardRatesPiecewiseHR = (simulationOutputResults: any) => {
  const hazardRateUnderH1 = simulationOutputResults?.input?.find(
    (item: any) => item?.name === 'hazardRatioUnderH1',
  );
  return hazardRateUnderH1.value;
};

const getEventHazardRatesPiecewiseMTE = (simulationOutputResults: any) => {
  const medianTimeToEventMST = simulationOutputResults?.input?.find(
    (item: any) => item?.name === 'hazardRateTablePeriods',
  );
  var valMST: number[];
  valMST = [];
  const valueMST = medianTimeToEventMST?.value;
  for (let i = 0; i < valueMST?.length; i++) {
    valMST.push(valueMST[i]?.medianSurvivalTime);
  }
  return valMST;
};

const getEventHazardRatesPiecewiseHPD = (simulationOutputResults: any) => {
  const hazPeriodDuration = simulationOutputResults?.input?.find(
    (item: any) => item?.name === 'hazardRateTablePeriods',
  );
  var val: number[];
  val = [];
  const value = hazPeriodDuration?.value;
  for (let i = 0; i < value?.length; i++) {
    val.push(value[i]?.hazardRateDuration);
  }
  return val;
};

const getEventHazardRatesPiecewiseFCG = (simulationOutputResults: any) => {
  const eventHazardRatePiecewise = simulationOutputResults?.input?.find(
    (item: any) => item?.name === 'hazardRateTablePeriods',
  );
  var evntHazRtPwval: number[];
  evntHazRtPwval = [];
  var eventHazardratepiecewiseVal = eventHazardRatePiecewise.value;
  for (let i = 0; i < eventHazardratepiecewiseVal?.length; i++) {
    evntHazRtPwval.push(eventHazardratepiecewiseVal[i]?.eventHazardRate);
  }

  const evntHzrdRtFrCtrlGrpTunt = (rates: number[]) => {
    const timeUnitV = simulationOutputResults?.input?.find((item: any) => item.name === 'timeUnit');
    const timeUnitValue = timeUnitV.value;
    return rates.map((rate: number) =>
      timeUnitValue === 'Years'
        ? rate * 100 * 1
        : timeUnitValue === 'Months'
        ? rate * 100 * 12
        : timeUnitValue === 'Weeks'
        ? rate * 100 * 52.18
        : rate * 100 * 365.25,
    );
  };
  return evntHzrdRtFrCtrlGrpTunt(evntHazRtPwval);
};
const getEventHazardRatesPiecewiseFTG = (simulationOutputResults: any) => {
  const hazardRateUnderH1 = simulationOutputResults?.input?.find(
    (item: any) => item?.name === 'hazardRatioUnderH1',
  );
  const hazardRateUnderH1Val = hazardRateUnderH1?.value;
  const eventHazardRatePiecewise = simulationOutputResults?.input?.find(
    (item: any) => item?.name === 'hazardRateTablePeriods',
  );
  var evntHazRtPwTrtmntval: number[];
  evntHazRtPwTrtmntval = [];
  var eventHazardratepiecewiseVal = eventHazardRatePiecewise.value;
  for (let i = 0; i < eventHazardratepiecewiseVal?.length; i++) {
    evntHazRtPwTrtmntval.push(eventHazardratepiecewiseVal[i]?.eventHazardRate);
  }

  const evntHzrdRtFrTrtmntGrpTunt = (rates: number[]) => {
    const timeUnitV = simulationOutputResults?.input?.find((item: any) => item.name === 'timeUnit');
    const timeUnitVal = timeUnitV.value;

    return rates.map((rate: number) =>
      timeUnitVal === 'Years'
        ? rate * 100 * 1 * hazardRateUnderH1Val
        : timeUnitVal === 'Months'
        ? rate * 100 * 12 * hazardRateUnderH1Val
        : timeUnitVal === 'Weeks'
        ? rate * 100 * 52.18 * hazardRateUnderH1Val
        : rate * 100 * 365.25 * hazardRateUnderH1Val,
    );
  };
  return evntHzrdRtFrTrtmntGrpTunt(evntHazRtPwTrtmntval);
};

const getEventHazardRatesPiecewiseFAC = (simulationOutputResults: any) => {
  const timeUnitV = simulationOutputResults?.input?.find((item: any) => item.name === 'timeUnit');
  const timeUnitVal = timeUnitV.value;
  const hazardRateUnderH1 = simulationOutputResults?.input?.find(
    (item: any) => item?.name === 'hazardRatioUnderH1',
  );
  const hazardRateUnderH1Val = hazardRateUnderH1?.value;
  const eventHazardRatePiecewise = simulationOutputResults?.input?.find(
    (item: any) => item?.name === 'hazardRateTablePeriods',
  );
  var evntHazRtPwTrtmntval: number[];
  evntHazRtPwTrtmntval = [];
  var eventHazardratepiecewiseVal = eventHazardRatePiecewise.value;
  for (let i = 0; i < eventHazardratepiecewiseVal?.length; i++) {
    evntHazRtPwTrtmntval.push(eventHazardratepiecewiseVal[i]?.eventHazardRate);
  }
  const randomizationRatio = simulationOutputResults?.input?.find(
    (item: any) => item.name === 'randomizationRationtnc',
  );
  const randomizationRatioValue = randomizationRatio.value;
  return evntHazRtPwTrtmntval.map(
    (rate: number) =>
      (randomizationRatioValue *
        (timeUnitVal === 'Years'
          ? rate * 100 * 1 * hazardRateUnderH1Val
          : timeUnitVal === 'Months'
          ? rate * 100 * 12 * hazardRateUnderH1Val
          : timeUnitVal === 'Weeks'
          ? rate * 100 * 52.18 * hazardRateUnderH1Val
          : rate * 100 * 365.25 * hazardRateUnderH1Val) +
        (timeUnitVal === 'Years'
          ? rate * 100 * 1
          : timeUnitVal === 'Months'
          ? rate * 100 * 12
          : timeUnitVal === 'Weeks'
          ? rate * 100 * 52.18
          : rate * 100 * 365.25)) /
      (randomizationRatioValue + 1),
  );
};

const geteventHazardRatesForControlEHR = (resultData: any, isfixed: boolean) => {
  const {value: eventHazardRate} = resultData.formattedInput.find(
    (item: any) => item.name === 'eventHazardRate',
  );
  const {value: timeUnit} = resultData.formattedInput.find((item: any) => item.name === 'timeUnit');

  if (eventHazardRate) {
    const result =
      timeUnit === 'Years'
        ? eventHazardRate * 100 * 1
        : timeUnit === 'Months'
        ? eventHazardRate * 100 * 12
        : timeUnit === 'Weeks'
        ? eventHazardRate * 100 * 52.18
        : eventHazardRate * 100 * 365.25;
    const formattedResult = result.toFixed(2);
    return parseFloat(formattedResult);
  }
};

const geteventHazardRatesForTreatmentEHR = (resultData: any, isfixed: boolean) => {
  const {value: eventHazardRate} = resultData.formattedInput.find(
    (item: any) => item.name === 'eventHazardRate',
  );
  const {value: hazardRatioUnderH1} = resultData.formattedInput.find(
    (item: any) => item.name === 'hazardRatioUnderH1',
  );
  const {value: timeUnit} = resultData.formattedInput.find((item: any) => item.name === 'timeUnit');

  if (eventHazardRate && hazardRatioUnderH1) {
    const result =
      (timeUnit === 'Years'
        ? eventHazardRate * 100 * 1
        : timeUnit === 'Months'
        ? eventHazardRate * 100 * 12
        : timeUnit === 'Weeks'
        ? eventHazardRate * 100 * 52.18
        : eventHazardRate * 100 * 365.25) * hazardRatioUnderH1;
    const formattedResult = result.toFixed(2);
    return parseFloat(formattedResult);
  }
};

const geteventHazardRatesForAccrualEHR = (resultData: any, isfixed: boolean) => {
  const {value: eventHazardRate} = resultData.formattedInput.find(
    (item: any) => item.name === 'eventHazardRate',
  );
  const {value: hazardRatioUnderH1} = resultData.formattedInput.find(
    (item: any) => item.name === 'hazardRatioUnderH1',
  );
  const {value: randomizationRationtnc} = resultData.formattedInput.find(
    (item: any) => item.name === 'randomizationRationtnc',
  );
  const {value: timeUnit} = resultData.formattedInput.find((item: any) => item.name === 'timeUnit');

  if (eventHazardRate && hazardRatioUnderH1 && randomizationRationtnc) {
    const result =
      (randomizationRationtnc *
        (timeUnit === 'Years'
          ? eventHazardRate * 100 * 1
          : timeUnit === 'Months'
          ? eventHazardRate * 100 * 12
          : timeUnit === 'Weeks'
          ? eventHazardRate * 100 * 52.18
          : eventHazardRate * 100 * 365.25) *
        hazardRatioUnderH1 +
        (timeUnit === 'Years'
          ? eventHazardRate * 100 * 1
          : timeUnit === 'Months'
          ? eventHazardRate * 100 * 12
          : timeUnit === 'Weeks'
          ? eventHazardRate * 100 * 52.18
          : eventHazardRate * 100 * 365.25)) /
      (randomizationRationtnc + 1);
    const formattedResult = result.toFixed(2);
    return parseFloat(formattedResult);
  }
};

const getTotalSubjectsToRecruit = (resultData: any, isfixed: boolean) => {
  let {
    output: {outTab, expectedTrialParams, expectedTrialParamsForPower},
  } = resultData;
  if (expectedTrialParams) {
    let [data] = expectedTrialParams;
    const {expectT, expectN} = data;
    return isfixed ? +(expectT + expectN) : outTab[outTab.length - 1]['Ns'];
  }
  if (expectedTrialParamsForPower) {
    let [power] = expectedTrialParamsForPower;
    const {expectT: expectTPower, expectN: expectNPower} = power;
    return isfixed ? +(expectTPower + expectNPower) : outTab[outTab.length - 1]['Ns'];
  }
};

const getTotalEventsTTE = (resultData: any) => {
  let {
    output: {expectedTrialParams, expectedTrialParamsForPower},
  } = resultData;
  if (expectedTrialParams) {
    let [data] = expectedTrialParams;
    const {expectEvents} = data;
    return expectEvents;
  }
  if (expectedTrialParamsForPower) {
    let [data] = expectedTrialParamsForPower;
    const {expectEvents} = data;
    return expectEvents;
  }
};
