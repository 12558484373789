import { put, takeEvery, call } from 'redux-saga/effects';
import {
  fetchSuccess,
  fetchFailure,
  getRequest,
} from '../slices/TrialParamSlice';
import { ENDPOINTS } from '../../../services/endpoint';
import { callApi } from '../../../services/api';

export default function* watchGetTrislLParamValue() {
  yield takeEvery(getRequest, getTrialParamFormValue);
}

export function* getTrialParamFormValue({ payload }: any) {
  try {
    const url = `${ENDPOINTS.COMMON_TRIAL_PARAMS}?objid=${payload}`;
    const result = yield call(callApi, url);

    yield put(fetchSuccess(result));
  } catch (error) {
    console.warn(error);
    yield put(fetchFailure(error?.error));
  }
}
