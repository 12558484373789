import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {StageSliceType, IStage, StepTypes} from '../../types/CustomDesignTypes';
import {getStageTemplate} from './StepTemplate';
import {mergeParams, extractParams, replaceParamPrefix} from '../../../../common/utils';
import nanoid from 'nanoid';
import {saveCustomSessionSlice} from './SaveCustomSessionSlice';
import {forEach} from 'lodash';

const COMPLETE: 'complete' = 'complete';
const INPROGRESS: 'inProgress' = 'inProgress';
const NOT_STARTED: 'notStarted' = 'notStarted';
const SKIP:'skip' = 'skip';

export const initialState: StageSliceType = {
  data: [],
  isLoading: false,
  stageCounter: 1,
  activeSnippetIndex: -1,
  defaultSteps: [],
  defaultPrefix: '',
  allSteps:[],
  activeStageTab:0,
  masterCodes:[],
  customCodes:[],
  masterCodeNames:[],
  customCodeNames:[]
};

export const StageSlice = createSlice({
  name: 'customStage',
  initialState: initialState,
  reducers: {
    fetchRequest: (state: any, action: any) => {
      state.isLoading = true;
    },
    fetchSuccess: (state: StageSliceType, action: PayloadAction<any>) => {
      let payload = action.payload;
      const prefix = payload.data?.prefix;
      const {masterCodes,myCodes,masterCodeNames,myCodeNames} = payload.data;
      const [firstStage] = masterCodes;
      const{steps:firstStep} = firstStage;
      let steps = firstStep.map((step: StepTypes) => {
        return {
          ...step,
          id: nanoid(),
          status: NOT_STARTED,
          active: false,
          paramsList: [],
        };
      });

      state.defaultSteps = steps;
      state.defaultPrefix = prefix;
      state.isLoading = false;
      state.masterCodes = masterCodes;
      state.customCodes = myCodes;
      state.masterCodeNames = masterCodeNames || [];
      state.customCodeNames = myCodeNames || [];
    },
    fetchFailure: (state: any, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    initializeStage: function (state: StageSliceType) {
      state.data = [getStageTemplate(0, true, state.defaultSteps)];
      state.isLoading = false;
      state.stageCounter = 1;
      state.activeSnippetIndex = -1;
    },
    addStage: function (state: StageSliceType) {
      state.data = [...state.data, getStageTemplate(state.stageCounter, false, state.defaultSteps)];
      state.isLoading = false;
      ++state.stageCounter;

      const {activeStepIndex, activeStageIndex} = getActiveStage(state.data);
     
      const stageComplete = state.data[activeStageIndex].steps.every((el) => el.status === COMPLETE || el.status === SKIP);
      state.data[activeStageIndex].isStageComplete = stageComplete;
      // Move active state to next Stage when current stage completed
      if (stageComplete && activeStageIndex < state.data.length - 1) {
        state.data[activeStageIndex].active = false;
        state.data[activeStageIndex].steps[activeStepIndex].active = false;

        let {inCompleteStageIndex = -1, inCompleteStepIndex = -1} = getSkipStageIndex(state.data);

        if (inCompleteStageIndex > -1 && inCompleteStepIndex > -1) {
          state.data[inCompleteStageIndex].active = true;
          state.data[inCompleteStageIndex].steps[inCompleteStepIndex].active = true;
          state.data[inCompleteStageIndex].steps[inCompleteStepIndex].status = INPROGRESS;
        }
      }
    },
    removeStage: (state: StageSliceType, action: PayloadAction<IStage & {currentIndex: number}>) => {
      const sData = action.payload;
      state.data = state.data.filter((el: IStage) => el.stageId !== sData.stageId);

      const {activeStageIndex, activeStepIndex} = getActiveStage(state.data);
      state.activeStageTab = state.activeStageTab -1;

      if (activeStageIndex === -1 || activeStepIndex === -1) {
        state.data[0].active = true;
        state.data[0].steps[0].active = true;
        state.data[0].steps[0].status = state.data[0].steps[0].status === COMPLETE ? COMPLETE : INPROGRESS;
        state.activeStageTab = 0
      }
    },
    nextStep: (
      state: StageSliceType,
      action: PayloadAction<{newStepIndex: number | any; newStageIndex: number}>,
    ) => {
      let {newStepIndex, newStageIndex} = action.payload;
      const currentActiveIndex = getActiveStage(state.data);

      state.data[currentActiveIndex.activeStageIndex].active = false;
      state.data[currentActiveIndex.activeStageIndex].steps[
        currentActiveIndex.activeStepIndex
      ].active = false;

      state.data[newStageIndex].active = true;
      state.data[newStageIndex].steps[newStepIndex].active = true;

      const status = state.data[newStageIndex].steps[newStepIndex].status;
      if (status !== COMPLETE && status !== NOT_STARTED && status !== SKIP) {
        state.data[newStageIndex].steps[newStepIndex].status = INPROGRESS;
      }
      if (status === NOT_STARTED) {
        state.data[newStageIndex].steps[newStepIndex].status = INPROGRESS;
      }

      state.activeSnippetIndex = -1;
    },
    confirmStep: (state: StageSliceType, action: PayloadAction<boolean>) => {
      const {activeStepIndex, activeStageIndex} = getActiveStage(state.data);
      if (action.payload) {
        state.data[activeStageIndex].steps[activeStepIndex].status = COMPLETE;
      } else {
        state.data[activeStageIndex].steps[activeStepIndex].status = INPROGRESS;
      }

      const stageComplete = state.data[activeStageIndex].steps.every((el) => el.status === COMPLETE || el.status === SKIP);
      state.data[activeStageIndex].isStageComplete = stageComplete;

      // Move active state to next Stage when current stage completed
      if (stageComplete && activeStageIndex < state.data.length - 1) {
        state.data[activeStageIndex].active = false;
        state.data[activeStageIndex].steps[activeStepIndex].active = false;

        let {inCompleteStageIndex = -1, inCompleteStepIndex = -1} = getInCompleteStageIndex(state.data);

        if (inCompleteStageIndex > -1 && inCompleteStepIndex > -1) {
          state.data[inCompleteStageIndex].active = true;
          state.data[inCompleteStageIndex].steps[inCompleteStepIndex].active = true;
          state.data[inCompleteStageIndex].steps[inCompleteStepIndex].status = INPROGRESS;
        } else {
          state.data[activeStageIndex].active = true;
          state.data[activeStageIndex].steps[activeStepIndex].active = true;
        }
      } else {
        state.data[activeStageIndex].active = true;
        state.data[activeStageIndex].steps[activeStepIndex].active = true;
      }
    },
    saveCode: (state: StageSliceType, action: any) => {
      const {
        payload: {activeStageIndex, activeStepIndex, snippet},
      } = action;
      state.data[activeStageIndex].steps[activeStepIndex].data.code = snippet;
      //commenting this code to finalize confirm feature
      // const {status} = state.data[activeStageIndex]?.steps[activeStepIndex];

      // if -> Mark Status as not Started if code is empty
      // else if -> Mark Status as INPROGRESS if user writes code
      // if (status === COMPLETE) {
      //   return;
      // }
      // state.data[activeStageIndex].steps[activeStepIndex].status = snippet === '' ?  NOT_STARTED : INPROGRESS;
      state.data[activeStageIndex].steps[activeStepIndex].status = INPROGRESS;
    },
    updateActiveStepCode: (state: StageSliceType, action: any) => {
      const {payload: prefix} = action;
      const {activeStepIndex, activeStageIndex} = getActiveStage(state.data);
      // const activeSnippetIndex = state.activeSnippetIndex;
      const defaultPrefix = state.defaultPrefix;
      const {status} = state.data[activeStageIndex].steps[activeStepIndex];
      const step = state.data[activeStageIndex].steps[activeStepIndex];
      const snippet = step?.data?.codeSnippet;
      const {code} = step.data;

      if (status !== COMPLETE) {
        state.data[activeStageIndex].steps[activeStepIndex].data.code = code
          ? `${code}\n${replaceParamPrefix(snippet, defaultPrefix, prefix)}}`
          : replaceParamPrefix(snippet, defaultPrefix, prefix);
      }
    },
    updateActiveSnippetIndex: (state: StageSliceType, action: any) => {
      const {payload: activeSnippetIndex} = action;
      state.activeSnippetIndex = activeSnippetIndex;
    },
    resetStages: function (state: StageSliceType) {
      state.data = [getStageTemplate(0, true, state.defaultSteps)];
      state.isLoading = false;
      state.stageCounter = 1;
      state.activeSnippetIndex = -1;
    },
    updateParams: (state: StageSliceType, action: any) => {
      let {code, activeStageIndex, activeStepIndex, steps, prefix} = action.payload;
      const newParams = extractParams(code, prefix);
      state.data[activeStageIndex].steps[activeStepIndex].data.paramsList = newParams || [];
      state.data[activeStageIndex].paramsList = getUpdatedStageParams(steps, activeStepIndex, newParams);
    },
    addAllSnippets:(state:StageSliceType,action:any) => {
      const {prefix,isMaster,index} = action.payload;
      const {activeStageIndex} = getActiveStage(state.data);
      if(isMaster) {
        state.data[activeStageIndex].steps =  state.data[activeStageIndex].steps.map((d:any,i:number)=>({          
          ...d,data:state.masterCodes[index].steps[i].data
        }))
      } else {
        state.data[activeStageIndex].steps =  state.data[activeStageIndex].steps.map((d:any,i:number)=>({          
          ...d,data:state.customCodes[index].steps[i].data
        }))
      }
      const stepsData:any =  state.data[activeStageIndex].steps;
      const defaultPrefix = state.defaultPrefix;
      state.data[activeStageIndex].steps = stepsData.map(({data,status,active,...rest}:any) => (
        {...rest,active,status:status === COMPLETE ? COMPLETE : INPROGRESS,data:(status === INPROGRESS || status === NOT_STARTED || status === SKIP) ?{...data,code: 
          data.code
          ? `${data.code}\n${replaceParamPrefix(data.codeSnippet, defaultPrefix, prefix)}`
          : replaceParamPrefix(data.codeSnippet, defaultPrefix, prefix)
        }:{...data}}
      )
      )
    },
    skipStep:(state:StageSliceType) => {
      const {activeStepIndex, activeStageIndex} = getActiveStage(state.data);
        state.data[activeStageIndex].steps[activeStepIndex].status = SKIP;
        state.data[activeStageIndex].steps[activeStepIndex].data.code = '';
        // state.data[activeStageIndex].steps[activeStepIndex].active = false;

      const stageComplete = state.data[activeStageIndex].steps.every((el) => el.status === SKIP || el.status === COMPLETE);
      state.data[activeStageIndex].isStageComplete = stageComplete;

      // Move active state to next Stage when current stage completed
      if (stageComplete && activeStageIndex < state.data.length - 1) {
        state.data[activeStageIndex].active = false;
        state.data[activeStageIndex].steps[activeStepIndex].active = false;

        let {inCompleteStageIndex = -1, inCompleteStepIndex = -1} = getSkipStageIndex(state.data);
        if (inCompleteStageIndex > -1 && inCompleteStepIndex > -1) {
          state.data[inCompleteStageIndex].active = true;
          state.data[inCompleteStageIndex].steps[inCompleteStepIndex].active = true;
          state.data[inCompleteStageIndex].steps[inCompleteStepIndex].status = INPROGRESS;
          state.activeStageTab = inCompleteStageIndex
        } else {
          state.data[activeStageIndex].active = true;
          state.data[activeStageIndex].steps[activeStepIndex].active = true;
        }
      } else {
        state.data[activeStageIndex].active = true;
        if(activeStepIndex < 3) {
          state.data[activeStageIndex].steps[activeStepIndex+1].active = true;
          state.data[activeStageIndex].steps[activeStepIndex+1].status = INPROGRESS;
        }
      }
    },
    setActiveTab:(state:StageSliceType,action:PayloadAction<number>) => {
      const tab = action.payload;
      state.activeStageTab = tab
    },
    setStageTemplateName:(state:StageSliceType,action:PayloadAction<any>) => {
      const {activeStageID,templateName} = action.payload;
      const activeStage = state.data[activeStageID];
      activeStage.templateName = templateName;
      state.data[activeStageID]= activeStage;
    },
    saveTemplate:(state:StageSliceType,action:PayloadAction<any>) => {
      state.isLoading = true;
    },
    saveTemplateSuccess:(state:StageSliceType,action:PayloadAction<any>) => {
      console.log(action.payload)
      const {data:{data},activeStageIndex} = action.payload;
      const [temp] = data;
      const {user_code_template_id,code_name}  = temp;
      const activeStage = state.data[activeStageIndex];
      activeStage.templateName = code_name;
      activeStage.templateID = user_code_template_id;
      state.data[activeStageIndex]= activeStage;
      state.isLoading = false;
    },
    saveTemplateFailure:(state:StageSliceType,action:PayloadAction<any>) => {
      const {activeStageIndex} = action.payload;
      const activeStage = state.data[activeStageIndex];
      activeStage.templateName = '';
      activeStage.templateID = -1;
      state.data[activeStageIndex]= activeStage;
      state.isLoading = false;
    },
    updateCodes:(state:StageSliceType,action:PayloadAction<any>) => {
        const snippets = action.payload;
        const {data} = snippets;
        state.masterCodes = data?.masterCodes;
        state.customCodes = data?.myCodes;
        state.masterCodeNames = data?.masterCodeNames || [];
        state.customCodeNames = data?.myCodeNames || [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      saveCustomSessionSlice.actions.fetchSuccess_getSession,
      (state: StageSliceType, action: any) => {
        state.data = action?.payload?.data?.data?.stages?.data.map(
          ({stageId, steps, templateName,isStageComplete ,templateID,paramsList, ...rest}: any) => ({
            ...rest,
            stageId,
            steps: steps.map((step: any,index:number) => ({...step, 
              stageID: stageId,
              status:action?.payload?.isClonedSession?'inProgress': step.status,
              active:action?.payload?.isClonedSession ?index === 0 ?true :false :step.active
            })),
            paramsList:action?.payload?.isClonedSession ? [] : paramsList,
            isStageComplete:action?.payload?.isClonedSession? false:isStageComplete,
            ...(!templateName ? {templateName: ''} : {templateName}),
            ...(!templateID ? {templateID: -1} :{templateID}),
          }),
        );
        state.stageCounter = action?.payload?.data?.data?.stages?.stageCounter;
        state.isLoading = false;
        state.activeSnippetIndex = -1;
      },
    );
  },
});

export const {
  addStage,
  removeStage,
  confirmStep,
  nextStep,
  saveCode,
  updateActiveSnippetIndex,
  updateActiveStepCode,
  fetchFailure,
  fetchRequest,
  fetchSuccess,
  resetStages,
  updateParams,
  initializeStage,
  addAllSnippets,
  skipStep,
  setActiveTab,
  setStageTemplateName,
  saveTemplate,
  saveTemplateSuccess,
  saveTemplateFailure,
  updateCodes
} = StageSlice.actions;

export default StageSlice.reducer;

const getUpdatedStageParams = (
  steps: Array<StepTypes>,
  updatedStepIndex: number,
  newParams: Array<string>,
) => {
  let params: Array<string> = [];

  for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
    params =
      stepIndex !== updatedStepIndex
        ? mergeParams(params, steps[stepIndex].data.paramsList)
        : mergeParams(params, newParams);
  }

  return params;
};

export const getActiveStage = (data: Array<IStage>) => {
  const sIndex = data.findIndex((el: IStage) => el.active);
  const stIndex = data[sIndex]?.steps.findIndex((el: any) => el.active);
  return {activeStageIndex: sIndex, activeStepIndex: stIndex};
};

export const getInCompleteStageIndex = (data: Array<IStage>) => {
  let inCompleteStageIndex = -1,
    inCompleteStepIndex = -1;
  forEach(data, (stage, stageIndex) => {
    inCompleteStepIndex = stage.steps.findIndex((el) => el.status !== COMPLETE);
    if (inCompleteStepIndex > -1) {
      inCompleteStageIndex = stageIndex;
      return false;
    }
  });

  return {inCompleteStageIndex, inCompleteStepIndex};
};

export const getSkipStageIndex = (data: Array<IStage>) => {
  let inCompleteStageIndex = -1,
    inCompleteStepIndex = -1;
  forEach(data, (stage, stageIndex) => {
    inCompleteStepIndex = stage.steps.findIndex((el) => el.status !== SKIP && el.status !== COMPLETE);
    if (inCompleteStepIndex > -1) {
      inCompleteStageIndex = stageIndex;
      return false;
    }
  });

  return {inCompleteStageIndex, inCompleteStepIndex};
};
