import { put, takeEvery, call } from 'redux-saga/effects';
import {
  fetchSuccess,
  fetchFailure,
  fetchRequest,
  selectDesignsRequest,
  selectedDesign,
  removeDesignRequest,
  removeDesign,
  fetchRequestDefaultFields,
  getDesignOptionsDefaultFields,
  fetchUtilityFuncGraph,
  updateUtilityFuncGraph
} from '../slices/DesignOptionSlice';
import { ENDPOINTS } from '../../../services/endpoint';
import { callApi } from '../../../services/api';
// import formInput from '../../dynamicForm/formInputAPI.json';
import { PayloadAction } from '@reduxjs/toolkit';

export default function* watchGetDesignoptionList() {
  yield takeEvery(fetchRequest, getDesignOptionList);
  yield takeEvery(selectDesignsRequest, selectedDesignOptions);
  yield takeEvery(removeDesignRequest, removeDesignSaga);
  yield takeEvery(fetchRequestDefaultFields,getDefaultFields);
  yield takeEvery(fetchUtilityFuncGraph, getUtilityFuncGraph);
}

export function* getDesignOptionList() {
  try {
    const url = `${ENDPOINTS.DESIGN_OPTIONS}?objId=1`;
    const result = yield call(callApi, url);

    yield put(fetchSuccess(result));
  } catch (error) {
    console.warn(error);
    yield put(fetchFailure(error?.error));
  }
}

export function* selectedDesignOptions(data: any) {
  yield put(selectedDesign(data.payload));
}

export function* removeDesignSaga(data: any) {
  yield put(removeDesign(data.payload));
}

export function* getDefaultFields() {
  try {
    const url = `${ENDPOINTS.GET_ALL_DESIGN_PARAMS_DEFAULT_FIELDS}?objid=1`;
    let { data } = yield call(callApi, url);
    
    // Temporarily mocking the resposne. Will be reverted soon.
    // data = formInput.data;

    // De-structure the JSON response.
    // collect metaData & merge it with formInput data.
    data = data.map((formType: any) => {
      formType.details = formType.details.map((formEle: any) => {
        formEle = {
          ...formEle,
          ...formEle.metaData
        };
        return formEle;
      });
      return formType;
    });
    yield put(getDesignOptionsDefaultFields(data));
  } catch (error) {
    console.warn(error);
    yield put(getDesignOptionsDefaultFields([]));
  }
}

export function* getUtilityFuncGraph(action: PayloadAction<any>) {
  try {
    const url = `${ENDPOINTS.GET_UTILITY_FUNCTION_GRAPHS}`;
    const { graphInputs, index } = action.payload;

    let { graphData } = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(graphInputs),
    });

    yield put(updateUtilityFuncGraph({graphData, index}));
  } catch (error) {
    console.warn(error);
    yield put(updateUtilityFuncGraph([]));
  }
}
