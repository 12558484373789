import { differenceBy, isEqual } from "lodash";
import { CurveParams, ToxicityCurve, TwoParamEquationParamType } from "../../features/projects/types/SessionTypes";
import { CONSTANTS } from "../constants";
import { betaMod, betaScaleMultipliers } from "../constants/twoParamFormulas";
import { filterObjByRegEx } from "./toxicityUtil";

export const editEfficacyCurves =(curveData:any,selectedRow:any,curves:Array<ToxicityCurve>,dispatch:Function,saveEdit:Function,editedRow:any,{toxicity ,efficacy}:TwoParamEquationParamType,curveName:string) => {
  const {key:rowIndex} = selectedRow;    
      let lrows = [...curves];
      let localRow = editedRow?.map((row:any,index:number)=>{
        let doseAmounts = filterObjByRegEx(curveData[index], /^d/);
        return {...row,curveName,...doseAmounts,...index === 0 ?{formulaParams:{...toxicity || {}}} : {formulaParams:{...efficacy || {}}}}
      })
      if(rowIndex > -1) {
        lrows[rowIndex] = localRow;
      }
      dispatch(saveEdit({data:lrows}))
}

export const getRemovedCurve = (rowData:any) => {
  const {key,curves} = rowData;
  let removedCurve = curves[key]?.map((curve:any)=>{
    return {
      ...curve, is_del_flg: 1 
    }
  })
  return removedCurve;
}

const getQuadraticCoefficient = (doseAmounts:any,e0:any,isConcaveUp:string) => {
  const maxValue = Math.max(...doseAmounts) || 0;
  const minValue = Math.min(...doseAmounts) || 0;
  if(isConcaveUp === 'up') {
    const minNow=(1-e0)/Math.pow(maxValue,2)
    const maxNow=(1-e0)/Math.pow((maxValue  - minValue),2)
    return (maxNow+minNow)/2
  } else {
    const minNow = -e0/Math.pow((maxValue-minValue),2);
    return 0.9 * minNow;
  }
}
const median = (values:any) =>{
  let data:any = values.slice();
  data.sort((a:any,b:any) => 
    a-b
  );
  let half = Math.floor(data.length / 2);
  if(data.length > 0) {
  if (data.length % 2)
    return values[half];
  else
    return (data[half - 1] + data[half]) / 2.0;
  } else {
    return 0;
  }
}
const getConcaveHValue = (isConcaveUp:string,doseAmounts:any) => {
  return isConcaveUp === 'up' ?  Math.min(...doseAmounts) :Math.max(...doseAmounts) ;
}
export const calculateUpdatedRowValues = (curveType:string,formulaParams:any,doseAmounts:any,maxDoseLevel:any) => {
  let resultObject :any = {};
  let newFormulaParams:any  = {};
  for(let i = 0; i<doseAmounts.length; i++) {
    switch(curveType) {
      case 'Linear':
          newFormulaParams= {...formulaParams,b1:+(0.8 / +Math.max(...doseAmounts))}
          resultObject['d'+(i+1)] = CONSTANTS.LINEAR_CURVE_FORMULA(formulaParams?.e0Linear || formulaParams?.e0,+(0.8 / +Math.max(...doseAmounts)),doseAmounts[i],maxDoseLevel).toFixed(3);
          break;
        case 'Logistic':
          resultObject['d'+(i+1)] = CONSTANTS.LOGISTIC_CURVE_FORMULA(formulaParams?.e0,formulaParams?.eMax,formulaParams?.eD50,formulaParams?.h,doseAmounts[i]).toFixed(3);
          break;
        case 'Hill':
          newFormulaParams= {...formulaParams,eD50:median(doseAmounts)}
          resultObject['d'+(i+1)] = CONSTANTS.HILL_CURVE_FORMULA(formulaParams?.e0,formulaParams?.eMax,median(doseAmounts),formulaParams?.h,doseAmounts[i]).toFixed(3);
          break;
        case 'Quadratic':
          newFormulaParams= {...formulaParams,a:getQuadraticCoefficient(doseAmounts,formulaParams?.k,formulaParams?.isConcaveUp),concaveH:getConcaveHValue(formulaParams?.isConcaveUp,doseAmounts)}
          resultObject['d'+(i+1)] = CONSTANTS.QUADRATIC_CONCAVE_CURVE_FORMULA(formulaParams?.k,getQuadraticCoefficient(doseAmounts,formulaParams?.k,formulaParams?.isConcaveUp),doseAmounts[i],getConcaveHValue(formulaParams?.isConcaveUp,doseAmounts)).toFixed(3);
          break;
        case 'Exponential Saturation':
          resultObject['d'+(i+1)] = CONSTANTS.EXP_CURVE_FORMULA(formulaParams?.e0,formulaParams?.eMax,formulaParams?.h,doseAmounts[i]).toFixed(3);
          break;
        case 'Beta':
          newFormulaParams= {...formulaParams,scale:(betaScaleMultipliers.defaultScale * Math.max(...doseAmounts))}
          resultObject['d'+(i+1)] = betaMod(doseAmounts[i],formulaParams?.e0Beta,formulaParams?.eMaxBeta,formulaParams?.delta2 ,formulaParams?.delta1,(betaScaleMultipliers.defaultScale * Math.max(...doseAmounts))).toFixed(3);
          break;
        default:
          break;
    }
  }
  return {resultObject,newFormulaParams};
  // return resultObject;
};
export const calculateUpdatedRowValuesEfficacy = (
  sessionEquationCurves: ToxicityCurve[][],
  projectLevelCurves: ToxicityCurve[][],
  doseAmounts: Array<string | number>,
  maxDoseLevel: string | number,
  updateRowsDoseAmountChange:Function,
  updateRemovedCurveList:Function,
  updateSessionEquationCurvesValidity:Function,
  updateProjectCurves:Function,
  dispatch: Function,
) => {
  //Get all the previous session curves
  const sessionProjectCurves = [...sessionEquationCurves].filter(
    (row: ToxicityCurve[]) => row[0].is_carry_fwd_flg === 1,
  );
  //check if they are matching the doselevel and amounts
  const previousSessionProjCurves = [...sessionEquationCurves]
    .filter((row: ToxicityCurve[]) => row[0].is_carry_fwd_flg === 1)
    .filter((v: ToxicityCurve[]) => {
      if (isEqual(v[0]?.meta?.crv_dose_amounts.slice(0, +maxDoseLevel), doseAmounts)) {
        return v;
      } else {
        return undefined;
      }
    })
    .filter((e: ToxicityCurve[]) => e !== undefined);
  //check if any non project curves exist, if exist recalculate the values
  const doNonProjectCurvesExist = [...sessionEquationCurves]
    .map((sessioCurve: ToxicityCurve[]) =>
      sessioCurve.some((curve: ToxicityCurve) => curve.is_carry_fwd_flg === 0),
    )
    .some((curveExists: boolean) => curveExists);
  if (doNonProjectCurvesExist) {
    let data: ToxicityCurve[][] = [];
    let changedRows =  [...sessionEquationCurves]
    .map((row:ToxicityCurve[])=>(row.filter((curve:ToxicityCurve)=>curve.is_carry_fwd_flg === 0)))
    .filter((filteredData:ToxicityCurve[]) => filteredData.length >0);
    if (changedRows.length > 0) {
      let res = {};
      data = changedRows.map((curves: ToxicityCurve[]) => (
        curves.map((curve:ToxicityCurve)=>{
          let {newFormulaParams,resultObject} = calculateUpdatedRowValues(curve.curveType, curve.formulaParams, doseAmounts, maxDoseLevel);
          res = {...curve,...resultObject,meta: {crv_dose_amounts: [...doseAmounts], maxDoseLevel: +maxDoseLevel},formulaParams:{...newFormulaParams}};
        return res;
        })
      )) as ToxicityCurve[][]
    }
    //update the validity of newly recalculated session curves
    dispatch(updateSessionEquationCurvesValidity(checkIfSessionEquationCurvesInRange(data)))
    dispatch(updateRowsDoseAmountChange([...previousSessionProjCurves, ...data]));
    //if previous session curves are there, delete them
    if (previousSessionProjCurves.length === 0) {
      dispatch(
        updateRemovedCurveList(
          sessionProjectCurves.map((curves: ToxicityCurve[]) => 
          curves.map((curve:ToxicityCurve)=> ({...curve, is_del_flg: 1})))
        ),
      );
    }
  }
  dispatch(updateProjectCurves({empirical:[],nonEmpirical:[],rows:formEfficacyProjectCurves(projectLevelCurves,maxDoseLevel,doseAmounts)}))
};

export const checkIfSessionEquationCurvesInRange = (equationCurves:ToxicityCurve[][]) => {
  let doseAmountsArray = [];
  let doseAmountValidity: Array<boolean>;
  doseAmountsArray = equationCurves.map((curves: ToxicityCurve[]) => (
    curves.map((curve: ToxicityCurve) => {
      let doseAmounts = filterObjByRegEx(curve, /^d/);
      return Object.values(doseAmounts);
    })
  ))
  doseAmountValidity = doseAmountsArray.flat().map((dose:number[]) => (dose.some((value:number)=>(value >=1 || value <=0))));
  return doseAmountValidity.includes(true)
}

export const getCurveParamsInRange = (curveParams:CurveParams={},maxDoseLevel:string|number) => {
  let formatedCurveParams:CurveParams= {};
  for(let i=0 ;i <+maxDoseLevel;i++) {
    formatedCurveParams['d'+ (i+1)] = curveParams['d'+(i+1)]
  }
  return formatedCurveParams;
}

const groupSessionCurves = (filteredSessionCurves:any) => {
  let groupedCurves:any = [];
  let incrementer = 0;
  for (let index = 0; index < filteredSessionCurves.length/2; index++) {
    let firstValueIndex = incrementer;
    let secondValueIndex = firstValueIndex + 1;
    groupedCurves.push([filteredSessionCurves[firstValueIndex],filteredSessionCurves[secondValueIndex]])
    incrementer += 2
  }
  return groupedCurves;
}
const formEfficacyProjectCurves = (projectLevelCurves:ToxicityCurve[][],maxDoseLevel:string|number,doseAmounts:(number |string)[]) => {
  let matchingEfficacyCurves =[...projectLevelCurves?.filter((e:ToxicityCurve[])=>(+e[0]?.meta?.maxDoseLevel >= +maxDoseLevel))
    .filter((v:ToxicityCurve[])=> {
      if(isEqual(v[0]?.meta?.crv_dose_amounts.slice(0,+maxDoseLevel),doseAmounts)) {
        return v
      }
     })
     .map((el: ToxicityCurve[]) => 
       (el.map((curve:ToxicityCurve)=>{
        return {
          ...curve,
          ...getCurveParamsInRange(curve.curveParams,+maxDoseLevel),
          smltn_txcty_id: null,
          is_carry_fwd_flg: 1,
          formulaParams:{...curve.curveEquationParams},
          meta:{...curve?.meta,crv_dose_amounts:curve?.meta?.crv_dose_amounts.slice(0,+maxDoseLevel),maxDoseLevel:+maxDoseLevel}
        };
       })
     ))
    ]
    return matchingEfficacyCurves
}
export const formEfficacyCurvesSessionLoad = (sessionCurves:ToxicityCurve[][],projectCurves:ToxicityCurve[][],trialParams:{maxDoseLevels:string|number,doseAmounts:Array<number | string>}) => {
  const {maxDoseLevels,doseAmounts} = trialParams;
const addedProjectCurves = sessionCurves
  .filter((ss: ToxicityCurve[]) =>
    projectCurves?.find((p: ToxicityCurve[]) => p[0]?.curveName === ss[0]?.curveName),
  )
  .map((sessionProjectCurve: ToxicityCurve[]) => (
    sessionProjectCurve.map((curve:any)=>{
      let projCurve = projectCurves?.find((p: ToxicityCurve[]) => p[0]?.curveName === curve?.curveName);
      if (projCurve) {
        return {...curve, projSessionId: projCurve[0]?.projSessionId};
      } else return {...curve};
    })
  ));
  let sessionCurvesFiltered  = differenceBy(sessionCurves.flat(),projectCurves.flat(),'curveName')
  const efficacyCurvesProject = formEfficacyProjectCurves(projectCurves,maxDoseLevels,doseAmounts)
  let currentSessionCurves = [...groupSessionCurves(sessionCurvesFiltered)?.filter((e:ToxicityCurve[])=>(+e[0]?.meta?.maxDoseLevel >= +maxDoseLevels))
  .filter((v:ToxicityCurve[])=> {
    if(isEqual(v[0]?.meta?.crv_dose_amounts.slice(0,+maxDoseLevels),doseAmounts)) {
      return v
    }
   })
   .map((el: ToxicityCurve[]) => 
     (el.map((curve:ToxicityCurve)=>{
      return {
        ...curve,
        ...getCurveParamsInRange(curve.curveParams,+maxDoseLevels),
        smltn_txcty_id: curve.simulationToxicityCurveId,
        is_carry_fwd_flg: 0,
        projSessionId:'Current',
        formulaParams:{...curve.curveEquationParams},
        meta:{...curve?.meta,crv_dose_amounts:curve?.meta?.crv_dose_amounts.slice(0,+maxDoseLevels),maxDoseLevel:+maxDoseLevels}
      };
     })
   ))
  ]
  let currentSessionProjectCurves = [...addedProjectCurves?.filter((e:ToxicityCurve[])=>(+e[0]?.meta?.maxDoseLevel >= +maxDoseLevels))
  .filter((v:ToxicityCurve[])=> {
    if(isEqual(v[0]?.meta?.crv_dose_amounts.slice(0,+maxDoseLevels),doseAmounts)) {
      return v
    }
   })
   .map((el: ToxicityCurve[]) => 
     (el.map((curve:ToxicityCurve)=>{
      return {
        ...curve,
        ...getCurveParamsInRange(curve.curveParams,+maxDoseLevels),
        smltn_txcty_id: curve.simulationToxicityCurveId,
        is_carry_fwd_flg: 1,
        formulaParams:{...curve.curveEquationParams},
        meta:{...curve?.meta,crv_dose_amounts:curve?.meta?.crv_dose_amounts.slice(0,+maxDoseLevels),maxDoseLevel:+maxDoseLevels}
      };
     })
   ))
  ]
  return {
    matchingProjectCurves:[...efficacyCurvesProject],
    sessionCurves:[...currentSessionCurves,...currentSessionProjectCurves]
  }
}


export const resetEfficacyCurves = (
  projectCurves:ToxicityCurve[][],
  sessionCurves: ToxicityCurve[][],
  maxDoseLevel:number|string,
  doseAmounts:Array<number|string>,
  dispatch: Function,
  resetSessionCurves: Function,
  updateProjectCurves: Function,
  updateRemovedCurveList:Function
) => {
  dispatch(resetSessionCurves());
  let removedSessionCurves = [...sessionCurves].map((curves: ToxicityCurve[]) =>
    curves.map((curve: ToxicityCurve) => ({...curve, is_del_flg: 1})),
  );
  let projectCurvesMatchingDoseLevel = formEfficacyProjectCurves(projectCurves,maxDoseLevel,doseAmounts)
  removedSessionCurves.length > 0 && dispatch(updateRemovedCurveList(removedSessionCurves));
  dispatch(
    updateProjectCurves({
      empirical: [],
      nonEmpirical: [],
      rows: projectCurvesMatchingDoseLevel,
    }),
  );
};

export const sortSesssionEfficacyCurves = (rows:ToxicityCurve[][]):ToxicityCurve[][] => {
  const currentSessionCurves = rows.filter((row:ToxicityCurve[])=> row[0].projSessionId === 'Current');
  const carryForwardedCurves = rows.filter((row:ToxicityCurve[])=> row[0].projSessionId !== 'Current');
  return [...currentSessionCurves,...carryForwardedCurves]
 }